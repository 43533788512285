import React, { useEffect, useRef, useState } from "react";
import { Platform, View, ActivityIndicator, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { TouchableOpacity } from "react-native-gesture-handler";

import tw from "tailwind-rn";
import * as Yup from "yup";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import { ToasterHandler, Tooltip, RadioButton } from "components/elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import { fonts, colors } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";

import UserProfileStorage from "library/storage/userProfile";

import {
  selectOrderModifying,
  selectRecordData,
} from "library/sagas/ongoing/current-orders/selector";
import { selectSelectedProducts } from "library/sagas/ongoing/global-data/selector";
import {
  selectRefundSplitPreview,
  selectIsRefundPreviewLoading,
} from "library/sagas/ongoing/order-details/selector";
import { handleRefund } from "library/sagas/views/home/drawer/mol-refunds/slice";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";

import {
  setActiveTab,
  setOrderModifying,
} from "library/sagas/ongoing/current-orders/slice";

import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { getPrice, getRefundAmount } from "library/utils/createOrder";
import { formatPrice } from "library/utils/formatter";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import { handleError } from "library/utils/deliveryService";
import { Entitlements, isCoreConnectMember } from "library/utils/entitlements";
import {
  fetchOrderData,
  setCancelFormOnRefund,
  setRefundFormOnCancel,
  lockOrder,
  fetchRefundPreview,
  resetRefundPreview,
  setRefundPreviewInitProgress,
} from "library/sagas/ongoing/order-details/slice";

import styles from "../../styles";
import IMAGES from "static/assets/images";
import {
  getOrderCurrentSubTotal,
  getRefundAmountTotal,
} from "components/views/drawer/order-details/helper";
import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";

import RefundVerificationModal from "components/views/drawer/order-details/order-head/take-action/refund-verification-modal";
import PaymentMethods from "./payment-methods";
import ChargesComponent from "./charges-summary";
import RefundPreview from "./refund-payment-preview";

const OrderItemDetails = ({
  index,
  lineItem,
  updateTaxOfOrder,
  updateRefundAmount,
  isOrderRefunded,
  setFieldValue,
  updatedLineItemsPath,
  Localise,
  messages,
  newDeliveryFee,
  newRelayFee,
  newServiceFee,
  newRetransFee,
  newDeliveryTip,
  newRushDeliveryFee,
  setIsSubmit,
  updatedLineItems,
  noPrice,
  eligibleProductRefund,
  lineItemRefundedAmount,
  selectedProducts,
  refundOptionValue,
}) => {
  const {
    originalPrice = "0.0",
    newPrice = "",
    quantity = "1",
    isRemoved = false,
    refundAmount = "",
  } = lineItem;

  const currentProductId = get(
    updatedLineItems,
    "0.productFirstChoiceCode",
    ""
  );
  const currentProductInfo = selectedProducts.find(
    (product) => product.productId === currentProductId
  );

  const { productName = "" } = currentProductInfo || {};

  useEffect(() => {
    updateTaxOfOrder();
  }, [newPrice]);

  useEffect(() => {
    updateRefundAmount();

    for (let i = 0; i < updatedLineItems?.length; i++) {
      if (
        updatedLineItems[i]?.refundAmount?.length > 0 &&
        updatedLineItems[i]?.refundAmount !== noPrice
      ) {
        setIsSubmit(false);
      } else {
        setIsSubmit(true);
      }
    }
    newDeliveryFee?.length > 0 && newDeliveryFee !== noPrice
      ? setIsSubmit(false)
      : setIsSubmit(true);
    newRelayFee?.length > 0 && newRelayFee !== noPrice
      ? setIsSubmit(false)
      : setIsSubmit(true);
    newServiceFee?.length > 0 && newServiceFee !== noPrice
      ? setIsSubmit(false)
      : setIsSubmit(true);
    newRetransFee?.length > 0 && newRetransFee !== noPrice
      ? setIsSubmit(false)
      : setIsSubmit(true);
  }, [
    refundAmount,
    newDeliveryFee,
    newRelayFee,
    newServiceFee,
    newRetransFee,
    newDeliveryTip,
    newRushDeliveryFee,
  ]);

  return (
    <View key={index} style={tw("flex flex-row my-1 items-center")}>
      <View
        style={{
          ...styles.priceLabelText,
          paddingLeft: 0,
          paddingRight: 8,
        }}
      >
        <Text
          style={{
            ...fonts.default,
            ...(isRemoved && { color: colors.grayScaleLight }),
          }}
          testID="productFirstChoiceDescription"
        >
          {`${
            lineItem.productFirstChoiceDescription.trim() || productName
          } x ${quantity}`}
        </Text>
      </View>
      <Text
        style={{
          ...styles.orderAmount,
          ...(isRemoved && { color: colors.grayScaleLight }),
        }}
        testID="originalPrice"
      >
        ${formatPrice(getPrice(originalPrice))}
      </Text>
      {((!isOrderRefunded && lineItemRefundedAmount > 0) ||
        isOrderRefunded) && (
        <Text
          style={{
            ...styles.refundAmount,
          }}
          testID="lineItemRefundedAmount"
        >
          (${formatPrice(lineItemRefundedAmount)})
        </Text>
      )}
      {isOrderRefunded &&
        eligibleProductRefund > 0 &&
        refundOptionValue !== "" && (
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            name={"refundAmount"}
            placeholder=""
            containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
            isPrice={true}
            keyboardType="numeric"
            iconType="material-community"
            iconName="currency-usd"
            iconSize={14}
            inputContainerStyle={{
              padding: 6,
            }}
            editable={refundOptionValue === "partialRefund"}
            grayedOutOnDisable={refundOptionValue === "fullRefund"}
            leftIconContainerStyle={{ paddingRight: 0 }}
            path={`${updatedLineItemsPath}.${index}`}
            testID="refundAmount"
            handleOnBlur={(refundAmountLocal = "") => {
              if (
                Number(formatPrice(eligibleProductRefund)) <
                Number(refundAmountLocal)
              ) {
                setFieldValue(
                  `${updatedLineItemsPath}.${index}.refundAmount`,
                  formatPrice(0)
                );
                ToasterHandler(
                  "error",
                  Localise(
                    messages,
                    `Refund amount should not be greater than $${formatPrice(
                      eligibleProductRefund
                    )}`
                  )
                );
              }
            }}
          />
        )}
      {isRemoved && (
        <Text style={{ color: colors.grayScaleLight }}>
          {Localise(messages, "Removed")}
        </Text>
      )}
    </View>
  );
};

const PaymentDetails = ({
  orderRefundStatus: internalRefundStatus,
  setOrderRefundStatus,
  setActionProcessing,
  isSmallScreen,
  triggerAction,
}) => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const orderDetailResponse = useSelector(selectRecordData);
  const selectedProducts = useSelector(selectSelectedProducts);
  const refundSplitPreview = useSelector(selectRefundSplitPreview);
  const { values, setFieldValue, errors, resetForm } = useFormikContext();

  const [onChangeValidation, setOnChangeValidation] = useStateIfMounted(false);
  const [paymentOptions, setPaymentOptions] = useStateIfMounted([]);
  const [refundReasonInfo, setRefundReasonInfo] = useStateIfMounted("");
  const [cancelValidation, setCancelValidation] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [partialRefundPrice, setPartialRefundPrice] = useState(0);
  const [loadingPaymentTypes, setLoadingPaymentTypes] = useState("");
  const [showTaxSplits, setShowTaxSplits] = useStateIfMounted(false);
  const [refundOptionValue, setRefundOptionValue] = useState("");
  const refundOptions = [
    {
      id: 1,
      name: Localise(messages, "Full Refund"),
      key: "fullRefund",
      selected: true,
    },
    {
      id: 2,
      name: Localise(messages, "Partial Refund"),
      key: "partialRefund",
      selected: false,
    },
  ];

  const isSplitPaymentEnabled = Environment.get("ENABLE_SPLIT_PAYMENT", false);
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const noPrice = "0.00";

  const {
    paymentInfo: {
      paymentType = "",
      newDiscount = "",
      newDeliveryFee = "",
      newRelayFee = "",
      newServiceFee = "",
      newRetransFee = "",
      newRetailDeliveryFee = "",
      newDeliveryTip = "",
      newRushDeliveryFee = "",
      newTotalAmount,
    } = {},
    customerInfo = {},
    orderItems: formikOrderItems,
    refundPercentage,
  } = values;

  const paymentsInfoPath = `paymentInfo`;
  const updatedLineItemsPath = `orderItems.0.productInfo.updatedLineItems`;

  const deliveryMethod = get(
    formikOrderItems,
    "0.deliveryInfo.deliveryMethod",
    ""
  );
  const isWalkInOrStorePickUp = ["WALK_IN", "STORE_PICKUP"].includes(
    deliveryMethod
  );

  const updatedLineItems = get(values, updatedLineItemsPath, []);

  const paymentsInfoItems = get(values, paymentsInfoPath, []);
  const {
    orderItems = [],
    paymentDetails = {},
    orderAmounts = [],
    taxOnDeliveryFee,
    customerInfo: { taxExemptCode = "" } = {},
  } = orderDetailResponse;

  const orderDetails = orderItems[0] || {};

  const {
    orderSource = "",
    orderItemId = "",
    operator: orderOperator = "",
    paymentItemStatus = "",
    direction,
    receivingMember,
    sendingMember,
    deliveryInfo: { deliveryMethod: orginalDeliveryMethod = "" },
    recipientInfo: { state: recipientState = "" },
    promoCode,
    hasSettlementError = false,
    deliveryGroupAmount: {
      deliveryTip = 0,
      deliveryGroupTotal: totalMOLOrderAmount = 0,
    } = {},
    lineItems,
    status = "",
    isRushOrder = false,
    taxAmounts = [],
    customerOrderId = "",
  } = orderDetails;

  const senderState = sendingMember?.state || "";
  const isEmailOrder = orderSource == "Email Order";

  const isOrderModifying =
    useSelector(selectOrderModifying) &&
    ![
      "FLORIST_PARTNER",
      "MOL_FLORIST_DELIVERED",
      "FOL_FLORIST_DELIVERED",
    ].includes(orginalDeliveryMethod);

  const isMOLorder =
    ["MOL_FLORIST_DELIVERED", "MOL_CUSTOMER_PICKUP"].includes(
      orginalDeliveryMethod
    ) || orderSource === "MOL";

  const [isFullRefund, setIsFullRefund] = useStateIfMounted(false);

  const { newTaxExemptCode = "", houseAccountInfo: { isActive = false } = {} } =
    customerInfo;

  const sourceMemberCode =
    direction === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const selectedShopPermissions = get(permissions, sourceMemberCode, {});

  const isCPSEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH);

  const isCoreConnectShop = isCoreConnectMember(sourceMemberCode);

  let totalPrice = 0;

  const { paymentStatus = "" } = get(paymentDetails, "paymentMethod.0", {});

  const { note = "" } = get(
    paymentDetails,
    "paymentMethod.0.paymentMethodDetails",
    {}
  );

  const refundAmount = getRefundAmount(paymentDetails);

  const isOrderRefunded =
    values?.actionItem === "refund" && internalRefundStatus === "initiated"
      ? !isOrderModifying ||
        internalRefundStatus === "processed" ||
        [
          "TO_BE_REFUND",
          "SETTELED",
          "TO_BE_VOID",
          "VOID",
          "REFUND_ERROR",
          "VOID_ERROR",
          "REFUND_PENDING",
          "VOID_PENDING",
        ].includes(paymentItemStatus)
      : false;

  const fees = orderItems[0].fees || {};

  let totalTax = parseFloat(
    taxExemptCode ? 0 : orderItems[0]?.totalTax?.amount || 0
  );

  let totalTaxRate = taxExemptCode ? 0 : orderItems[0]?.totalTax?.rate || 0.0;
  let taxRate = totalTaxRate * 1000;

  const totalFee = parseFloat(
    get(fees, "applicableCharges.deliveryCharges", []).find(
      (obj) => obj.type === "Standard"
    )?.value || 0
  );
  const totalApplicableCharges = get(
    fees,
    "applicableCharges.totalApplicableCharges",
    0
  );

  const feeSummary =
    orderAmounts?.find((obj) => obj.name === "feeSummary")?.value || "{}";
  const parseSummary = JSON.parse(feeSummary);
  const serviceFee =
    (totalApplicableCharges > 0 && parseSummary.fuelSurcharge) || 0;
  const rushDeliveryFee =
    (totalApplicableCharges > 0 && parseSummary.rushDeliveryFee) || 0;
  const totalDeliveryFee =
    isRushOrder && rushDeliveryFee > 0 && totalFee > 0
      ? totalFee - rushDeliveryFee
      : totalFee;

  const isFullyRefunded = paymentItemStatus === "REFUNDED";

  const feeRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "feeRefundedAmount")?.value || 0;

  const relayFeeRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "relayFeeRefundedAmount")?.value ||
    0;
  const serviceFeeRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "serviceFeeRefundedAmount")
      ?.value || 0;

  const retransFeeRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "retransFeeRefundedAmount")
      ?.value || 0;

  const molRushDeliveryRefundAmount =
    orderAmounts?.find((obj) => obj.name === "rushFeeRefundedAmount")?.value ||
    0;

  const molDeliveryTipRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "deliveryTipRefundedAmount")
      ?.value || 0;

  const taxRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "taxRefundedAmount")?.value || 0;

  const retailDeliveryFee =
    (totalApplicableCharges > 0 &&
      get(fees, "applicableCharges.surCharges", []).find(
        (obj) => obj.name === "retailDeliveryFee"
      )?.value) ||
    0;

  let newRefundedAmount = 0;

  newRefundedAmount =
    Number(feeRefundedAmount) +
    Number(relayFeeRefundedAmount) +
    Number(serviceFeeRefundedAmount) +
    Number(molRushDeliveryRefundAmount) +
    Number(molDeliveryTipRefundedAmount) +
    Number(retransFeeRefundedAmount) +
    Number(taxRefundedAmount) +
    Number(isFullyRefunded ? retailDeliveryFee : 0);

  const eligibleDeliveryRefund =
    Number(feeRefundedAmount) > 0
      ? totalDeliveryFee - Number(feeRefundedAmount)
      : totalDeliveryFee;

  const relayFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "relayFee"
    )?.value || 0;

  const mhqServiceFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "serviceFee"
    )?.value || 0;

  const retransFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retransFee"
    )?.value || 0;

  const eligibleRelayFeeRefund =
    relayFeeRefundedAmount > 0 ? relayFee - relayFeeRefundedAmount : relayFee;

  const eligibleMhqServiceFeeRefund =
    serviceFeeRefundedAmount > 0
      ? mhqServiceFee - serviceFeeRefundedAmount
      : mhqServiceFee;

  const eligibleMOLServiceFeeRefund =
    serviceFeeRefundedAmount > 0
      ? serviceFee - serviceFeeRefundedAmount
      : serviceFee;

  const eligibleRetransFeeRefund =
    retransFeeRefundedAmount > 0
      ? retransFee - retransFeeRefundedAmount
      : retransFee;

  const eligibleMOLRushDeliveryFee =
    molRushDeliveryRefundAmount > 0
      ? rushDeliveryFee - molRushDeliveryRefundAmount
      : rushDeliveryFee;

  const eligibleMOLDeliveryTipAmount =
    molDeliveryTipRefundedAmount > 0
      ? deliveryTip - molDeliveryTipRefundedAmount
      : deliveryTip;

  const eligibleTaxRefundAmount =
    Number(taxRefundedAmount) > 0
      ? Number(totalTax) - Number(taxRefundedAmount)
      : Number(totalTax);

  const totalDiscount = parseFloat(orderItems[0]?.totalDiscountAmount || 0);
  const formattedTotalDiscount =
    totalDiscount > 0
      ? `($${formatPrice(totalDiscount)})`
      : `$${formatPrice(totalDiscount)}`;

  let totalAmount = isMOLorder
    ? totalMOLOrderAmount
    : parseFloat(
        orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
          ?.value || 0
      );

  if (hasSettlementError) totalAmount = 0;

  /* To pass refund item details in the refund call*/
  let totalRefundPrice = 0;
  const itemsInfoArr = updatedLineItems.map((lineItem) => {
    const { productFirstChoiceCode, type, refundAmount } = lineItem;
    totalRefundPrice += parseFloat(getPrice(refundAmount));
    return {
      productId: productFirstChoiceCode,
      productType: type,
      refundAmount: formatPrice(refundAmount),
    };
  });

  const isRefundDue = useRef(false);

  const updateTaxOfOrder = () => {
    const orderNewSubTotal = getOrderCurrentSubTotal(updatedLineItems);

    setFieldValue(
      `${paymentsInfoPath}.newSubTotalAmount`,
      formatPrice(orderNewSubTotal)
    );

    setFieldValue(`${paymentsInfoPath}.paymentType`, "");
  };

  const updateRefundAmount = () => {
    const orderNewSubTotal = getRefundAmountTotal(
      updatedLineItems,
      paymentsInfoItems,
      totalTaxRate,
      taxRate,
      taxOnDeliveryFee,
      eligibleTaxRefundAmount,
      refundPercentage
    );
    isOrderRefunded && setPartialRefundPrice(orderNewSubTotal);
  };

  useEffect(() => {
    updateTaxOfOrder();
  }, [
    updatedLineItems.length,
    newDeliveryFee,
    newRetailDeliveryFee,
    newDiscount,
    newTaxExemptCode,
  ]);

  const refundFormSubmitTrigger = (values, formikBag) => {
    if (formikBag.isSubmitting) return;
    dispatch(setRefundFormOnCancel(""));

    const refundAmount =
      isFullRefund && partialRefundPrice === 0
        ? formatPrice(totalAmount - newRefundedAmount)
        : formatPrice(partialRefundPrice);

    if (!isEmpty(values.refundTransactions)) {
      // Function to sum refundAmount values
      const sumRefundAmount = (transactions) => {
        let total = 0;
        transactions.forEach((transaction) => {
          total += parseFloat(transaction.refundAmount);
        });
        return total;
      };

      // Accessing and calculating the sum
      const totalRefundAmount = sumRefundAmount(values.refundTransactions);

      const differenceAmount =
        parseFloat(refundAmount) - parseFloat(totalRefundAmount);
      if (formatPrice(totalRefundAmount) !== formatPrice(refundAmount)) {
        formikBag.setSubmitting(false);

        const errorMsg = `Incorrect total refund amount. Please ${
          Math.sign(differenceAmount) === -1 ? "remove" : "add"
        } $${Math.abs(differenceAmount).toFixed(
          2
        )} to match the total refund amount`;
        ToasterHandler("uh oh", Localise(messages, errorMsg));

        return;
      }
    }

    if (
      (formatPrice(partialRefundPrice) === formatPrice(totalAmount) ||
        (isFullRefund && partialRefundPrice === 0) ||
        totalAmount === partialRefundPrice + newRefundedAmount) &&
      status !== "CANCELLED"
    ) {
      setCancelValidation(true);
    } else {
      !isMOLorder && initiateRefund(values, formikBag);
    }
    {
      isMOLorder &&
        orginalDeliveryMethod !== "FLORIST_PARTNER" &&
        request("lock-order", {
          recordId: orderItemId,
          deliveryMethod: orginalDeliveryMethod,
        });
    }

    {
      const molOrderReq = getRefundReqObj({ values });
      isMOLorder &&
        dispatch(
          handleRefund({
            params: {
              ...molOrderReq,
              mappedOrderID: orderOperator,
              orderItemId,
              customerOrderId,
            },
            resolve: (text) => {
              // to show percentage related toast message if percentage input field is not empty
              text =
                Number(refundPercentage) > 0
                  ? `Partial refund of ${Number(
                      refundPercentage
                    )}% successfully initiated.`
                  : molOrderReq.isPartial
                  ? `Partial refund of $${molOrderReq.partialAmount} successfully initiated.`
                  : text;

              ToasterHandler("success", Localise(messages, text)),
                setOrderRefundStatus("processed");
              pullOrderDetails();
              setRefundOptionValue("");
            },
            reject: (text) => {
              ToasterHandler("error", Localise(messages, text));
              setOrderRefundStatus("initiated");
              setFieldValue("actionItem", "");
            },
          })
        );
    }
  };

  const refundFormCancelTrigger = () => {
    resetForm();
    setOnChangeValidation(false);
    setOrderRefundStatus("");
    dispatch(setRefundFormOnCancel(""));
    setFieldValue("actionItem", "");
    dispatch(resetRefundPreview());
    setRefundReasonInfo("");
    setPartialRefundPrice(0);
    setIsFullRefund(false);
    setRefundOptionValue("");
  };

  const pullOrderDetails = () => {
    dispatch(setOrderModifying(false));
    dispatch(
      fetchOrderData({
        recordId: orderItemId,
        deliveryMethod: isMOLorder ? orginalDeliveryMethod : deliveryMethod,
        sourceMemberCode,
      })
    );
    dispatch(setActiveTab("payment"));
  };

  let deliveryRefund = 0;
  if (isMOLorder) {
    deliveryRefund =
      Number(newServiceFee) +
      Number(newDeliveryFee) +
      Number(newRushDeliveryFee) +
      Number(newRetailDeliveryFee);
  } else {
    deliveryRefund = Number(newDeliveryFee) + Number(newRelayFee);
  }

  let refundedTax =
    totalTaxRate > 0
      ? taxOnDeliveryFee
        ? formatPrice(((totalRefundPrice + deliveryRefund) * taxRate) / 1000)
        : formatPrice((totalRefundPrice * taxRate) / 1000)
      : 0;

  const isTaxAmountExceeded =
    Number(refundedTax) > Number(eligibleTaxRefundAmount);
  if (isTaxAmountExceeded || Number(refundPercentage) === 100) {
    refundedTax = eligibleTaxRefundAmount;
  }

  let refundedAmount = 0;

  // reqObj for refund-preview and initiate-refund
  const getRefundReqObj = ({ isPreview = false, values = {} }) => {
    const enteredAmount =
      Number(newDeliveryFee) +
      Number(refundedTax) +
      Number(paymentsInfoItems?.newRelayFee) +
      Number(itemsInfoArr[0]?.refundAmount);

    const totalAmountExcludingTax = Number(totalAmount) - Number(totalTax);

    const refundedAmountExcludingTax =
      Number(totalRefundPrice) + Number(deliveryRefund) + Number(deliveryTip);

    const isRefundTotalMatching =
      totalAmountExcludingTax === refundedAmountExcludingTax;

    const isPartial =
      (isFullRefund && partialRefundPrice === 0) ||
      isRefundTotalMatching ||
      refundPercentage == 100
        ? false
        : true;
    const totalTaxAmount =
      isFullRefund && partialRefundPrice === 0
        ? formatPrice(totalTax - taxRefundedAmount)
        : refundedTax;

    return {
      isPartial,
      reason: isPreview ? "Other" : refundReasonInfo,
      orderId: orderItemId,
      memberCode: sourceMemberCode,
      deliveryMethod: orginalDeliveryMethod,
      ...((!(isFullRefund && partialRefundPrice === 0) || !isPreview) && {
        refundInitiatedAmount:
          partialRefundPrice === 0 || !isPartial
            ? enteredAmount > 0
              ? formatPrice(totalAmount - newRefundedAmount)
              : 0
            : formatPrice(partialRefundPrice),
        partialAmount:
          partialRefundPrice === 0 || !isPartial
            ? enteredAmount > 0
              ? formatPrice(totalAmount - newRefundedAmount)
              : 0
            : formatPrice(partialRefundPrice),
        taxAmount: totalTaxAmount,
        relayFeeAmount: paymentsInfoItems?.newRelayFee || 0,
        feeAmount: newDeliveryFee || 0,
        lineItems: itemsInfoArr,
        rushFeeAmount: newRushDeliveryFee || 0,
        retailDeliveryFeeAmount: newRetailDeliveryFee || 0,
        serviceFeeAmount: paymentsInfoItems?.newServiceFee || 0,
        retransFeeAmount: paymentsInfoItems?.newRetransFee || 0,
        deliveryTip: paymentsInfoItems?.newDeliveryTip || 0,
      }),
      ...(isSplitPaymentEnabled &&
        !isPreview && {
          refundTransactions: values?.refundTransactions || [],
          ...((!(isFullRefund && partialRefundPrice === 0) || !isPreview) &&
            !isEmpty(refundSplitPreview) && {
              refundInitiatedAmount: formatPrice(
                refundSplitPreview?.refundAmount
              ),
              partialAmount: formatPrice(refundSplitPreview?.refundAmount),
              taxAmount: formatPrice(refundSplitPreview?.taxAmount),
              feeAmount: formatPrice(refundSplitPreview?.feeAmount),
              relayFeeAmount: formatPrice(refundSplitPreview?.relayFeeAmount),
            }),
        }),
    };
  };

  const getRefundPreview = React.useCallback(() => {
    const refundAmount =
      isFullRefund && partialRefundPrice === 0
        ? formatPrice(totalAmount - newRefundedAmount)
        : formatPrice(partialRefundPrice);

    if (refundAmount <= 0) {
      return;
    }
    dispatch(setRefundPreviewInitProgress());
    dispatch(
      lockOrder({
        params: {
          recordId: orderItemId,
          deliveryMethod: orginalDeliveryMethod,
        },
        resolve: () => {
          dispatch(
            fetchRefundPreview({
              params: getRefundReqObj({ isPreview: true }),
              resolve: () => {
                console.log("refundPreview fetched successfully !! :>> ");
              },
              reject: () => {
                ToasterHandler(
                  "uh oh",
                  Localise(
                    messages,
                    `Failed to get Refund Preview. Please try again`
                  )
                );
                dispatch(resetRefundPreview());
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, `Failed to get Refund Preview. Please try again`)
          );
          dispatch(resetRefundPreview());
        },
      })
    );
  }, [partialRefundPrice, isFullRefund]);

  const initiateRefund = (values = {}, formikBag) => {
    const refundAmount =
      isFullRefund && partialRefundPrice === 0
        ? formatPrice(totalAmount - newRefundedAmount)
        : formatPrice(partialRefundPrice);

    if (Number(refundAmount) <= 0) {
      formikBag.setSubmitting(false);
      ToasterHandler(
        "uh oh",
        Localise(messages, `Refund Amount need to be more than zero`)
      );
      return;
    }
    request("lock-order", {
      recordId: orderItemId,
      deliveryMethod: orginalDeliveryMethod,
    })
      .then(() => {
        request("initiate-refund", getRefundReqObj({ values }))
          .then(() => {
            pullOrderDetails();
            let text =
              Number(refundPercentage) > 0
                ? `Partial refund of ${Number(
                    refundPercentage
                  )}% successfully initiated.`
                : refundOptionValue == "partialRefund"
                ? `Partial refund of $${refundAmount} successfully initiated.`
                : "Refund initiated";
            ToasterHandler("success", Localise(messages, text));
            cancelValidation
              ? setOrderRefundStatus("full_refund_initiated")
              : setOrderRefundStatus("");
            dispatch(resetRefundPreview());
            setIsFullRefund(false);
            setRefundOptionValue("");
            setPartialRefundPrice(formatPrice(0));
          })
          .catch((error) => {
            console.log("Error occurred :>> ", error);
            formikBag.setSubmitting(false);
            handleError(error, ToasterHandler, Localise, messages);
          });
      })
      .catch(() => {
        ToasterHandler(
          "uh oh",
          Localise(messages, `Refund initiate request failed. Please try again`)
        );
        setOrderRefundStatus("");
        setFieldValue("actionItem", "");
        formikBag.setSubmitting(false);
      });
  };

  const readCardData = () => {
    const cardContentDiv = document.getElementById("card_content_div");
    const cardData = cardContentDiv.innerHTML;
    cardContentDiv.innerHTML = ""; // Clear the data;
    const detailsPath = `${paymentsInfoPath}.CREDIT_CARD`;
    const cardDataArr = cardData.split("_");
    const data = get(values, detailsPath);
    setFieldValue(detailsPath, {
      ...data,
      cardNumber: cardDataArr[0].split("/")[1],
      actualCardNumber: cardDataArr[0].split("/")[0],
      expDate: cardDataArr[1],
      name: cardDataArr[2],
      isSwipedCard: true,
    });
  };

  const getShopSettings = (firstPaymentInfo = {}) => {
    if (sourceMemberCode) {
      request("get-shop-settings", {
        ids: [
          "cash",
          "check",
          "credit_card",
          "paid_elsewhere",
          "house_account_invoice",
          "delivery_fee",
          "tax_delivery_fee",
          "retail_delivery_fee",
          "tax_retail_delivery_fee",
          "rush_submitted_by",
          "gift_cards",
          "pay_later",
          "payment_terminal",
        ],
        memberCode: sourceMemberCode,
      })
        .then(async (res) => {
          if (res && res.preferences && res.preferences.length) {
            let result = processShopSettingsResponse(res);

            setFieldValue(`${paymentsInfoPath}.applyTaxOnDF`, "false");
            setFieldValue(
              `${paymentsInfoPath}.applyTaxOnRDF`,
              result["tax_retail_delivery_fee"].toLowerCase() === "true"
            );

            //Update Retail Delivery Fee if shop location is shifted.
            let newRetailDeliveryFee = "";
            if (!isWalkInOrStorePickUp && recipientState === senderState) {
              newRetailDeliveryFee = retailDeliveryFee
                ? ""
                : result["retail_delivery_fee"] || "";
            } else {
              newRetailDeliveryFee = retailDeliveryFee ? noPrice : "";
            }

            setFieldValue(
              "paymentInfo.newRetailDeliveryFee",
              newRetailDeliveryFee
            );

            let paymentOptions = [];

            if (hasSettlementError) {
              result["house_account_invoice"] === "true" &&
                isActive &&
                paymentOptions.push({
                  label: Localise(messages, "Invoice"),
                  value: "INVOICE",
                });
            }

            if (hasSettlementError) {
              result["cash"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Cash"),
                  value: "CASH",
                });

              result["check"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Check"),
                  value: "CHECK",
                });

              !isMOLorder &&
                result["credit_card"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Credit Card"),
                  value: "CREDIT_CARD",
                });

              result["paid_elsewhere"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Paid Elsewhere"),
                  value: "PAID_ELSEWHERE",
                });
            }

            if (
              isCPSEntitlementEnabled &&
              !isEmpty(firstPaymentInfo) &&
              result["credit_card"] === "true" &&
              !isMOLorder &&
              hasSettlementError
            ) {
              paymentOptions.push({
                label: Localise(messages, "Saved Payment"),
                value: "SAVED_CARD",
              });
            }

            if (isElectronApp) {
              const paymentTerminalEnabledForMembercode =
                result["payment_terminal"] === "true";

              const enablePaymentTerminal = Environment.get(
                "ENABLE_PAYMENT_TERMINAL",
                ""
              ).split(",");

              const hasAccessToPaymentTerminal =
                enablePaymentTerminal.includes("ALL") ||
                enablePaymentTerminal.includes(sourceMemberCode);

              if (
                paymentTerminalEnabledForMembercode &&
                hasAccessToPaymentTerminal
              ) {
                const { terminal_settings = [] } =
                  UserProfileStorage.getShopPreferences(sourceMemberCode);

                const { currentPOSSettings = {} } = await getCurrentPOSSettings(
                  terminal_settings
                );

                if (currentPOSSettings?.serial_number) {
                  paymentOptions.unshift({
                    label: Localise(messages, "Payment Terminal"),
                    value: "PAYMENT_TERMINAL",
                  });

                  setPaymentOptions(paymentOptions);

                  setFieldValue("paymentInfo.paymentType", "PAYMENT_TERMINAL");
                } else {
                  setPaymentOptions(paymentOptions);
                }
              } else {
                setPaymentOptions(paymentOptions);
              }
            } else {
              setPaymentOptions(paymentOptions);
            }

            UserProfileStorage.setShopPreferences(sourceMemberCode, {
              cash: result["cash"] || "",
              check: result["check"] || "",
              credit_card: result["credit_card"] || "",
              paid_elsewhere: result["paid_elsewhere"] || "",
              gift_cards: result["gift_cards"] || "",
              pay_later: result["pay_later"] || "",
              house_account_invoice: result["house_account_invoice"] || "",
              delivery_fee: result["delivery_fee"] || noPrice,
              tax_delivery_fee: result["tax_delivery_fee"] || "false",
              retail_delivery_fee: result["retail_delivery_fee"] || noPrice,
              tax_retail_delivery_fee:
                result["tax_retail_delivery_fee"] || "false",
              rush_submitted_by: result["rush_submitted_by"] || "",
            });

            setLoadingPaymentTypes("");
          }
        })
        .catch((err) => {
          console.log(
            "Error occurred while getting shop preferences :>> ",
            err
          );
          setLoadingPaymentTypes("");
        });
    } else {
      setPaymentOptions([]);
      setLoadingPaymentTypes("");
    }
  };

  useEffect(() => {
    if (Platform.OS !== "web") return;

    const swipeButtonElement = document.getElementById("swipe_click_button");
    swipeButtonElement?.addEventListener("click", readCardData);

    // remove the event listener when component unmounts
    return () => {
      swipeButtonElement?.removeEventListener("click", readCardData);
    };
  }, []);

  useEffect(() => {
    if (hasSettlementError) {
      if (isCoreConnectShop) {
        setPaymentOptions([
          ...paymentOptions,
          {
            label: Localise(messages, "Paid Elsewhere"),
            value: "PAID_ELSEWHERE",
          },
        ]);
        setLoadingPaymentTypes("");
      } else {
        setLoadingPaymentTypes("load");

        let firstPaymentInfo = {};

        // Get customer info to fetch the saved cards.
        if (!isEmpty(customerInfo)) {
          const { customerId, storeOrigin } = customerInfo;
          request("get-customer-details", {
            customerId,
            storeOrigin,
          }).then((res) => {
            if (res) {
              firstPaymentInfo =
                (res.paymentInfo || []).find(
                  (info) => info.tokenId && info.tokenId !== "string"
                ) || {};
              getShopSettings(firstPaymentInfo);
              setFieldValue("customerInfo", {
                ...customerInfo,
                firstPaymentInfo,
              });
            }
          });
        } else {
          getShopSettings(firstPaymentInfo);
        }
      }
    }
    // clear refundPreview when component unmounts
    return () => {
      dispatch(resetRefundPreview());
    };
  }, []);

  useEffect(() => {
    if (paymentType === "INVOICE") {
      setFieldValue("paymentInfo.INVOICE", {
        amount: formatPrice(newTotalAmount),
        notes: "",
      });
    }
  }, [paymentType, newTotalAmount]);

  useEffect(() => {
    if (!isMOLorder && Number(partialRefundPrice) !== 0 && isFullRefund) {
      // resetting the fullRefund flag, if user changes the amount after clicking on the fullRefund button.
      setIsFullRefund(false);
    } else {
      if (
        !isMOLorder &&
        (Number(partialRefundPrice) !== 0 ||
          (isFullRefund && refundOptionValue === "fullRefund"))
      ) {
        getRefundPreview();
      } else {
        dispatch(resetRefundPreview());
      }
    }
  }, [partialRefundPrice, isFullRefund]);

  const handleCompletePayment = () => {
    setOrderRefundStatus("");
    dispatch(setEditOrder());
    triggerAction({
      action: "edit-and-lock-order",
    });
  };

  const resetFeeFields = () => {
    setPartialRefundPrice(0);
    setFieldValue("refundPercentage", "");
    updatedLineItems.forEach((lineItem, index) => {
      const refundedAmount =
        lineItems[index]?.lineItemAmounts?.find(
          (obj) => obj.name === "refundedAmount"
        )?.value || ``;
      const discountAmount =
        lineItems[index]?.lineItemAmounts?.find(
          (obj) => obj.name === "discountAmount"
        )?.value || ``;
      const originalPriceAmount =
        lineItem?.originalPrice - parseFloat(getPrice(discountAmount));
      const eligibleProductRefund =
        refundOptionValue === "fullRefund"
          ? refundedAmount > 0
            ? originalPriceAmount - refundedAmount
            : originalPriceAmount
          : 0;

      setFieldValue(
        `${updatedLineItemsPath}.${index}.refundAmount`,
        formatPrice(eligibleProductRefund)
      );
    });
    const feeFields = {
      newDeliveryFee: eligibleDeliveryRefund,
      newRelayFee: eligibleRelayFeeRefund,
      newServiceFee: isMOLorder
        ? eligibleMOLServiceFeeRefund
        : eligibleMhqServiceFeeRefund,
      newRushDeliveryFee: eligibleMOLRushDeliveryFee,
      newDeliveryTip: eligibleMOLDeliveryTipAmount,
      newRetransFee: eligibleRetransFeeRefund,
    };

    Object.keys(feeFields).forEach((val) => {
      setFieldValue(
        `${paymentsInfoPath}.${val}`,
        formatPrice(refundOptionValue === "fullRefund" ? feeFields[val] : 0)
      );
    });
  };

  useEffect(() => {
    setIsFullRefund(refundOptionValue === "fullRefund" ? true : false);
    resetFeeFields();
  }, [refundOptionValue, isFullRefund]);

  const getPercentageValue = (percentage, totalValue) => {
    return totalValue * (percentage / 100);
  };

  const showErrorToast = () => {
    // resets all input fields and shows error toast for invalid percentage input
    updatedLineItems.map((lineItem, index) => {
      setFieldValue(
        `${updatedLineItemsPath}.${index}.refundAmount`,
        formatPrice(0)
      );
    });
    resetFeeFields();

    ToasterHandler(
      "error",
      Localise(messages, `Refund percent should be between 1 and 100`)
    );
  };

  const handleRefundPercentInput = (refundInputValue) => {
    if (refundInputValue === "") {
      return;
    }
    const refundPercentValue = Number(refundInputValue);
    if (
      Number(refundInputValue) &&
      refundPercentValue >= 1 &&
      refundPercentValue <= 100
    ) {
      // calculates and updates all field input values when valid percent input is given
      updatedLineItems.map((lineItem, index) => {
        const { originalPrice = "0.0" } = lineItem;

        const lineItemRefundedAmount =
          lineItems[index]?.lineItemAmounts?.find(
            (obj) => obj.name === "refundedAmount"
          )?.value || ``;
        newRefundedAmount += Number(lineItemRefundedAmount);
        const discountAmount =
          lineItems[index]?.lineItemAmounts?.find(
            (obj) => obj.name === "discountAmount"
          )?.value || ``;
        const originalPriceAmount =
          originalPrice - parseFloat(getPrice(discountAmount));
        const eligibleProductRefund =
          lineItemRefundedAmount > 0
            ? originalPriceAmount - lineItemRefundedAmount
            : originalPriceAmount;

        const productRefundValue = getPercentageValue(
          refundPercentValue,
          eligibleProductRefund
        );

        setFieldValue(
          `${updatedLineItemsPath}.${index}.refundAmount`,
          formatPrice(productRefundValue)
        );
      });

      const feeFields = {
        newDeliveryFee: getPercentageValue(
          refundPercentValue,
          eligibleDeliveryRefund
        ),
        newRelayFee: getPercentageValue(
          refundPercentValue,
          eligibleRelayFeeRefund
        ),
        newServiceFee: getPercentageValue(
          refundPercentValue,
          isMOLorder ? eligibleMOLServiceFeeRefund : eligibleMhqServiceFeeRefund
        ),
        newRushDeliveryFee: getPercentageValue(
          refundPercentValue,
          eligibleMOLRushDeliveryFee
        ),
        newDeliveryTip: getPercentageValue(
          refundPercentValue,
          eligibleMOLDeliveryTipAmount
        ),
        newRetransFee: getPercentageValue(
          refundPercentValue,
          eligibleRetransFeeRefund
        ),
        newRetailDeliveryFee:
          refundPercentValue === 100 ? retailDeliveryFee : 0,
      };

      Object.entries(feeFields).forEach(([key, value]) => {
        setFieldValue(`${paymentsInfoPath}.${key}`, formatPrice(value));
      });
    } else {
      showErrorToast();
    }
  };

  return (
    <View>
      {isOrderRefunded && internalRefundStatus === "initiated" && (
        <View
          style={[tw("flex flex-row items-start flex-wrap justify-between")]}
        >
          <View style={[tw("my-1 mt-1"), { width: "40%" }]}>
            <View style={tw("flex")}>
              <Text
                style={{
                  ...fonts.heading5,
                  textTransform: "capitalize",
                }}
              >
                {`${Localise(messages, "Choose A Refund Method")}`}
              </Text>
            </View>
            <View style={tw("flex flex-row")}>
              <RadioButton
                data={refundOptions.map((e, index) => ({
                  ...e,
                  selected: e.key === refundOptionValue,
                  radioButtonStyle: {
                    marginLeft: index === 0 ? 0 : 10,
                  },
                }))}
                onPress={(evt) => {
                  setRefundOptionValue(evt.key);
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: "35%",
              paddingBottom: 10,
            }}
          ></View>
          {isOrderRefunded &&
            refundOptionValue !== "" &&
            refundOptionValue === "partialRefund" && (
              <View style={[tw("items-start"), { width: "25%" }]}>
                <Text
                  style={{
                    ...fonts.heading5,
                    paddingBottom: 10,
                  }}
                >
                  {Localise(messages, "Enter a % (Optional)")}
                </Text>

                <FormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  name={"refundPercentage"}
                  placeholder=""
                  containerStyle={{
                    ...styles.newPriceWidth,
                    paddingLeft: 0,
                    width: "100%",
                  }}
                  isPrice={true}
                  keyboardType="numeric"
                  iconType="material-community"
                  iconName="percent"
                  iconPosition={false}
                  iconSize={12}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  testID="refundPercentage"
                  handleOnBlur={(refundInputValue = "") => {
                    handleRefundPercentInput(refundInputValue);
                  }}
                />
              </View>
            )}
        </View>
      )}

      <View style={tw("my-2")}>
        <View style={tw("flex")}>
          <View style={tw("flex flex-row")}>
            {((!isOrderRefunded && newRefundedAmount > 0) ||
              isOrderRefunded) && (
              <>
                <Text
                  style={{
                    ...styles.refundedMargin,
                    color: colors.primary,
                  }}
                ></Text>
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "20%",
                    paddingBottom: 10,
                  }}
                >
                  {Localise(messages, "Refunded")}
                </Text>
              </>
            )}
            {isOrderRefunded && refundOptionValue !== "" && (
              <>
                <Text
                  style={{
                    ...fonts.heading5,
                    width: "20%",
                    paddingBottom: 10,
                  }}
                >
                  {Localise(messages, "Refund")}
                </Text>
              </>
            )}
          </View>
        </View>

        {updatedLineItems.map((lineItem, index) => {
          const { originalPrice = "0.0" } = lineItem;
          totalPrice += parseFloat(getPrice(originalPrice));

          const lineItemRefundedAmount =
            lineItems[index]?.lineItemAmounts?.find(
              (obj) => obj.name === "refundedAmount"
            )?.value || ``;
          newRefundedAmount += Number(lineItemRefundedAmount);
          const discountAmount =
            lineItems[index]?.lineItemAmounts?.find(
              (obj) => obj.name === "discountAmount"
            )?.value || ``;
          const originalPriceAmount =
            originalPrice - parseFloat(getPrice(discountAmount));
          const eligibleProductRefund =
            lineItemRefundedAmount > 0
              ? originalPriceAmount - lineItemRefundedAmount
              : originalPriceAmount;

          refundedAmount =
            (isFullRefund && partialRefundPrice === 0) ||
            Number(refundPercentage) === 100
              ? Number(formatPrice(totalAmount - newRefundedAmount))
              : Number(formatPrice(partialRefundPrice));

          return (
            <OrderItemDetails
              key={index}
              index={index}
              lineItem={lineItem}
              updateTaxOfOrder={updateTaxOfOrder}
              updateRefundAmount={updateRefundAmount}
              isOrderRefunded={isOrderRefunded}
              setFieldValue={setFieldValue}
              updatedLineItemsPath={updatedLineItemsPath}
              Localise={Localise}
              messages={messages}
              newDeliveryFee={newDeliveryFee}
              newRelayFee={newRelayFee}
              newServiceFee={newServiceFee}
              newDeliveryTip={newDeliveryTip}
              newRushDeliveryFee={newRushDeliveryFee}
              newRetransFee={newRetransFee}
              setIsSubmit={setIsSubmit}
              updatedLineItems={updatedLineItems}
              noPrice={noPrice}
              eligibleProductRefund={eligibleProductRefund}
              isMOLorder={isMOLorder}
              lineItemRefundedAmount={lineItemRefundedAmount}
              selectedProducts={selectedProducts}
              refundOptionValue={refundOptionValue}
            />
          );
        })}
      </View>

      <View style={tw("flex flex-row my-1 items-center")}>
        <Text style={{ ...fonts.heading5, ...styles.priceLabelText }}>
          {Localise(messages, "Subtotal")}
        </Text>
        <Text
          style={{
            ...fonts.heading5,
            ...styles.orderAmount,
          }}
          testID="subTotal"
        >
          ${formatPrice(totalPrice)}
        </Text>
      </View>

      {!isEmpty(promoCode) && (
        <View style={tw("flex flex-row mt-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, "Promo Code")}
          </Text>
          <Text style={styles.orderAmount} testID="promoCode">
            {promoCode}
          </Text>
        </View>
      )}

      {!isWalkInOrStorePickUp && (
        <View style={tw("flex flex-row mt-2 mb-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, "Delivery Fee")}
          </Text>
          <Text
            style={{
              ...styles.orderAmount,
            }}
            testID="deliveryFee"
          >
            ${formatPrice(totalDeliveryFee)}
          </Text>
          {((!isOrderRefunded && feeRefundedAmount > 0) || isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundDeliveryFee"
            >
              (${formatPrice(feeRefundedAmount)})
            </Text>
          )}

          {isOrderRefunded &&
            totalDeliveryFee > 0 &&
            eligibleDeliveryRefund > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newDeliveryFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(deliveryFee = "") => {
                  if (
                    Number(formatPrice(eligibleDeliveryRefund)) <
                    Number(deliveryFee)
                  ) {
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Delivery Fee refund should not be greater than $${formatPrice(
                          eligibleDeliveryRefund
                        )}`
                      )
                    );
                    setFieldValue(
                      `${paymentsInfoPath}.newDeliveryFee`,
                      formatPrice(0)
                    );
                  }
                }}
                testID="newDeliveryFee"
              />
            )}
        </View>
      )}

      {isMOLorder && serviceFee > 0 && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {`${Localise(messages, "Service Fee")}`}
          </Text>
          <Text
            style={[fonts.default, styles.orderAmount, tw("flex-wrap")]}
            testID="serviceFee"
          >
            ${formatPrice(serviceFee)}
          </Text>
          {((!isOrderRefunded && serviceFeeRefundedAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedMOLServiceFee"
            >
              (${formatPrice(serviceFeeRefundedAmount)})
            </Text>
          )}
          {isOrderRefunded &&
            eligibleMOLServiceFeeRefund > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newServiceFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundMOLServiceFee = "") => {
                  if (
                    Number(formatPrice(eligibleMOLServiceFeeRefund)) <
                    Number(refundMOLServiceFee)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newServiceFee`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleMOLServiceFeeRefund
                        )}`
                      )
                    );
                  }
                }}
                testID="newServiceFee"
              />
            )}
        </View>
      )}

      {isMOLorder && isRushOrder && rushDeliveryFee >= 0 && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {`${Localise(messages, "Rush Delivery Fee")}`}
          </Text>
          <Text
            style={[fonts.default, styles.orderAmount, tw("flex-wrap")]}
            testID="rushDeliveryFee"
          >
            ${formatPrice(rushDeliveryFee)}
          </Text>
          {((!isOrderRefunded && molRushDeliveryRefundAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedRushDeliveryFee"
            >
              (${formatPrice(molRushDeliveryRefundAmount)})
            </Text>
          )}

          {isOrderRefunded &&
            eligibleMOLRushDeliveryFee > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newRushDeliveryFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundRushFee = "") => {
                  if (
                    Number(formatPrice(eligibleMOLRushDeliveryFee)) <
                    Number(refundRushFee)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newRushDeliveryFee`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleMOLRushDeliveryFee
                        )}`
                      )
                    );
                  }
                }}
                testID="newRushDeliveryFee"
              />
            )}
        </View>
      )}

      {(Number(newRetailDeliveryFee) > 0 || Number(retailDeliveryFee) > 0) && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, `Retail Delivery Fee`)}
          </Text>
          <Text
            style={{
              ...styles.orderAmount,
              color: colors.primary,
            }}
            testID="retailDeliveryFee"
          >
            $
            {formatPrice(
              Number(newRetailDeliveryFee) || Number(retailDeliveryFee)
            )}
          </Text>

          {isFullyRefunded && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedRetailDeliveryFee"
            >
              ($
              {formatPrice(
                Number(newRetailDeliveryFee) || Number(retailDeliveryFee)
              )}
              )
            </Text>
          )}
        </View>
      )}

      {!isMOLorder && (Number(relayFee) > 0 || isEmailOrder) && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, `Relay Fee`)}
          </Text>
          <Text
            style={{
              ...styles.orderAmount,
              color: colors.primary,
            }}
            testID="relayFee"
          >
            ${formatPrice(Number(relayFee))}
          </Text>
          {((!isOrderRefunded && relayFeeRefundedAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedRelayFee"
            >
              (${formatPrice(relayFeeRefundedAmount)})
            </Text>
          )}
          {isOrderRefunded &&
            eligibleRelayFeeRefund > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newRelayFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundRelayFee = "") => {
                  if (
                    Number(formatPrice(eligibleRelayFeeRefund)) <
                    Number(refundRelayFee)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newRelayFee`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleRelayFeeRefund
                        )}`
                      )
                    );
                  }
                }}
                testID="newRelayFee"
              />
            )}
        </View>
      )}

      {!isMOLorder && isEmailOrder && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, `Service Fee`)}
          </Text>
          <Text
            style={{
              ...styles.orderAmount,
              color: colors.primary,
            }}
            testID="mhqServiceFee"
          >
            ${formatPrice(Number(mhqServiceFee))}
          </Text>
          {((!isOrderRefunded && serviceFeeRefundedAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedMHQServiceFee"
            >
              (${formatPrice(serviceFeeRefundedAmount)})
            </Text>
          )}
          {isOrderRefunded &&
            eligibleMhqServiceFeeRefund > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newServiceFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundMHQServiceFee = "") => {
                  if (
                    Number(formatPrice(eligibleMhqServiceFeeRefund)) <
                    Number(refundMHQServiceFee)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newServiceFee`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleMhqServiceFeeRefund
                        )}`
                      )
                    );
                  }
                }}
                testID="newServiceFee"
              />
            )}
        </View>
      )}

      {!isMOLorder && isEmailOrder && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {Localise(messages, `Retrans Fee`)}
          </Text>
          <Text
            style={{
              ...styles.orderAmount,
              color: colors.primary,
            }}
            testID="retransFee"
          >
            ${formatPrice(Number(retransFee))}
          </Text>
          {((!isOrderRefunded && retransFeeRefundedAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="refundedRetransFee"
            >
              (${formatPrice(retransFeeRefundedAmount)})
            </Text>
          )}
          {isOrderRefunded &&
            eligibleRetransFeeRefund > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newRetransFee"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundRetransFee = "") => {
                  if (
                    Number(formatPrice(eligibleRetransFeeRefund)) <
                    Number(refundRetransFee)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newRetransFee`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleRetransFeeRefund
                        )}`
                      )
                    );
                  }
                }}
                testID="newRetransFee"
              />
            )}
        </View>
      )}

      {isMOLorder && deliveryTip > 0 && (
        <View style={tw("flex flex-row my-1 items-center")}>
          <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
            {`${Localise(messages, "Tip")}`}
          </Text>
          <Text
            style={[fonts.default, styles.orderAmount, tw("flex-wrap")]}
            testID="deliveryTip"
          >
            ${formatPrice(deliveryTip)}
          </Text>
          {((!isOrderRefunded && molDeliveryTipRefundedAmount > 0) ||
            isOrderRefunded) && (
            <Text
              style={{
                ...styles.refundAmount,
              }}
              testID="tipRefundedAmount"
            >
              (${formatPrice(molDeliveryTipRefundedAmount)})
            </Text>
          )}
          {isOrderRefunded &&
            eligibleMOLDeliveryTipAmount > 0 &&
            refundOptionValue !== "" && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newDeliveryTip"}
                placeholder=""
                containerStyle={{ ...styles.newPriceWidth, paddingLeft: 0 }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                editable={refundOptionValue === "partialRefund"}
                grayedOutOnDisable={refundOptionValue === "fullRefund"}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${paymentsInfoPath}`}
                handleOnBlur={(refundedDeliveryTip = "") => {
                  if (
                    Number(formatPrice(eligibleMOLDeliveryTipAmount)) <
                    Number(refundedDeliveryTip)
                  ) {
                    setFieldValue(
                      `${paymentsInfoPath}.newDeliveryTip`,
                      formatPrice(0)
                    );
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        `Refund amount should not be greater than $${formatPrice(
                          eligibleMOLDeliveryTipAmount
                        )}`
                      )
                    );
                  }
                }}
                testID="newDeliveryTip"
              />
            )}
        </View>
      )}

      <View style={tw("flex flex-row my-1 items-center")}>
        <Text style={{ ...fonts.default, ...styles.priceLabelText }}>
          {Localise(messages, "Discounts")}
        </Text>
        <Text
          style={{
            ...styles.orderAmount,
            color: totalDiscount > 0 ? colors.red : colors.primary,
          }}
          testID="discounts"
        >
          {formattedTotalDiscount}
        </Text>

        {isOrderModifying && refundOptionValue !== "" && (
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            name={"formattedDiscount"}
            placeholder=""
            containerStyle={{
              paddingLeft: 0,
            }}
            isPrice={true}
            keyboardType="numeric"
            iconType="material-community"
            iconName="currency-usd"
            iconSize={14}
            inputContainerStyle={{
              padding: 6,
            }}
            editable={refundOptionValue === "partialRefund"}
            grayedOutOnDisable={refundOptionValue === "fullRefund"}
            leftIconContainerStyle={{ paddingRight: 0 }}
            path={paymentsInfoPath}
            handleOnBlur={(discount) => {
              if (/^\(?\d+\.?\d*\)?$/.test(discount)) {
                let onlyDiscount = discount.replace(/\(|\)/g, "");

                setFieldValue(
                  `${paymentsInfoPath}.formattedDiscount`,
                  `(${formatPrice(onlyDiscount)})`
                );

                setFieldValue(
                  `${paymentsInfoPath}.newDiscount`,
                  `${formatPrice(onlyDiscount)}`
                );
              } else {
                setFieldValue(`${paymentsInfoPath}.newDiscount`, "");
              }
            }}
            testID="newFormattedDiscount"
          />
        )}
      </View>

      <View style={tw("flex flex-row my-1 items-center")}>
        <View
          style={[
            tw("flex flex-row items-center"),
            { ...styles.priceLabelText },
          ]}
        >
          <Text style={{ ...fonts.default }} testID="taxRate">
            {`${Localise(messages, "Tax")} ${formatPrice(totalTaxRate * 100)}%`}
          </Text>

          {taxAmounts.length > 0 && (
            <TouchableOpacity
              onPress={() => {
                setShowTaxSplits(!showTaxSplits);
              }}
              testID="show_tax_splits_info"
              accessibilityLabel="show_tax_splits_info"
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                source={
                  IMAGES[
                    showTaxSplits ? "expanded-section" : "collapsed-section"
                  ]
                }
              />
            </TouchableOpacity>
          )}
        </View>

        <Text
          style={[fonts.default, styles.orderAmount, tw("flex-wrap")]}
          testID="totalTax"
        >
          {taxExemptCode
            ? Localise(messages, "Tax Exempt")
            : "$" + formatPrice(totalTax)}
        </Text>

        {((!isOrderRefunded && taxRefundedAmount > 0) || isOrderRefunded) && (
          <Text
            style={{
              ...styles.refundAmount,
            }}
            testID="taxRefundedAmount"
          >
            ($
            {formatPrice(taxRefundedAmount)})
          </Text>
        )}
        {isOrderRefunded && totalTax > 0 && refundOptionValue !== "" && (
          <Text
            style={{
              ...styles.refundAmount,
            }}
            testID="fullRefundTaxRefundedAmount"
          >
            ($
            {!isEmpty(refundSplitPreview)
              ? formatPrice(refundSplitPreview?.taxAmount)
              : isFullRefund && partialRefundPrice === 0
              ? formatPrice(totalTax - taxRefundedAmount)
              : formatPrice(refundedTax)}
            )
          </Text>
        )}
      </View>

      {taxAmounts.length > 0 && showTaxSplits && (
        <View style={tw("flex flex-col my-1")}>
          {taxAmounts.map((taxInfo) => {
            const taxType = taxInfo.taxType;

            return (
              <View
                key={taxType}
                style={tw("flex flex-row")}
                testID={taxType}
                accessibilityLabel={taxType}
              >
                <View
                  style={[
                    tw("flex flex-row"),
                    { ...styles.priceLabelText, marginBottom: 5 },
                  ]}
                >
                  <Text>
                    {`${Localise(
                      messages,
                      taxType === "Zip"
                        ? "Special Tax"
                        : startCase(taxInfo.taxDescription)
                    )} ${formatPrice(taxInfo.taxRate * 100)}%`}
                  </Text>
                </View>

                <Text style={{ ...styles.orderAmounts }}>
                  $
                  {taxInfo.taxAmount === 0
                    ? formatPrice(taxInfo.taxAmount)
                    : taxInfo.taxAmount}
                </Text>
              </View>
            );
          })}
        </View>
      )}

      <View style={tw("flex flex-row my-1 items-center")}>
        <Text
          style={{ ...fonts.heading5, ...styles.priceLabelText }}
          testID="total_title"
        >
          {Localise(messages, "Total")}
        </Text>
        <Text
          style={{
            ...fonts.heading5,
            ...styles.orderAmount,
          }}
          testID="total_amount"
        >
          ${formatPrice(totalAmount)}
        </Text>

        {((!isOrderRefunded && newRefundedAmount > 0) || isOrderRefunded) && (
          <Text
            style={{
              ...fonts.heading5,
              ...styles.refundAmount,
            }}
            testID="total_amount"
          >
            ${formatPrice(newRefundedAmount)}
          </Text>
        )}
      </View>

      {paymentStatus?.toLowerCase() === "pending" && status !== "CANCELLED" ? (
        <>
          {note !== "" && (
            <View style={tw("flex flex-row my-1")}>
              <Text style={{ ...styles.priceLabelText }}>
                {Localise(messages, "Pay Later Note")}
              </Text>
              <Text>{note}</Text>
            </View>
          )}
          <View style={tw("flex flex-row my-1 items-center")}>
            <Text
              style={{ ...fonts.heading5, ...styles.priceLabelText }}
              testID="payment_due_title"
            >
              {Localise(messages, "Payment")}
            </Text>

            <Tooltip
              text={Localise(
                messages,
                "Payment still needed for this pickup order"
              )}
            >
              <View style={tw("flex flex-row justify-around items-center")}>
                <Image
                  style={{
                    width: 20,
                    height: 20,
                    marginBottom: Platform.OS !== "web" ? 2 : 0,
                  }}
                  source={IMAGES["payment-pending"]}
                />
                <TouchableOpacity
                  onPress={() => {
                    handleCompletePayment();
                  }}
                  testID="payment_due"
                  accessibilityLabel="payment_due"
                >
                  <Text
                    style={{
                      ...fonts.link1,
                      color: colors.red,
                      marginLeft: 4,
                    }}
                  >
                    {Localise(messages, "Payment Due")}
                  </Text>
                </TouchableOpacity>
              </View>
            </Tooltip>
          </View>
        </>
      ) : !(isOrderRefunded && internalRefundStatus === "initiated") ? (
        <ChargesComponent isCoreConnectShop={isCoreConnectShop} />
      ) : null}

      {refundAmount > 0 && (
        <View style={tw("flex flex-row my-1 mt-2")}>
          <Text
            style={{
              ...fonts.heading5,
              width: isOrderRefunded ? "55%" : "50%",
              textTransform: "capitalize",
            }}
          >
            {Localise(messages, "Total Refunded Amount")}
          </Text>
          <Text
            style={{
              ...fonts.heading5,
              ...styles.refundAmount,
            }}
            testID="totalRefundedAmount"
          >
            {`$${formatPrice(refundAmount)}`}
          </Text>
        </View>
      )}

      {!isMOLorder && newRefundedAmount > 0 && (
        <View style={tw("flex flex-row my-1 mt-2")}>
          <Text
            style={{
              ...fonts.heading5,
              width: isOrderRefunded ? "55%" : "50%",
              textTransform: "capitalize",
            }}
          >
            {Localise(messages, "Refund due to Others/Quality/Non-Delivery")}
          </Text>
          <Text
            style={{
              ...fonts.heading5,
              ...styles.orderAmount,
              color: `${isOrderRefunded ? colors.red : colors.primary}`,
            }}
          >
            {`$${formatPrice(newRefundedAmount)}`}
          </Text>
        </View>
      )}

      {!isMOLorder &&
        refundAmount - newRefundedAmount > 0 &&
        !(isOrderRefunded && internalRefundStatus === "initiated") && (
          <View style={tw("flex flex-row my-1 mt-5")}>
            <Text
              style={{
                ...fonts.heading6,
                width: "60%",
                textTransform: "capitalize",
              }}
            >
              {`* ${Localise(
                messages,
                "Indicates amount refunded due to Price Modification"
              )}`}
            </Text>
          </View>
        )}

      {isOrderRefunded && internalRefundStatus === "initiated" && (
        <>
          {isMOLorder ? (
            <>
              <View style={tw("flex flex-row my-1 mt-4 items-center")}>
                <Text style={{ ...fonts.heading5, ...styles.labelText }}>
                  {Localise(messages, "Refund Amount")}
                </Text>
                <Text
                  style={{
                    ...fonts.default,
                    ...styles.paymentValue,
                    textAlign: "center",
                    backgroundColor: "rgb(244,244,244)",
                    marginLeft: 0,
                  }}
                >
                  {`$${formatPrice(refundedAmount)}`}
                </Text>
              </View>
            </>
          ) : null}

          {/* Refund Form */}
          <View style={[tw("my-1")]}>
            <RefundForm
              onChangeValidation={onChangeValidation}
              setOnChangeValidation={setOnChangeValidation}
              setRefundReasonInfo={setRefundReasonInfo}
              refundFormSubmitTrigger={refundFormSubmitTrigger}
              refundFormCancelTrigger={refundFormCancelTrigger}
              isSubmit={isSubmit}
              isMOLorder={isMOLorder}
              totalAmount={totalAmount}
              isFullRefund={isFullRefund}
              refundedAmount={Number(refundedAmount)}
              partialRefundPrice={partialRefundPrice}
              newRefundedAmount={newRefundedAmount}
            />
          </View>
        </>
      )}

      {/* Payment Section */}
      {hasSettlementError && (
        <>
          {!isRefundDue.current && parseFloat(newTotalAmount) > 0 && (
            <View style={tw("flex flex-row my-2 items-center")}>
              <Text style={{ ...fonts.heading5, ...styles.labelText }}>
                {Localise(messages, "Payment Type")}
              </Text>
              <Text style={{ ...styles.orderAmount }}></Text>
              <FormFieldPicker
                name={"paymentType"}
                path={paymentsInfoPath}
                label={""}
                placeholder={{
                  label: Localise(messages, "Select Payment Type"),
                  value: "",
                }}
                containerStyle={{
                  paddingHorizontal: 0,
                  width: isMobile ? "50%" : 200,
                }}
                data={paymentOptions}
                testID="selectPaymentType"
              />

              {loadingPaymentTypes !== "" && (
                <ActivityIndicator
                  style={{ marginLeft: 8, marginBottom: 8 }}
                  color={colors.activityIndicator}
                />
              )}
            </View>
          )}
          {paymentType.length > 0 && <PaymentMethods />}
          {(isRefundDue.current || paymentType.length > 0) && (
            <View style={tw("flex flex-row items-center justify-end")}>
              <Text style={{ ...fonts.heading5, ...styles.labelText }}></Text>
              <Text style={{ ...styles.orderAmount }}></Text>
              <SubmitButton
                containerStyle={{ margin: 5 }}
                title={"Process Payment"}
                error={
                  !isEmpty(errors)
                    ? Localise(
                        messages,
                        "Please complete required fields to submit this order"
                      )
                    : ""
                }
                disableOnErrors
              />
            </View>
          )}
        </>
      )}

      {!isMOLorder && isOrderRefunded && status !== "CANCELLED" && (
        <RefundVerificationModal
          showModal={cancelValidation}
          isSmallScreen={isSmallScreen}
          isCancel={true}
          onClose={() => {
            setCancelValidation(false);
            initiateRefund();
          }}
          onContinue={(val) => {
            if (val === "Yes") {
              // redirect to cancel
              dispatch(setCancelFormOnRefund(val));
            }
            setCancelValidation(false);
            initiateRefund();
          }}
          isWiredOrder={false}
        />
      )}
    </View>
  );
};

const RefundForm = React.memo(
  ({
    onChangeValidation,
    setOnChangeValidation,
    setRefundReasonInfo,
    refundFormSubmitTrigger,
    refundFormCancelTrigger,
    isMOLorder,
    totalAmount,
    isFullRefund,
    partialRefundPrice,
    newRefundedAmount,
    refundedAmount,
  }) => {
    const { messages, Localise } = React.useContext(I18NContext);
    const isPreviewLoading = useSelector(selectIsRefundPreviewLoading);
    const refundSplitPreview = useSelector(selectRefundSplitPreview);

    const refundReasons = Environment.get("REFUND_REASONS", "").split(",");
    const isSplitPaymentEnabled = Environment.get(
      "ENABLE_SPLIT_PAYMENT",
      false
    );

    let refundReasonsData = [];

    refundReasons.map((reason) => {
      refundReasonsData.push({
        label: reason,
        value: reason,
      });
    });

    const defaultValues = {
      refundReason: "",
    };

    const validationSchema = Yup.object().shape({
      refundReason: Yup.string().required("Select reason"),
      refundTransactions: Yup.array().of(
        Yup.object().shape({
          refundAmount: Yup.number()
            .typeError("Invalid Amount")
            .min(0, "Invalid Amount"),
        })
      ),
    });

    return (
      <Form
        initialValues={{
          ...defaultValues,
          refundTransactions: [],
        }}
        validationSchema={validationSchema}
        validateOnChange={onChangeValidation}
        validateOnBlur={onChangeValidation}
        onSubmit={refundFormSubmitTrigger}
        render={({
          submitCount,
          resetForm,
          setFieldValue,
          isSubmitting,
          values,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setOnChangeValidation(true);
            }
          }, [submitCount]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setFieldValue(
              "refundTransactions",
              refundSplitPreview?.refundTransactions?.map((refundDetails) => {
                return {
                  ...refundDetails,
                  availableAmount: formatPrice(refundDetails.availableAmount),
                  refundAmount: formatPrice(refundDetails.refundAmount),
                };
              }) || []
            );
          }, [refundSplitPreview]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            values?.refundTransactions?.map((each, index) => {
              const { refundAmount } = each;
              if (!refundAmount)
                setFieldValue(
                  `refundTransactions.${index}.refundAmount`,
                  "0.00"
                );
            });
          }, [values?.refundTransactions]);

          return (
            <>
              {/* Refund Preview */}
              {isSplitPaymentEnabled ? (
                !isEmpty(refundSplitPreview) ? (
                  <RefundPreview
                    variant="orderDetails"
                    isLoading={isPreviewLoading}
                    transactions={refundSplitPreview.refundTransactions}
                    refundEditable={true}
                    formikPath={`refundTransactions`}
                    setFieldValue={setFieldValue}
                  />
                ) : isPreviewLoading ? (
                  <View style={tw("flex flex-row items-center")}>
                    <Text style={[fonts.default, { fontWeight: "normal" }]}>
                      {Localise(messages, "Generating Refund Preview")}
                    </Text>
                    <ActivityIndicator
                      style={{ marginLeft: 10 }}
                      color={colors.activityIndicator}
                    />
                  </View>
                ) : null
              ) : null}
              {!isMOLorder ? (
                <View style={tw("flex flex-row my-1 items-center")}>
                  <View style={{ width: "45%" }}>
                    <Text
                      style={{
                        ...fonts.heading5,
                        ...styles.labelText,
                        width: "100%",
                      }}
                    >
                      {Localise(messages, "Refund Amount")}
                    </Text>
                  </View>
                  <View style={{ width: "55%", ...tw("flex flex-row") }}>
                    <Text style={{ width: "25%", marginHorizontal: 5 }}></Text>
                    <Text
                      style={{
                        ...fonts.default,
                        ...styles.paymentValue,
                        paddingLeft: 10,
                        ...tw("items-center"),
                        backgroundColor: "rgb(244,244,244)",
                        width: "40%",
                      }}
                      numberOfLines={1}
                    >
                      {!isEmpty(refundSplitPreview)
                        ? `$ ${formatPrice(refundSplitPreview?.refundAmount)}`
                        : isFullRefund && partialRefundPrice === 0
                        ? `$ ${formatPrice(totalAmount - newRefundedAmount)}`
                        : `$ ${formatPrice(partialRefundPrice)}`}
                    </Text>
                  </View>
                </View>
              ) : null}
              {/* Refund Reason */}
              <View style={tw("flex flex-row my-1 mt-6")}>
                <Text
                  style={{
                    ...fonts.heading5,
                    ...styles.labelText,
                    paddingTop: 8,
                  }}
                >
                  {Localise(messages, "Refund Reason")}
                </Text>
                <FormFieldPicker
                  name="refundReason"
                  placeholder={{
                    label: "Select Reason",
                    value: "",
                  }}
                  data={refundReasonsData}
                  defaultValue={defaultValues.reason}
                  label=""
                  containerStyle={{
                    width: "70%",
                    paddingLeft: 0,
                  }}
                  onChange={(reason) => {
                    setRefundReasonInfo(reason);
                  }}
                />
              </View>
              {/* Save/Cancel Buttons */}
              {(isSplitPaymentEnabled && !isEmpty(refundSplitPreview)) ||
              refundedAmount > 0 ? (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <View style={tw("mx-5")}>
                    <Text
                      style={{
                        ...fonts.default,
                        marginVertical: 8,
                        textDecorationLine: "underline",
                      }}
                      onPress={() => {
                        resetForm();
                        refundFormCancelTrigger();
                      }}
                    >
                      {Localise(messages, "Cancel")}
                    </Text>
                  </View>
                  <SubmitButton
                    isLoading={isSubmitting}
                    containerStyle={{ margin: 5 }}
                    title={"Submit"}
                    error={""}
                    isSubmitOnEnter={true}
                    disableOnDirty={
                      isSplitPaymentEnabled &&
                      !isMOLorder &&
                      isEmpty(refundSplitPreview)
                    }
                  />
                </View>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

export default PaymentDetails;
