/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import moment from "moment";
import get from "lodash/get";
import { getPaymentValidationSchema } from "library/utils/payment-options";
import { basicPaymentInfo } from "../create-order/subscription-helper";
import { request } from "library/utils/request";

export const emailRegex = /([\w\.-]+@[\w\.-]+\.\w{2,4})/g; //regex

export const CustomerEligibleActions = ["Accept", "Request Changes", "Decline"];

export const ProposalActions = {
  Accept: "APPROVED",
  Decline: "DECLINED",
  "Request Changes": "CHANGE_REQUEST",
};

export const EventTypes = [
  { label: "Wedding", value: "WEDDING" },
  { label: "Funeral", value: "FUNERAL" },
  { label: "Party", value: "PARTY" },
];

export const BasicActionItems = {
  new: {
    icon: "incoming",
    label: "Draft",
  },
  pending: { icon: "accept-deadline", label: "Submitted" },
  approved: { icon: "orders", label: "Accepted" },
};

export const ActiveActionItems = {
  ...BasicActionItems,
  completed: { icon: "completed", label: "Completed" },
  cancelled: { icon: "cancelled", label: "Canceled" },
  rejected: { icon: "rejected-back", label: "Rejected" },
};

export const ActionItemsWithIcons = (hasOrders) => {
  return {
    new: {
      new: {
        icon: "incoming",
        label: "Draft",
      },
      pending: { icon: "accept-deadline", label: "Submitted" },
      cancelled: { icon: "cancelled", label: "Canceled" },
    },
    pending: {
      ...BasicActionItems,
      cancelled: { icon: "cancelled", label: "Canceled" },
      rejected: { icon: "rejected-back", label: "Rejected" },
    },
    approved: hasOrders
      ? {
          ...BasicActionItems,
          completed: { icon: "completed", label: "Completed" },
          cancelled: { icon: "cancelled", label: "Canceled" },
        }
      : {
          ...BasicActionItems,
          cancelled: { icon: "cancelled", label: "Canceled" },
          rejected: { icon: "rejected-back", label: "Rejected" },
        },
    rejected: {
      new: {
        icon: "incoming",
        label: "Draft",
      },
      pending: { icon: "accept-deadline", label: "Submitted" },
      rejected: { icon: "rejected-back", label: "Rejected" },
      approved: { icon: "orders", label: "Accepted" },
      cancelled: { icon: "cancelled", label: "Canceled" },
    },
    completed: {
      ...BasicActionItems,
      completed: { icon: "completed", label: "Completed" },
    },
    cancelled: {
      ...BasicActionItems,
      cancelled: { icon: "cancelled", label: "Canceled" },
    },
  };
};

export const Styles = {
  labelSize: 20,
  labelColor: "#114258",
  borderColor: "#e5175e",
  blockBgColor: "#E2F7EE",
  iconWidth: 22,
  iconHeight: 22,
};

export const printActions = [
  {
    key: "PrintLabels",
    label: "Print Labels",
  },
  {
    key: "PrintProposal",
    label: "Print Proposal",
  },
];

export const EventTabs = [
  { key: 0, title: "Contacts" },
  { key: 1, title: "Location & Times" },
  { key: 2, title: "Products" },
  { key: 3, title: "Proposal & Payment" },
];

export const ITEM_MAX_QUANTITY = "99";
export const MAX_ITEMS_PER_ORDER = 50;

export const DeliveryTypes = [
  { label: "Florist Delivered", value: "FLORIST_DELIVERED" },
  { label: "Pickup", value: "STORE_PICKUP" },
  { label: "Walk-In", value: "WALK_IN" },
];

export const basicLocationInfo = {
  title: "",
  name: "",
  deliveryDate: "",
  deliveryType: "",
  deliveryInstructions: "",
  locationTimes: [{ label: "", startTime: "", endTime: "", notes: "" }],
  address: {
    addressLine1: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  },
  products: [],
};

export const basicEventInfo = {
  name: "",
  type: "",
  description: "",
  customerInfo: {
    customerType: "",
    firstName: "",
    lastName: "",
    businessName: "",
    phone: "",
    email: "",
  },
  locations: [basicLocationInfo],
  currentTabIndex: 0,
  paymentDetails: basicPaymentInfo(true),
};

Yup.addMethod(Yup.string, "invalidStartTime", function (errorMessage) {
  return this.test(`invalid-start-time`, errorMessage, function (value) {
    const { path, createError, parent } = this;
    const { deliveryDate = "" } = this.from[1]?.value || {};
    const { startTime = "", endTime = "" } = parent;

    if (!startTime) return true;
    const start = startTime.split("T")[1];
    const end = endTime.split("T")[1];

    const locationStartTime = deliveryDate
      ? (deliveryDate + "T" + start).trim()
      : startTime;
    const locationEndTime =
      deliveryDate && end ? (deliveryDate + "T" + end).trim() : endTime;

    const openingTime = moment(locationStartTime);
    const closingTime = locationEndTime ? moment(locationEndTime) : "";
    const currentTime = moment();

    return (
      (currentTime.isBefore(openingTime) &&
        (closingTime ? openingTime.isBefore(closingTime) : true)) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "validateEmails", function (errorMessage) {
  return this.test("validate-emails", errorMessage, function (value) {
    const { createError, path } = this;
    const enteredMails = value ? value.split(",") : [];

    const inValidEmails = enteredMails?.reduce((acc, val) => {
      const isValidEmail = Yup.string().email().isValidSync(val.trim());
      if (!isValidEmail) acc.push(val);
      return acc;
    }, []);

    return (
      !inValidEmails.length ||
      createError({
        path,
        message: `${errorMessage} "${inValidEmails.toString()}"`,
      })
    );
  });
});

Yup.addMethod(Yup.string, "isValidAmount", function (errorMessage) {
  return this.test(`is-valid-amount`, errorMessage, function (value) {
    const { tenderedAmount, orderTotal } = this.parent;
    const paymentAmount = get(this, "from.1.value.amount", orderTotal);
    return !(
      isNaN(tenderedAmount) ||
      parseFloat(tenderedAmount) < (paymentAmount || orderTotal)
    );
  });
});

export const createFuneralLogValidationSchema = (messages, Localise) => {
  return Yup.object({
    celebrant: Yup.object({
      firstName: Yup.string()
        .label("firstName")
        .min(2, Localise(messages, "First Name should have atlease 2 letters"))
        .required(Localise(messages, "First Name is required")),
      lastName: Yup.string()
        .label("lastName")
        .min(2, Localise(messages, "Last Name should have atlease 2 letters"))
        .required(Localise(messages, "Last Name is required")),
    }),
    type: Yup.string()
      .label("type")
      .required(Localise(messages, "Select an Event")),
    memberCode: Yup.string()
      .label("memberCode")
      .required(Localise(messages, "Select a Shop")),

    locations: Yup.array().of(
      Yup.object().shape({
        deliveryDate: Yup.string()
          .label("deliveryDate")
          .required(Localise(messages, "Select Delivery Date")),
        address: Yup.object().shape({
          addressLine1: Yup.string().required(
            Localise(messages, "Please enter Street Address")
          ),
          city: Yup.string().required(Localise(messages, "Please enter City")),
          state: Yup.string().required(Localise(messages, "Select State")),
          zip: Yup.string()
            .label("zip")
            .when("country", {
              is: (value) => value && value === "US",
              then: Yup.string()
                .required(Localise(messages, "Zip required"))
                .matches(
                  new RegExp("^[0-9]{5}$"),
                  Localise(messages, "Invalid Zip")
                ),
              otherwise: Yup.string().when("country", {
                is: (value) => value && value === "CA",
                then: Yup.string()
                  .required(Localise(messages, "Zip required"))
                  .matches(
                    new RegExp(
                      "^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"
                    ),
                    Localise(messages, "Invalid Zip")
                  ),
              }),
            }),
          country: Yup.string().required(Localise(messages, "Select Country")),
        }),
      })
    ),
  });
};

export const createEventValidationSchema = (
  messages,
  Localise,
  currentTabIndex = 0,
  customerDetails,
  redeemedGiftCardsData
) => {
  return currentTabIndex === 0
    ? Yup.object({
        name: Yup.string()
          .label("name")
          .min(
            2,
            Localise(messages, "Event Name must have atleast 2 characters")
          )
          .required(Localise(messages, "Enter Event Name")),
        type: Yup.string()
          .label("type")
          .required(Localise(messages, "Select an Event")),
        memberCode: Yup.string()
          .label("memberCode")
          .required(Localise(messages, "Select a Shop")),
        description: Yup.string()
          .label("description")
          .required(Localise(messages, "Enter Event Description")),
        customerInfo: Yup.object().shape({
          firstName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter First Name")
            ),
          }),
          lastName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter Last Name")
            ),
          }),
          businessName: Yup.string().when("customerType", {
            is: (value) => value === "Business",
            then: Yup.string().required(
              Localise(messages, "Please enter Business Name")
            ),
          }),
          email: Yup.string()
            .email(Localise(messages, "Enter valid Email"))
            .matches(
              /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
              "Invalid Email address"
            )
            .required(Localise(messages, "Please enter Customer Email")),
          phone: Yup.string()
            .matches(
              new RegExp(
                "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
              ),
              Localise(messages, "Enter valid Phone")
            )
            .required(Localise(messages, "Please enter Customer Phone")),
          customerType: Yup.string()
            .label("customerType")
            .required(Localise(messages, "Please select Customer Type")),
        }),
      })
    : currentTabIndex === 1
    ? Yup.object({
        name: Yup.string()
          .label("name")
          .min(
            2,
            Localise(messages, "Event Name must have atleast 2 characters")
          )
          .required(Localise(messages, "Enter Event Name")),
        type: Yup.string()
          .label("type")
          .required(Localise(messages, "Select an Event")),
        memberCode: Yup.string()
          .label("memberCode")
          .required(Localise(messages, "Select a Shop")),
        description: Yup.string()
          .label("description")
          .required(Localise(messages, "Enter Event Description")),
        locations: Yup.array().of(
          Yup.object().shape({
            title: Yup.string()
              .label("title")
              .required(Localise(messages, "Enter Title")),
            name: Yup.string()
              .label("name")
              .required(Localise(messages, "Enter Location")),
            deliveryDate: Yup.string()
              .label("deliveryDate")
              .required(Localise(messages, "Select Date")),
            deliveryType: Yup.string()
              .label("deliveryType")
              .required(Localise(messages, "Select Delivery Type")),
            address: Yup.object().shape({
              addressLine1: Yup.string().required(
                Localise(messages, "Please enter Street Address")
              ),
              city: Yup.string().required(
                Localise(messages, "Please enter City")
              ),
              state: Yup.string().required(Localise(messages, "Select State")),
              zip: Yup.string()
                .label("zip")
                .when("country", {
                  is: (value) => value && value === "US",
                  then: Yup.string()
                    .required(Localise(messages, "Postal Code required"))
                    .matches(
                      new RegExp("^[0-9]{5}$"),
                      Localise(messages, "Invalid Postal Code")
                    ),
                  otherwise: Yup.string().when("country", {
                    is: (value) => value && value === "CA",
                    then: Yup.string()
                      .required(Localise(messages, "Postal Code required"))
                      .matches(
                        new RegExp(
                          "^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"
                        ),
                        Localise(messages, "Invalid Postal Code")
                      ),
                  }),
                }),
              country: Yup.string().required(
                Localise(messages, "Select Country")
              ),
            }),
            locationTimes: Yup.array().of(
              Yup.object().shape({
                label: Yup.string()
                  .label("label")
                  .required(Localise(messages, "Enter Label")),
                startTime: Yup.string()
                  .required(Localise(messages, "Select Start Time"))
                  .invalidStartTime(Localise(messages, "Invalid Start Time")),
                endTime: Yup.string().required(
                  Localise(messages, "Select End Time")
                ),
              })
            ),
          })
        ),
      })
    : currentTabIndex === 2
    ? Yup.object({
        name: Yup.string()
          .label("name")
          .min(
            2,
            Localise(messages, "Event Name must have atleast 2 characters")
          )
          .required(Localise(messages, "Enter Event Name")),
        type: Yup.string()
          .label("type")
          .required(Localise(messages, "Select an Event")),
        description: Yup.string()
          .label("description")
          .required(Localise(messages, "Enter Event Description")),
        memberCode: Yup.string()
          .label("memberCode")
          .required(Localise(messages, "Select a Shop")),
        locations: Yup.array().of(
          Yup.object().shape({
            productInfo: Yup.string()
              .label("productInfo")
              .when("products", {
                is: (value) => value && value.length === 0,
                then: Yup.string().required(
                  Localise(
                    messages,
                    "Select product from the dropdown list or from Product Catalog"
                  )
                ),
              }),
            products: Yup.array().of(
              Yup.object().shape({
                productLabel: Yup.string()
                  .label("productLabel")
                  .required(Localise(messages, "Enter Product Label")),
                productName: Yup.string()
                  .label("productName")
                  .required(Localise(messages, "Invalid Product")),
                amount: Yup.string()
                  .label("amount")
                  .required(Localise(messages, "Invalid Price"))
                  .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
                quantity: Yup.string()
                  .label("quantity")
                  .required(Localise(messages, "Select Quantity")),
              })
            ),
          })
        ),
      })
    : Yup.object().shape({
        paymentDetails: Yup.object().when("isPaymentRequired", {
          is: (isPaymentRequired) => !!isPaymentRequired,
          then: getPaymentValidationSchema(
            redeemedGiftCardsData,
            false,
            customerDetails
          ),
        }),
      });
};

export const customerActionValidationSchema = (
  messages,
  Localise,
  action = ""
) => {
  return ["accept", "decline"].includes(action)
    ? Yup.object({
        fullLegalName: Yup.string()
          .label("fullLegalName")
          .required(Localise(messages, "Enter Full Legal Name")),
      })
    : Yup.object({
        customerNotes: Yup.string()
          .label("customerNotes")
          .required(Localise(messages, "Enter Customer Notes")),
      });
};

export const sendEventProposalValidationSchema = (messages, Localise) => {
  return Yup.object({
    recipient: Yup.string()
      .label("recipient")
      .email(Localise(messages, "Inavlid Recipient Email"))
      .required(Localise(messages, "Enter Recipient Email")),
    cc: Yup.string()
      .label("cc")
      .validateEmails(Localise(messages, "Inavlid cc")),
    subject: Yup.string()
      .label("subject")
      .required(Localise(messages, "Enter Subject")),
  });
};

export const proposalInitialValues = { recipient: "", subject: "", email: "" };

export const getLocationsWithConvertedTime = (
  locations = [],
  addressVerificationInfo = [],
  toUtc = false
) => {
  return locations.map((location, index) => {
    const {
      locationTimes = [],
      deliveryFee = 0,
      retailDeliveryFee = 0,
      addressVerificationInfo: locationAddressVerificationInfo = {},
      ...other
    } = location;
    return {
      ...other,
      addressVerificationInfo:
        addressVerificationInfo[index] || locationAddressVerificationInfo,
      deliveryFee: parseFloat(deliveryFee).toFixed(2),
      retailDeliveryFee: parseFloat(retailDeliveryFee).toFixed(2),
      deliveryDate: moment(location.deliveryDate).format("YYYY-MM-DD"),
      locationTimes: locationTimes.map((locationTime) => {
        const { startTime, endTime, ...other } = locationTime;
        return {
          ...other,
          startTime: toUtc
            ? moment(startTime).utc()
            : moment(startTime).format(),
          endTime: toUtc ? moment(endTime).utc() : moment(endTime).format(),
        };
      }),
    };
  });
};

export const CSVHeader =
  "Event Name,Location,Product Label,Product Name,Delivery Date,Delivery Time";

export const downloadEventLabels = (values) => {
  const { name, locations } = values;
  const csvData = locations.map((locationInfo) => {
    const {
      title,
      deliveryDate,
      locationTimes = [],
      products = [],
    } = locationInfo;

    return products
      .map((product) => {
        const { productLabel, productName, quantity = 1 } = product;
        let prodLabelArr = [];
        const eventLabel = {
          name,
          location: title,
          productLabel,
          productName,
          deliveryDate: moment(deliveryDate).format("MM/DD/YYYY"),
          deliveryTime: moment(locationTimes[0].startTime).format("hh:mm a"),
        };
        for (let index = 0; index < quantity; index++) {
          prodLabelArr.push(Object.values(eventLabel).join(","));
        }
        return prodLabelArr.join("\n");
      })
      .join("\n");
  });

  const blob = new Blob([[CSVHeader, ...csvData].join("\n")], {
    type: "text/csv",
  });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", url);
  downloadLink.setAttribute("download", `${name}.csv`);
  downloadLink.click();
};

export const downloadImage = (memberCode, imageURL) => {
  const imageResp = request("download-image", {
    memberCode,
    imageName: imageURL,
  });
  return imageResp;
};
