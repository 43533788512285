import { PrintIframeAction, ToasterHandler } from "components/elements";
import { getSingleOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { getSingleCardPrintHTML } from "components/views/drawer/order-details/trifold-print-helper";
import { getSingleInvoicePrintHTML } from "components/views/drawer/order-details/invoice-print-helper";
import { getSingleWorksheetPrintHTML } from "components/views/drawer/order-details/worksheet-print-helper";
import UserProfileStorage from "library/storage/userProfile";
import { Platform } from "react-native";
import isEmpty from "lodash/isEmpty";
import { updateAutoPrintOrders } from "library/sagas/ongoing/current-orders/slice";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { fetchZoneDetails } from "library/utils/deliveryService";
import { getPrintReceiptContent } from "components/views/drawer/create-order/helper";

export const rejectReasons = [
  {
    label: "Order below minimum for SKU / product",
    value: "Below Min for SKU",
  },
  {
    label: "Order below minimum for distance",
    value: "Below Min for distance",
  },
  {
    label: "Customer canceled the order",
    value: "Customer Cancelled the Order",
  },
  {
    label: "Outside of delivery - Zip Code covered",
    value: "Outside of delivery - zip covered",
  },
  {
    label: "Outside of delivery - Zip Code not covered",
    value: "Outside of delivery - zip not covered",
  },
  {
    label: "Outside of delivery - remove Zip Code",
    value: "Outside of delivery - remove ZIP",
  },
  {
    label: "Stems Not Available",
    value: "Stems Not Available",
  },
  {
    label: "Container not available",
    value: "Containers Not Available",
  },
  {
    label: "Add-on not available",
    value: "Add on Not Available",
  },
  {
    label: "Recipient Not Available",
    value: "Recipient Not Available",
  },
  {
    label: "Patient discharged",
    value: "Patient discharged",
  },
  {
    label: "Too late for delivery - product",
    value: "Too late for delivery - product",
  },
  {
    label: "Too late for delivery - distance",
    value: "Too late for delivery - distance",
  },
  {
    label: "Too late for delivery - pool",
    value: "Too late for delivery - pool",
  },
  { label: "Other", value: "Other" },
];

export const cancelReasons = [
  {
    label: "Customer canceled the order",
    value: "Customer Cancelled the Order",
  },
  { label: "Other", value: "Other" },
];
export const fulfillmentRejectReasons = [
  {
    label: "Filler rejected the order",
    value: "Filler rejected the order",
  },
];
export const fulfillmentErrorReasons = [
  {
    label: "Filler unavailable",
    value: "Filler unavailable",
  },
];
export const cancelDenyReasons = [
  {
    label: "Already delivered",
    value: "Already Delivered",
  },
  { label: "Other", value: "Other" },
];

export const adjustmentReasons = [
  {
    label: "No order record found",
    value: "NO_RECORD",
  },
  { label: "Non-delivery", value: "NON_DELIVERY" },
  {
    label: "Overcharge",
    value: "OVERCHARGED",
  },
  {
    label: "Undercharge",
    value: "UNDERCHARGED",
  },
  {
    label: "Duplicate Charge",
    value: "DUPLICATE_CHARGE",
  },
  {
    label: "Paid Direct",
    value: "PAID_DIRECT",
  },
  {
    label: "Canceled",
    value: "ORDER_CANCELED",
  },
  {
    label: "Charged in Error",
    value: "CHARGED_IN_ERROR",
  },
  {
    label: "Incoming Order, not Outgoing",
    value: "INCOMING_NOT_OUTGOING",
  },
  {
    label: "Unsatisfactory",
    value: "UNSATISFACTORY",
  },
];

export const invoicePrintAction = [
  {
    key: "Invoice",
    label: "Invoice",
  },
];

export const orderDetailPrintAction = [
  {
    key: "OrderDetail",
    label: "Order Detail",
  },
];

export const printActions = [
  ...orderDetailPrintAction,
  {
    key: "TriFoldCard",
    label: "Tri-Fold Card",
  },
];

export const designerWorksheetAction = [
  { key: "DesignerWorksheet", label: "Worksheet" },
];

export const orderMessagesPrintAction = [
  { key: "OrderMessages", label: "Related Messages" },
];

export const orderReceipt = [{ key: "OrderReceipt", label: "Receipt" }];

export const getZoneDetails = async (orderDetails) => {
  const { latitude = "", longitude = "" } = orderDetails?.recipientInfo;
  const { memberCode: shopCode = "" } = orderDetails?.receivingMember;
  const zoneDetails =
    (await fetchZoneDetails({ latitude, longitude, shopCode })) || [];
  return zoneDetails;
};

export const printTrigger = ({
  selectedAction,
  orderDetails,
  memberCodes,
  operatorName: operator,
  userRole,
  messages,
  Localise,
  dispatch,
  selectedproducts,
  paymentDetails,
  cardInfo,
  imagesData,
  cardMessage,
  setSelectedAction,
  triggerAction,
  orderDetailResponse,
  ordersList = false,
  deliveryInfo,
  recordId,
  isDSSubmitEligible,
  customerInfo,
  isAdhocTriFold = false,
  isCallFromEnclosureCard = false,
}) => {
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;

  const { memberCode: sendMemberCode } = orderDetails?.sendingMember;
  const { memberCode: recMemberCode } = orderDetails?.receivingMember;

  const isInBoundOrder = orderDetails?.direction === "INBOUND";
  const isOutBoundOrder = orderDetails?.direction === "OUTBOUND";

  const isSenderOrder =
    isOutBoundOrder &&
    memberCodes.includes(sendMemberCode) &&
    sendMemberCode !== recMemberCode;

  const sourceMemberCode = isInBoundOrder
    ? orderDetails?.receivingMember?.memberCode
    : orderDetails?.sendingMember?.memberCode;

  const { storePickupDateTime = "" } = orderDetails?.pickupInfo || {};
  const isStorePickupOrder = deliveryInfo?.deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryInfo?.deliveryMethod === "WALK_IN";
  const isPickUpOrder =
    ((orderDetails?.orderSource === "DoorDash" ||
      orderDetails?.orderSource === "UberEats") &&
      !isEmpty(storePickupDateTime)) ||
    isStorePickupOrder ||
    isWalkInOrder ||
    (orderDetails?.orderSource === "MOL" &&
      deliveryInfo?.deliveryMethod === "MOL_CUSTOMER_PICKUP");

  // QR Data Payload for generating the QR code
  const qrData = [
    {
      oid: orderDetails?.orderItemId || "", // orderItemId
      sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
      dm: deliveryInfo?.deliveryMethod || "", // order deliveryMethod
    },
  ];
  const {
    order_details_print_settings = [],
    order_details_outgoing_print_settings = [],
    qr_code_global_enabled = "",
    qr_code_order_details_enabled = "",
    qr_code_trifold_enabled = "",
    qr_code_invoice_enabled = "",
    qr_code_worksheet_enabled = "",
  } = UserProfileStorage.getShopPreferences(sourceMemberCode);

  const showQrCode = qr_code_global_enabled === "true";

  const showOrderDetailsQrCode =
    showQrCode && qr_code_order_details_enabled === "true";
  const showTriFoldQrCode = showQrCode && qr_code_trifold_enabled === "true";
  const showInvoiceQrCode = showQrCode && qr_code_invoice_enabled === "true";
  const showWorksheetQrCode =
    showQrCode && qr_code_worksheet_enabled === "true";

  if (selectedAction === "accept-print" || selectedAction === "print") {
    let qrImageData = "";

    const printPreferencesArray = isInBoundOrder
      ? order_details_print_settings
      : isOutBoundOrder
      ? order_details_outgoing_print_settings
      : [];

    const isRemotePrintEnabled = hasRemotePrintEnabled(printPreferencesArray);
    const remotePrinterName = isRemotePrintEnabled
      ? getRemotePrinterName(printPreferencesArray)
      : "";

    // order detail print trigger
    const orderDetailPrint = () => {
      const printPreferences =
        (isElectronApp &&
          (printPreferencesArray || []).find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      const updatePrintStatus = (remotePrint = false) => {
        if (!remotePrint) {
          if (isElectronApp && !isEmpty(printPreferences)) {
            showPrintToaster({
              remotePrint,
              printerName: printPreferences?.printer,
              artifactName: "Order Details",
              Localise,
              messages,
            });
          }

          if (orderDetails?.isPrinted) {
            if (!ordersList) setSelectedAction("");
            return;
          }
        }

        if (!ordersList) {
          triggerAction({
            action: selectedAction,
            remotePrint,
            remotePrinterName,
          });
          setSelectedAction("");
        } else {
          triggerAction(
            selectedAction,
            deliveryInfo?.deliveryDate,
            recordId,
            deliveryInfo?.deliveryMethod,
            sourceMemberCode,
            isDSSubmitEligible,
            remotePrint,
            remotePrinterName
          );
        }
      };

      if (isRemotePrintEnabled) {
        updatePrintStatus(true, remotePrinterName);
      } else {
        PrintIframeAction(
          getSingleOrderPrintHTML({
            orderDetails,
            memberCodes,
            operatorName: operator,
            messages,
            Localise,
            selectedproducts,
            customerInfo,
            qrImageData,
          }),
          { printType: "orderDetails", preferences: printPreferences },
          updatePrintStatus
        );
      }
    };

    !isRemotePrintEnabled && showOrderDetailsQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              orderDetailPrint();
            },
            reject: () => {
              orderDetailPrint();
            },
          })
        )
      : orderDetailPrint();
  } else if (selectedAction === "triCardPrint") {
    let qrImageData = "";
    const {
      trifold_card_print_settings: printPreferencesArray = [],
      additional_sympathy_card,
    } = UserProfileStorage.getShopPreferences(sourceMemberCode);
    const isRemotePrintEnabled = hasRemotePrintEnabled(printPreferencesArray);
    const remotePrinterName = isRemotePrintEnabled
      ? getRemotePrinterName(printPreferencesArray)
      : "";

    const triFoldPrintTrigger = async () => {
      const { cardSettings: cardSettingsText = "{}" } = cardInfo;
      const cardSettings = JSON.parse(cardSettingsText);
      const {
        isLogoOpted,
        isBannerOpted,
        logoImageURL,
        bannerImageURL,
        orderInfo = [],
        contactInfoBottomHalf = "Recipient Info",
      } = cardSettings;
      let imageUrl = "";

      const needDeliveryZone =
        contactInfoBottomHalf === "Delivery Info" &&
        orderInfo.includes("Delivery Zone");

      if (isLogoOpted) {
        imageUrl = logoImageURL;
      } else if (isBannerOpted) {
        imageUrl = bannerImageURL;
      }

      const imageData = imagesData[imageUrl];
      const fileInfo = {
        uri: imageData ? `data:image/png;base64,${imageData}` : "",
        name: imageUrl,
      };

      const printPreferences =
        (isElectronApp &&
          (printPreferencesArray || [])?.find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      const updatePrintStatus = (
        remotePrint = false,
        remotePrinterName = ""
      ) => {
        if (!remotePrint) {
          if (isElectronApp && !isEmpty(printPreferences)) {
            showPrintToaster({
              remotePrint,
              printerName: printPreferences?.printer,
              artifactName: "Tri-Fold",
              Localise,
              messages,
            });
          }

          if (orderDetails?.isTrifoldDetailsPrinted) {
            if (!ordersList) setSelectedAction("");
            return;
          }
        }

        dispatch(
          updateAutoPrintOrders({
            ...(remotePrint && { remotePrintRequest: true }),
            payload: [
              {
                memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                autoPrintOrders: [
                  {
                    orderId:
                      orderDetailResponse?.orderId || orderDetails?.orderItemId,
                    orderItemId: orderDetails?.orderItemId,
                    orderNumber: orderDetails?.erosOrderNumber,
                    isTrifoldDetailsPrinted: true,
                    deliveryMethod: deliveryInfo?.deliveryMethod,
                    sendingMemberCode: sendMemberCode,
                    fillingMemberCode: recMemberCode,
                    isAutoPrint: false,
                  },
                ],
              },
            ],
            resolve: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
              remotePrint &&
                showPrintToaster({
                  remotePrint,
                  printerName: remotePrinterName,
                  artifactName: "Tri-Fold",
                  Localise,
                  messages,
                });
            },
            reject: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
            },
          })
        );

        if (!ordersList && !isCallFromEnclosureCard) setSelectedAction("");
      };

      if (isRemotePrintEnabled) {
        updatePrintStatus(true, remotePrinterName);
      } else {
        PrintIframeAction(
          getSingleCardPrintHTML({
            recipientInfo: orderDetails?.recipientInfo,
            receivingMember: orderDetails?.receivingMember,
            cardSettings,
            cardMessage,
            fileInfo,
            isPickUpOrder,
            orderSource: orderDetails?.orderSource,
            pickUpBy: deliveryInfo?.pickUpBy,
            isAdhocTriFold,
            numberOfPrints:
              additional_sympathy_card === "true" &&
              deliveryInfo?.occasion === "FUNERAL"
                ? 2
                : 1,
            qrImageData,
            zoneDetails:
              !isAdhocTriFold && needDeliveryZone
                ? await getZoneDetails(orderDetails)
                : [],
            orderDetails,
          }),
          { printType: "trifold", preferences: printPreferences },
          updatePrintStatus,
          "iframeId"
        );
      }
    };

    !isRemotePrintEnabled && showTriFoldQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              triFoldPrintTrigger();
            },
            reject: () => {
              triFoldPrintTrigger();
            },
          })
        )
      : triFoldPrintTrigger();
  } else if (selectedAction === "invoice") {
    let qrImageData = "";
    const {
      legal_size_invoice_print_settings: printPreferencesArray = [],
      additional_sympathy_invoice,
    } = UserProfileStorage.getShopPreferences(sourceMemberCode);
    const isRemotePrintEnabled = hasRemotePrintEnabled(printPreferencesArray);
    const remotePrinterName = isRemotePrintEnabled
      ? getRemotePrinterName(printPreferencesArray)
      : "";

    // invoice print trigger
    const invoicePrintTrigger = async () => {
      const printPreferences =
        (isElectronApp &&
          (printPreferencesArray || []).find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      const updatePrintStatus = (
        remotePrint = false,
        remotePrinterName = ""
      ) => {
        if (!remotePrint) {
          if (isElectronApp && !isEmpty(printPreferences)) {
            showPrintToaster({
              remotePrint,
              printerName: printPreferences?.printer,
              artifactName: "Invoice",
              Localise,
              messages,
            });
          }
          if (orderDetails?.isInvoiceDetailsPrinted) {
            if (!ordersList) setSelectedAction("");
            return;
          }
        }

        dispatch(
          updateAutoPrintOrders({
            ...(remotePrint && { remotePrintRequest: true }),
            payload: [
              {
                memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                autoPrintOrders: [
                  {
                    orderId:
                      orderDetailResponse?.orderId || orderDetails?.orderItemId,
                    orderItemId: orderDetails?.orderItemId,
                    orderNumber: orderDetails?.erosOrderNumber,
                    isInvoiceDetailsPrinted: true,
                    deliveryMethod: deliveryInfo?.deliveryMethod,
                    sendingMemberCode: sendMemberCode,
                    fillingMemberCode: recMemberCode,
                    isAutoPrint: false,
                  },
                ],
              },
            ],
            resolve: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
              remotePrint &&
                showPrintToaster({
                  remotePrint,
                  printerName: remotePrinterName,
                  artifactName: "Invoice",
                  Localise,
                  messages,
                });
            },
            reject: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
            },
          })
        );

        if (!ordersList && !isCallFromEnclosureCard) setSelectedAction("");
      };

      if (isRemotePrintEnabled) {
        updatePrintStatus(true, remotePrinterName);
      } else {
        PrintIframeAction(
          getSingleInvoicePrintHTML({
            orderDetails,
            memberCodes,
            messages,
            Localise,
            customerInfo,
            paymentDetails,
            userRole,
            operatorName: operator,
            zoneDetails: await getZoneDetails(orderDetails),
            qrImageData,
            numberOfPrints:
              additional_sympathy_invoice === "true" &&
              deliveryInfo?.occasion === "FUNERAL"
                ? 2
                : 1,
          }),
          { printType: "invoice", preferences: printPreferences },
          updatePrintStatus
        );
      }
    };

    !isRemotePrintEnabled && showInvoiceQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              invoicePrintTrigger();
            },
            reject: () => {
              invoicePrintTrigger();
            },
          })
        )
      : invoicePrintTrigger();
  } else if (selectedAction === "designerWorksheetPrint") {
    let qrImageData = "";
    const { designer_worksheet_print_settings: printPreferencesArray = [] } =
      UserProfileStorage.getShopPreferences(sourceMemberCode);
    const isRemotePrintEnabled = hasRemotePrintEnabled(printPreferencesArray);
    const remotePrinterName = isRemotePrintEnabled
      ? getRemotePrinterName(printPreferencesArray)
      : "";

    const designPrintTrigger = async () => {
      const printPreferences =
        (isElectronApp &&
          (printPreferencesArray || []).find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      const updatePrintStatus = (
        remotePrint = false,
        remotePrinterName = ""
      ) => {
        if (!remotePrint) {
          if (isElectronApp && !isEmpty(printPreferences)) {
            showPrintToaster({
              remotePrint,
              printerName: printPreferences?.printer,
              artifactName: "Designer Worksheet",
              Localise,
              messages,
            });
          }

          if (orderDetails?.isDesignerWorkSheetPrinted) {
            if (!ordersList) setSelectedAction("");
            return;
          }
        }

        dispatch(
          updateAutoPrintOrders({
            ...(remotePrint && { remotePrintRequest: true }),
            payload: [
              {
                memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                autoPrintOrders: [
                  {
                    orderId:
                      orderDetailResponse?.orderId || orderDetails?.orderItemId,
                    orderItemId: orderDetails?.orderItemId,
                    orderNumber: orderDetails?.erosOrderNumber,
                    isDesignerWorkSheetPrinted: true,
                    deliveryMethod: deliveryInfo?.deliveryMethod,
                    sendingMemberCode: sendMemberCode,
                    fillingMemberCode: recMemberCode,
                    isAutoPrint: false,
                  },
                ],
              },
            ],
            resolve: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
              remotePrint &&
                showPrintToaster({
                  remotePrint,
                  printerName: remotePrinterName,
                  artifactName: "Designer Worksheet",
                  Localise,
                  messages,
                });
            },
            reject: () => {
              if (!ordersList) {
                triggerAction({ action: selectedAction });
              }
            },
          })
        );

        if (!ordersList) setSelectedAction("");
      };

      if (isRemotePrintEnabled) {
        updatePrintStatus(true, remotePrinterName);
      } else {
        PrintIframeAction(
          getSingleWorksheetPrintHTML({
            orderDetails,
            operatorName: operator,
            messages,
            Localise,
            selectedproducts,
            customerInfo,
            paymentDetails,
            zoneDetails: await getZoneDetails(orderDetails),
            qrImageData,
          }),
          {
            printType: "designerWorksheet",
            preferences: printPreferences,
          },
          updatePrintStatus
        );
      }
    };

    !isRemotePrintEnabled && showWorksheetQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              designPrintTrigger();
            },
            reject: () => {
              designPrintTrigger();
            },
          })
        )
      : designPrintTrigger();
  } else if (selectedAction === "receipt") {
    const updatePrintStatus = () => {
      // show success toaster
      isElectronApp &&
        ToasterHandler(
          "hooray",
          Localise(messages, `Receipt has been sent for printing`)
        );

      if (orderDetails?.isReceiptPrinted) {
        if (!ordersList) setSelectedAction("");
        return;
      }

      dispatch(
        updateAutoPrintOrders({
          payload: [
            {
              memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
              autoPrintOrders: [
                {
                  orderId:
                    orderDetailResponse?.orderId || orderDetails?.orderItemId,
                  orderItemId: orderDetails?.orderItemId,
                  orderNumber: orderDetails?.erosOrderNumber,
                  isReceiptPrinted: true,
                  deliveryMethod: deliveryInfo?.deliveryMethod,
                  sendingMemberCode: sendMemberCode,
                  fillingMemberCode: recMemberCode,
                  isAutoPrint: false,
                },
              ],
            },
          ],
          resolve: () => {
            if (!ordersList) {
              triggerAction({ action: selectedAction });
            }
          },
          reject: () => {
            if (!ordersList) {
              triggerAction({ action: selectedAction });
            }
          },
        })
      );

      if (!ordersList) setSelectedAction("");
    };

    const content = getPrintReceiptContent(
      orderDetailResponse,
      orderDetailResponse,
      isElectronApp ? "POS" : "Web"
    );
    if (isElectronApp) {
      const printContent = [content];
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("printOrderInfo", 1, printContent, true);
      updatePrintStatus();
    } else {
      PrintIframeAction(
        content,
        { printType: "receipt", preferences: {} },
        updatePrintStatus,
        "iframeId"
      );
    }
  }
};

export const getIsPrintedOption = (
  isPrinted,
  isDesignerWorkSheetPrinted,
  isTrifoldDetailsPrinted,
  isInvoiceDetailsPrinted,
  isReceiptPrinted,
  isSideCarOpen,
  isWiredOrder,
  nonOLCMessages = [],
  unPrintedMessages = [],
  showPrintDropdown = true
) => {
  const printOptions = [
    { condition: isPrinted, value: "OrderDetail" },
    {
      condition: isDesignerWorkSheetPrinted,
      value: "DesignerWorksheet",
    },
    { condition: isTrifoldDetailsPrinted, value: "TriFoldCard" },
    { condition: isInvoiceDetailsPrinted, value: "Invoice" },
    { condition: isReceiptPrinted, value: "OrderReceipt" },
    {
      condition:
        !isSideCarOpen &&
        isWiredOrder &&
        nonOLCMessages?.length &&
        unPrintedMessages?.length == 0,
      value: "OrderMessages",
    },
  ];

  return printOptions
    .filter(
      (option) =>
        option.condition &&
        (!showPrintDropdown ? option.value === "OrderDetail" : true)
    )
    .map((option) => option.value);
};

export const getPrintActions = (
  sourceMemberCode,
  isSideCarOpen,
  isWiredOrder,
  nonOLCMessages,
  showPrintDropdown = true,
  showReceiptOption = true
) => {
  const showOrderMessagePrintActions =
    !isSideCarOpen && isWiredOrder && nonOLCMessages.length;

  if (!showPrintDropdown) {
    return orderDetailPrintAction;
  }

  if (isMHQNonCoreMember(sourceMemberCode)) {
    return [
      ...printActions,
      ...invoicePrintAction,
      ...designerWorksheetAction,
      ...(showReceiptOption ? orderReceipt : []),
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  } else {
    return [
      ...printActions,
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  }
};
export const getExtendedMenuOptions = ({
  isPrinted,
  isDesignerWorkSheetPrinted,
  isTrifoldDetailsPrinted,
  isInvoiceDetailsPrinted,
  isReceiptPrinted,
  isSideCarOpen,
  sourceMemberCode,
  isWiredOrder,
  nonOLCMessages,
  unPrintedMessages,
  showPrintDropdown = true,
  showReceiptOption = false,
}) => {
  const printedOptions = getIsPrintedOption(
    isPrinted,
    isDesignerWorkSheetPrinted,
    isTrifoldDetailsPrinted,
    isInvoiceDetailsPrinted,
    isReceiptPrinted,
    isSideCarOpen,
    isWiredOrder,
    nonOLCMessages,
    unPrintedMessages,
    showPrintDropdown
  );
  return getPrintActions(
    sourceMemberCode,
    isSideCarOpen,
    isWiredOrder,
    nonOLCMessages,
    showPrintDropdown,
    showReceiptOption
  ).map((option) => ({
    ...option,
    selected: printedOptions.includes(option.key),
  }));
};

export const hasRemotePrintEnabled = (printPreferencesArray = []) => {
  const isRemotePrintGloballyEnabled = Environment.get(
    "ENABLE_REMOTE_PRINT",
    true
  );
  const hasRemotePrintPreference = (printPreferencesArray || []).some(
    (item) => !!item.remote_print
  );
  return isRemotePrintGloballyEnabled && hasRemotePrintPreference;
};

export const getRemotePrinterName = (printPreferencesArray) => {
  const remotePreference = (printPreferencesArray || []).find(
    (item) => !!item.remote_print
  );
  return remotePreference?.printer || "";
};

export const showPrintToaster = ({
  remotePrint,
  printerName,
  artifactName,
  Localise,
  messages,
}) => {
  printerName &&
    artifactName &&
    ToasterHandler(
      "hooray",
      Localise(
        messages,
        `${artifactName} has been sent to ${printerName} for ${
          remotePrint ? "remote" : ""
        } printing`
      )
    );
};
