import React, { useState, useEffect, useRef } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import { Text, Button } from "react-native-elements";
import tw from "tailwind-rn";
import { Form } from "components/elements/forms";
import EventDetails from "./event-details";
import Products from "./products";
import ProposalAndPayment from "./proposal-and-payment";
import LocationAndTimes from "./location-and-times";
import { TabBar } from "components/elements";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import { backgroundColors, theme } from "styles/theme";
import ScreenHeader from "components/containers/header/screen";
import SideCar from "../create-order/side-car";
import get from "lodash/get";
import set from "lodash/set";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { getProductObj } from "../create-order/helper";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import I18NContext from "library/contexts/i18N";
import {
  selectEventDetails,
  selectOrdersInfo,
} from "library/sagas/views/home/drawer/events/selector";
import { setSearchText } from "library/sagas/views/home/drawer/events/slice";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import { useDispatch, useSelector } from "react-redux";
import { saveCustomerInfo } from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  setPaymentDetails,
  setRedeemedGcData,
} from "library/sagas/views/home/drawer/create-order/slice";
import Processing from "../create-order/processing";
import { ConfirmModal } from "components/wrappers";
import EventBasicInfo from "./event-basic-info";
import IMAGES from "static/assets/images";
import { isActiveOrder } from "library/utils/orderListing";
import { initiateRefundThroughTerminal } from "library/utils/payment-options";
import SendProposal from "./proposal-and-payment/send-proposal";
import {
  selectRedeemedGiftCardsData,
  selectPaymentDetails,
} from "library/sagas/views/home/drawer/create-order/selector";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";
import { getMaxHeight } from "library/utils/formatter";

import {
  // ITEM_MAX_QUANTITY,
  MAX_ITEMS_PER_ORDER,
} from "./config";
import {
  EventTabs,
  getLocationsWithConvertedTime,
  basicEventInfo,
  createEventValidationSchema,
} from "./config";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { basicPaymentInfo } from "../create-order/subscription-helper";
import { handleSave } from "./helper";
import { ToasterHandler } from "components/elements";

import {
  ValidateAndCreateOrder,
  getError,
  getPaymentInfo,
  getRefundAmount,
  getPaidAndUnpaidAmounts,
} from "library/utils/createOrder";
import isObject from "lodash/isObject";

const Components = [
  EventDetails,
  LocationAndTimes,
  Products,
  ProposalAndPayment,
];

const CreateEvent = (props) => {
  const navigation = useNavigation();
  const { setParams } = navigation;
  const dispatch = useDispatch();
  const { proxyUser, suspendedMembers = [] } =
    React.useContext(UserProfileContext);

  const { params: { id = "", action = "", meatballAction = "" } = {} } =
    props?.route || {};

  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const customerDetails = useSelector(selectCustomerDetails);
  const redeemedGiftCardsData = useSelector(selectRedeemedGiftCardsData);
  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
  const { status: orderPaymentStatus } = useSelector(selectPaymentDetails);

  const [sideCarInfo, setSideCarInfo] = useState({});
  const [validationOnChange, setValidationOnChange] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [authorizeGcPaymentMethod, setAuthorizeGcPaymentMethod] =
    useState(false);
  const [isGcAdded, setIsGcAdded] = useStateIfMounted([]);

  const [isGcAuthorizationSuccess, setIsGcAuthorizationSuccess] =
    useStateIfMounted(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [eventOrderIds, setEventOrderIds] = useState([]);
  const [eventOrdersStatusInfo, setEventOrdersStatusInfo] = useState({});
  const [completedTabIndexs, setCompletedTabIndexs] = useState(
    id ? [0, 1, 2] : []
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(id ? 3 : 0);
  const [nextTabIndex, setNextTabIndex] = useState(0);

  const eventDetails = useSelector(selectEventDetails);
  const ordersInfo = useSelector(selectOrdersInfo) || [];

  const createEditEventFormRef = useRef();
  const sendingMemberRef = useRef("");
  const terminalTxnCancellingRef = useRef(false);

  let eventData;
  if (action === "edit" && id) {
    eventData = {
      ...eventDetails,
      locations: getLocationsWithConvertedTime(eventDetails?.locations),
      customerInfo: {
        ...eventDetails?.customer?.details,
        customerNotes: eventDetails?.customer?.details?.notes,
      },
      paymentDetails: basicPaymentInfo(true),
      orderIds: eventDetails?.customer?.details.orderIds,
    };
  }

  const memberCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.CREATE_EVENT in permissions[memberCode])
      memberCodes.push(memberCode);
  });

  const customBorder = () => {
    return (
      <View style={{ marginRight: 10 }}>
        <Text
          style={{
            width: 6,
            height: "100%",
            backgroundColor: backgroundColors.primary,
            border: 0,
            borderTopRightRadius: "90% 40%",
          }}
        ></Text>
        <Text
          style={{
            width: 6,
            height: "100%",
            backgroundColor: backgroundColors.primary,
            border: 0,
            borderBottomRightRadius: "90% 40%",
          }}
        ></Text>
      </View>
    );
  };

  const showToaster = (message, formikBag, isError = true) => {
    const msg = isError ? getError(message) : message;
    setFormSubmitting(false);
    formikBag.setSubmitting(false);
    ToasterHandler(isError ? "uh oh" : "nice", Localise(messages, msg));
  };

  const createEventErrorCallback = ({
    error,
    formikBag,
    createEventReqObj,
    doRefund = false,
  }) => {
    const paymentMethodInfo = get(
      createEventReqObj,
      "paymentDetails.paymentMethod.0",
      {}
    );

    // We are initiating refunds always (if it is terminal payment) as we are in a catch block that indicates order creation is not successful
    if (paymentMethodInfo?.isHardwareTerminal && doRefund) {
      const authorizationDetails = get(
        paymentMethodInfo,
        "paymentMethodDetails.authorizationDetails",
        []
      );

      const merchantReferenceId =
        authorizationDetails.find((obj) => obj.name === "merchantReferenceId")
          ?.value || "";

      const transactionId =
        authorizationDetails.find((obj) => obj.name === "transactionDetailsId")
          ?.value || "";

      initiateRefundThroughTerminal({
        sendingMember: createEventReqObj.sendingMember,
        merchantReferenceId,
        transactionId,
        resolve: ({ message }) => {
          setFormSubmitting(false);
          formikBag.setSubmitting(false);

          ToasterHandler("uh oh", Localise(messages, message));
        },
      });
    } else {
      showToaster(error, formikBag, true);
    }
  };

  const CreateOrders = async (values, formikBag) => {
    if (get(values, "showError", false)) {
      formikBag.setSubmitting(false);
      return;
    }

    setIsGcAdded(redeemedGiftCardsData);

    const hasCreditCardPayment = get(
      values,
      "paymentDetails.paymentMethod",
      []
    )?.some((eachPayment) => {
      const { paymentMethodType } = eachPayment;
      return ["CREDIT_CARD", "SAVED_CARD"].includes(paymentMethodType);
    });

    !hasCreditCardPayment && setFormSubmitting(true);

    const getUpdatedPaymentDetails = async () => {
      const updatedValues = cloneDeep(values);
      const amountChargedToCustomer = values.orderAmounts.find(
        (orderAmount) => orderAmount.name === "amountChargedToCustomer"
      )?.value;

      let gcRedeemedAmt = 0;
      if (redeemedGiftCardsData?.length > 0) {
        for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
          gcRedeemedAmt += Number(redeemedGiftCardsData[i]?.gcAmount);
        }
      }
      const grandTotal = Number(amountChargedToCustomer) - gcRedeemedAmt;

      const paymentMethods = get(
        updatedValues,
        "paymentDetails.paymentMethod",
        []
      );

      paymentMethods.map((paymentMethod, index) => {
        const { paymentMethodDetails, paymentMethodType } = getPaymentInfo(
          updatedValues,
          paymentMethod,
          index
        );
        const { paymentProcessed = false } = paymentMethod;

        const isSavePaymentSelected = get(
          updatedValues,
          `paymentDetails.paymentMethod.${index}.savePayment`,
          false
        );
        if (paymentMethodType === "CREDIT_CARD" && !isSavePaymentSelected) {
          const billingZip = get(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.billingInformation.zip`,
            ""
          );
          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.billingInformation`,
            {
              ...basicPaymentInfo(true).paymentMethod[0].billingInformation,
              zip: billingZip,
            }
          );
        }

        if (["SAVED_CARD", "PAYMENT_TERMINAL"].includes(paymentMethodType)) {
          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.paymentMethodType`,
            "CREDIT_CARD"
          );
        }

        set(
          updatedValues,
          `paymentDetails.paymentMethod.${index}.paymentMethodDetails`,
          paymentMethodType === "INVOICE"
            ? {
                amount: parseFloat(grandTotal).toFixed(2),
                notes: "",
              }
            : paymentMethodDetails
        );

        if (paymentMethodType === "PAYMENT_TERMINAL") {
          set(
            updatedValues,
            `paymentDetails.paymentMethod.${index}.isHardwareTerminal`,
            true
          );

          sendingMemberRef.current = updatedValues.memberCode;
          if (!paymentProcessed) {
            delete updatedValues.paymentDetails.paymentMethod[index]
              .paymentMethodDetails;
          }
        }
      });

      // Setting paymentMethodArray if giftcard applied
      if (redeemedGiftCardsData?.length > 0) {
        const previousValues = get(
          updatedValues,
          "paymentDetails.paymentMethod.0",
          {}
        );

        const isGrandTotalZero = get(
          updatedValues,
          `paymentDetails.paymentMethod.0.isGrandTotalZero`,
          false
        );
        let paymentMethodArray = isGrandTotalZero
          ? []
          : get(updatedValues, "paymentDetails.paymentMethod", []);
        for (let i = 0; i < redeemedGiftCardsData?.length; i++) {
          paymentMethodArray.push({
            ...previousValues,
            paymentMethodDetails: {
              gcAmount: redeemedGiftCardsData[i]?.gcAmount,
              gcNumber: redeemedGiftCardsData[i]?.gcNumber,
            },
            paymentMethodType: "GIFT_CARD",
          });
        }
        set(updatedValues, "paymentDetails.paymentMethod", paymentMethodArray);
      }

      return updatedValues.paymentDetails;
    };

    const updatedPaymentDetails = await getUpdatedPaymentDetails();

    const payload = {
      customerInfo: values.customerInfo,
      orderChannel: "Web",
      orderType: "Local",
      orderOrigin: "MHQ",
      orderItems: values.orderItems,
      paymentDetails: updatedPaymentDetails,
      orderAmounts: values.orderAmounts,
      technology: "CLD",
      sendingMember: values.memberCode,
    };

    ValidateAndCreateOrder({
      values: { ...values, sendingMember: values.memberCode },
      createOrderReqObj: payload,
      redeemedGiftCardsData,
      setCurrentPaymentMethod,
      formikBag,
      setAuthorizeGcPaymentMethod,
      showError: showToaster,
      createOrderSuccessCallback: (resp) => {
        // showToaster("Your order(s) submitted successfully", formikBag, false);
        const orderIds = resp.orderItems.map((each) => each.orderItemId);
        setTimeout(() => {
          formikBag.setSubmitting(false);
          setFormSubmitting(false);
          setEventOrderIds(orderIds);
          dispatch(setRedeemedGcData([]));
        }, 10000);
        const { orderItems, orderAmounts, ...other } = values;
        handleSave({
          ...other,
          isPaymentRequired: true,
          customerInfo: {
            ...values?.customerInfo,
            notes: values?.customerInfo?.customerNotes,
            storeOrigin: values?.customerInfo.storeOrigin || values?.memberCode,
            orderIds,
          },
          addressVerificationInfo,
        });
      },
      createOrderErrorCallback: ({ error, formikBag, doRefund = false }) => {
        createEventErrorCallback({
          error,
          formikBag,
          createEventReqObj: payload,
          doRefund,
        });
      },
      setFormSubmitting,
      setIsGcAuthorizationSuccess,
      isEditOrder: false,
      setPaymentStatusDetails: (values) => {
        dispatch(setPaymentDetails({ status: "pending", values }));
      },
      dispatch,
    });
  };

  const createOrUpdateEvent = (id, values, formikBag) => {
    const { orderItems, orderAmounts, ...other } = values;
    const { paymentMethodType: firstPaymentMethodType } = get(
      values.paymentDetails,
      "paymentMethod.0"
    );
    values.showPayment &&
    (firstPaymentMethodType || redeemedGiftCardsData?.length > 0)
      ? CreateOrders(values, formikBag)
      : handleSave({
          ...other,
          customerInfo: {
            ...values?.customerInfo,
            notes: values?.customerInfo?.customerNotes,
            customerId: id,
            storeOrigin: values?.customerInfo.storeOrigin || values?.memberCode,
          },
          addressVerificationInfo,
        })
          .then((response) => {
            ToasterHandler(
              "nice",
              Localise(
                messages,
                `Your event ${
                  values.eventId ? `updated` : `created`
                } successfully`
              )
            );
            dispatch(setSearchText(values.name));
            navigation.dispatch(
              CommonActions.navigate({
                name: "events",
              })
            );
          })
          .catch((error) => {
            const message = isObject(error) ? error.error : error;
            console.log("error: " + message);
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                `Failed to ${values.eventId ? `update` : `create`} event. ${
                  message || ""
                }`
              )
            );
          })
          .finally(() => {
            formikBag.setSubmitting(false);
          });
  };

  const handleSubmit = (values, formikBag) => {
    const {
      firstName = "",
      lastName = "",
      businessName = "",
      customerType,
      email,
      customerId,
      phone = "",
      customerNotes,
    } = values?.customerInfo;

    let isModified = false;
    if (customerId) {
      const {
        firstName: actualFirstName,
        lastName: actualLastName,
        businessName: actualBusinessName,
        email: actualEmail,
        phone: actualPhone,
        phones: actualPhones,
      } = eventData?.customer?.details || values.actualCustomerInfo;
      const actualCustomerNotes =
        eventData?.customer?.details?.notes ||
        values.actualCustomerInfo?.customerNotes ||
        "";

      const initialInfo = {
        firstName: actualFirstName || "",
        lastName: actualLastName || "",
        businessName: actualBusinessName || "",
        email: actualEmail || "",
        phone: (actualPhone || actualPhones[0] || "").split("-").join(""),
        notes: actualCustomerNotes,
      };
      const updatedInfo = {
        firstName,
        lastName,
        businessName,
        email,
        phone: phone.split("-").join(""),
        notes: customerNotes,
      };

      isModified = !isEqual(initialInfo, updatedInfo);
    }

    const req = {
      ...values.actualCustomerInfo,
      firstName,
      lastName,
      businessName,
      customerType: customerType === "Business" ? "true" : "",
      phone,
      email,
      customerId,
      memberCode: values?.memberCode,
      storeOrigin: values?.customerInfo.storeOrigin || values?.memberCode,
      customerNotes,
    };
    if (!customerId || isModified) {
      dispatch(
        saveCustomerInfo({
          params: { ...req, isCreate: true },
          resolve: (id) => {
            createOrUpdateEvent(id, values, formikBag);
          },
          reject: (message = "") => {
            formikBag.setSubmitting(false);
            ToasterHandler(
              "uh oh",
              Localise(messages, `Failed to create customer. ${message}`)
            );
          },
        })
      );
    } else {
      createOrUpdateEvent(customerId, values, formikBag);
    }
  };

  useEffect(() => {
    return () => {
      setParams({
        action: undefined,
        id: undefined,
        smc: undefined,
        meatballAction: undefined,
      });
    };
  }, []);

  useEffect(() => {
    if (orderPaymentStatus === "pending" && Platform.OS === "web") {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires a string to be returned
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [orderPaymentStatus]);

  useEffect(() => {
    let amount = 0,
      refund = 0,
      unpaid = 0,
      completedOrders = [];
    ordersInfo?.forEach((order) => {
      const { orderAmounts, paymentDetails, orderItems } = order;
      const { status, orderItemId } = orderItems[0];
      const { paymentMethodType, paymentStatus } = get(
        paymentDetails,
        "paymentMethod.0"
      );
      if (paymentMethodType === "INVOICE") {
        const { paymentsReceived, unpaidAmount } =
          getPaidAndUnpaidAmounts(paymentDetails);
        unpaid += unpaidAmount;
        amount += paymentsReceived;
      } else if (paymentMethodType === "PAY_LATER") {
        if (
          paymentStatus?.toLowerCase() === "pending" &&
          status !== "CANCELLED"
        ) {
          unpaid += parseFloat(
            orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
              ?.value || 0
          );
        }
      } else {
        amount += parseFloat(
          orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
            ?.value || 0
        );
      }
      refund += getRefundAmount(paymentDetails);
      if (!isActiveOrder(status) || status === "DELIVERED")
        completedOrders.push(orderItemId);
    });
    setEventOrdersStatusInfo({
      amountPaid: parseFloat(amount).toFixed(2),
      refunded: parseFloat(refund).toFixed(2),
      unpaidBalance: parseFloat(unpaid).toFixed(2),
      completedOrders,
    });
  }, [ordersInfo?.length]);

  useEffect(() => {
    navigation.setOptions({
      title: action === "edit" ? "Edit Event" : "Create Event",
    });
    id && setCurrentTabIndex(3);
  }, [action, navigation, id]);

  const isInactiveEvent =
    id && ["COMPLETED", "CANCELLED"].includes(eventData?.status);

  const waitMessage = terminalTxnCancellingRef.current
    ? "Canceling transaction."
    : eventData?.eventId
    ? "We are saving your edits. Just a moment."
    : "Creating your event(s). Just a moment.";

  return (
    <View style={tw("flex")}>
      <ScreenHeader
        title={eventData?.eventId ? "Edit Event" : "Create Event"}
        settingsRoute={{
          page: "shop-settings",
          screen: "order-settings",
        }}
      />
      <View style={tw("flex flex-row")}>
        {id ? (
          <Processing
            authorizeGcPaymentMethod={authorizeGcPaymentMethod}
            currentPaymentMethod={currentPaymentMethod}
            setCurrentPaymentMethod={setCurrentPaymentMethod}
            isFormSubmitting={isFormSubmitting}
            setFormSubmitting={setFormSubmitting}
            waitMessage={waitMessage}
            formRef={createEditEventFormRef}
            sendingMemberRef={sendingMemberRef}
            terminalTxnCancellingRef={terminalTxnCancellingRef}
            isGcAdded={isGcAdded}
            isGcAuthorizationSuccess={isGcAuthorizationSuccess}
          />
        ) : null}
        <Form
          innerRef={createEditEventFormRef}
          initialValues={
            eventData || {
              ...basicEventInfo,
              memberCode: memberCodes.length > 1 ? "" : memberCodes[0],
            }
          }
          onSubmit={(values, formikBag) => {
            setValidationOnChange(false);
            if (nextTabIndex < 4) {
              setCurrentTabIndex(nextTabIndex);
              formikBag.setSubmitting(false);
              if (
                !completedTabIndexs.includes(currentTabIndex) &&
                currentTabIndex < 3
              )
                setCompletedTabIndexs(
                  [...completedTabIndexs, currentTabIndex].sort()
                );
            } else handleSubmit(values, formikBag);
          }}
          validationSchema={
            !isInactiveEvent
              ? createEventValidationSchema(
                  messages,
                  Localise,
                  currentTabIndex,
                  customerDetails,
                  redeemedGiftCardsData
                )
              : undefined
          }
          validateOnChange={validationOnChange}
          validateOnBlur={validationOnChange}
          enableReinitialize={false}
          render={(props) => {
            const {
              values,
              setValues,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              handleSubmit,
              submitCount,
              resetForm,
              dirty,
              initialValues,
              isSubmitting,
              errors,
            } = props;

            const maxScrollViewHeight = getMaxHeight(
              proxyUser,
              suspendedMembers
            );

            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (submitCount > 0) {
                setValidationOnChange(true);
              }
            }, [submitCount]);

            // Using existing settings of tabbar to show Icons
            const settings = [0, 1, 2].map((x) => {
              return completedTabIndexs.includes(x)
                ? {
                    id: x,
                    values: { smsToggle: true },
                  }
                : { id: x, values: undefined };
            });

            const disableTabs =
              isInactiveEvent ||
              (["APPROVED"].includes(values.status) && values.orderIds?.length);

            return (
              <React.Fragment>
                <View>
                  <TabBar
                    tabNames={EventTabs}
                    currentTab={currentTabIndex}
                    subTabNames={[{ key: "enabled", title: "" }]}
                    subTabImages={{
                      enabled: "customised-notification",
                      disabled: "",
                    }}
                    notificationTabs={true}
                    notificationTabsWidth={{ width: 160 }}
                    notificationSettings={settings}
                    setCurrentTab={(tabIndex) => {
                      setNextTabIndex(tabIndex);
                      if (
                        completedTabIndexs.includes(currentTabIndex) ||
                        currentTabIndex < tabIndex
                      )
                        handleSubmit();
                      else setCurrentTabIndex(tabIndex);
                    }}
                    disableTabs={disableTabs}
                    scrollTabBar={true}
                    showScrollBar={false}
                    showsScrollIndicators={true}
                    showBorder={false}
                    containerStyle={{ paddingVertical: 10 }}
                    isColumnView={true}
                    customBorder={customBorder}
                    activeTabStyle={{ size: "font-bold", margin: 16 }}
                    inactiveTabStyle={{ color: backgroundColors.primary }}
                    tabStyle={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      minHeight: 35,
                      marginVertical: 2,
                    }}
                  />
                  {values?.orderIds?.length > 0 && (
                    <Button
                      titleStyle={theme.Button.secondaryTitleStyle}
                      buttonStyle={{
                        ...theme.Button.secondaryButtonStyle,
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        marginRight: 10,
                      }}
                      containerStyle={{
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingLeft: 6,
                      }}
                      onPress={() => {
                        navigation.dispatch(
                          CommonActions.navigate({
                            name: "orders",
                            params: {
                              orderIds: values.orderIds.join(),
                            },
                          })
                        );
                      }}
                      title={Localise(messages, "View Event Orders")}
                      testID={"EventOrders"}
                      accessibilityLabel={"EventOrders"}
                    />
                  )}
                </View>

                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{ maxHeight: maxScrollViewHeight, width: "45%" }}
                  >
                    <ScrollView scrollEnabled={true}>
                      <View style={tw("flex items-center w-full p-5")}>
                        <EventBasicInfo
                          isDesktop={isDesktop}
                          values={values}
                          initialValues={initialValues}
                          currentTabIndex={currentTabIndex}
                          memberCodes={memberCodes}
                          setCurrentTabIndex={setCurrentTabIndex}
                          isProposalPage={currentTabIndex === 3}
                          setFieldValue={setFieldValue}
                          ordersInfo={ordersInfo}
                          meatballAction={meatballAction}
                          eventOrdersStatusInfo={eventOrdersStatusInfo}
                          setSideCarInfo={setSideCarInfo}
                        />
                        {Components.map((Component, index) => (
                          <View
                            key={index}
                            style={{
                              display:
                                index === currentTabIndex ? "flex" : "none",
                              width: "100%",
                            }}
                          >
                            <Component
                              isDesktop={isDesktop}
                              values={values}
                              errors={errors}
                              setValues={setValues}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              setSideCarInfo={setSideCarInfo}
                              setFieldTouched={setFieldTouched}
                              resetForm={resetForm}
                              currentTabIndex={currentTabIndex}
                              setCurrentTabIndex={setCurrentTabIndex}
                              dirty={dirty}
                              memberCodes={memberCodes}
                              handleSubmit={() => {
                                setNextTabIndex(currentTabIndex + 1);
                                handleSubmit();
                              }}
                              loading={isSubmitting}
                              initialValues={initialValues}
                              completedTabIndexs={completedTabIndexs}
                              setValidationOnChange={setValidationOnChange}
                              eventOrdersStatusInfo={eventOrdersStatusInfo}
                              nextTabIndex={nextTabIndex}
                            />
                          </View>
                        ))}
                      </View>
                      <View fsClass="fs-unmask"></View>
                    </ScrollView>
                    {currentTabIndex !== 3 && (
                      <TouchableOpacity
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          padding: 20,
                          paddingBottom: 5,
                        }}
                        onPress={() => {
                          setNextTabIndex(currentTabIndex + 1);
                          handleSubmit();
                        }}
                        testID="forward-order-item-arrow"
                        accessibilityLabel="forward-order-item-arrow"
                      >
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                          }}
                          resizeMode="contain"
                          source={IMAGES[`back_right_nav_arrow`]}
                        />
                      </TouchableOpacity>
                    )}
                  </View>

                  {!!sideCarInfo?.key && (
                    <View
                      style={[
                        {
                          backgroundColor: backgroundColors.sideCar,
                          width: !isDesktop
                            ? "100%"
                            : ["product_selection", "send_proposal"].includes(
                                sideCarInfo?.key
                              )
                            ? "35%"
                            : "25%",
                        },
                      ]}
                    >
                      <ScrollView
                        contentContainerStyle={{
                          paddingHorizontal: 10,
                        }}
                        style={[
                          {
                            maxHeight: maxScrollViewHeight,
                          },
                        ]}
                      >
                        {sideCarInfo?.key === "product_selection" ? (
                          <SideCar
                            setShowSideCar={() => {
                              setSideCarInfo({});
                            }}
                            sideCarKey={sideCarInfo?.key}
                            sendingMemberCode={values.memberCode}
                            setSelectedRecord={(info) => {
                              const { index } = sideCarInfo;
                              const products = get(
                                values,
                                `locations.${index}.products`
                              );
                              const {
                                pid,
                                name,
                                price,
                                img = "",
                                refNumberId,
                                siteId = "",
                                quantity = "1",
                                type = "Product",
                              } = info.product;

                              if (products.length === MAX_ITEMS_PER_ORDER) {
                                setFieldValue(
                                  `locations.${index}.qtyWarning`,
                                  `You've reached the maximum items allowed for this order.`
                                );
                              } else {
                                const resp = getProductObj(
                                  pid,
                                  name,
                                  refNumberId,
                                  img,
                                  price,
                                  quantity,
                                  type,
                                  siteId
                                );
                                setFieldValue(`locations.${index}.products`, [
                                  ...products,
                                  {
                                    ...resp,
                                    amount: resp.actualPrice,
                                    productId: resp.productFirstChoiceCode,
                                    productName:
                                      resp.productFirstChoiceDescription,
                                    productLabel: "",
                                    placement: "Tables",
                                  },
                                ]);
                                setFieldTouched(
                                  `locations.${index}.products.${products.length}.productLabel`
                                );
                              }
                              setSideCarInfo({});
                            }}
                            isSmallScreen={!isDesktop}
                            memberCodes={memberCodes}
                            addText="Add to Event"
                            showGiftCards={false}
                          />
                        ) : (
                          <SendProposal
                            initialEventInfo={initialValues}
                            eventInfo={values}
                            onClose={() => setSideCarInfo({})}
                          />
                        )}
                      </ScrollView>
                    </View>
                  )}
                </View>
                <ConfirmModal
                  modalVisible={eventOrderIds.length}
                  handlePrimary={() => {
                    navigation.dispatch(
                      CommonActions.navigate({
                        name: "orders",
                        params: {
                          orderIds: eventOrderIds.join(),
                        },
                      })
                    );
                    setEventOrderIds([]);
                  }}
                  contentStyle={{ maxWidth: 340 }}
                  handleSecondary={() => {
                    setEventOrderIds([]);
                    dispatch(setSearchText(values.name));
                    navigation.dispatch(
                      CommonActions.navigate({
                        name: "events",
                      })
                    );
                  }}
                  data={{
                    modal: {
                      primary: "View Event Orders",
                      secondary: "Close",
                      content: Localise(
                        messages,
                        `Orders for ${values.name} have been created.`
                      ),
                    },
                  }}
                />
              </React.Fragment>
            );
          }}
        />
      </View>
    </View>
  );
};

export default CreateEvent;
