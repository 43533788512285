import React, { useContext, useEffect, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { FormField, FormFieldPicker } from "components/elements/forms";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toNumber from "lodash/toNumber";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { selectPromoCodeslist } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { COMMON, CREATE_ORDER } from "library/constants";
import { fonts } from "styles/theme";
import { getOrderSubTotal } from "library/utils/createOrder";
import { formatPrice } from "library/utils/formatter";
import { getPromoCodesList, parsePromoValue } from "./helper";
import { CrossIcon, InfoLabel } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { fetchPromoCodes } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { promoParams } from "components/views/drawer/shop-settings/marketing/promoCodes/helper";

const PromoCode = ({ index, sendingMemberCode }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { isEditOrder = false } = values;

  const [loadingPromoCodes, setLoadingPromoCodes] = useStateIfMounted(true);
  const dispatch = useDispatch();
  const promoCodesList = useSelector(selectPromoCodeslist(sendingMemberCode));
  const promoCodeSwitch = Environment.get("SHOW_PROMO_CODE_FEATURE", false);

  const orderItemsPath = `orderItems.${index}`;
  const pricePath = `orderItems.${index}.price`;

  const order = get(values, orderItemsPath, {});

  const {
    lineItems = [],
    promoCode = "",
    selectedPromoCodeInfo = {},
    price = [],
  } = order;

  const promoCodeOptions = useMemo(() => {
    const activePromoCodes = getPromoCodesList(promoCodesList);
    const promoCode = get(initialValues, `${orderItemsPath}.promoCode`, "");
    const isPromoInList = promoCodesList.some(
      (item) => item.promoCode === promoCode
    );

    // If the promo code is not in the list and exists, append it to the active promo codes.
    return lineItems.length > 0
      ? promoCode &&
        !isPromoInList &&
        initialValues?.sendingMember === sendingMemberCode
        ? [...activePromoCodes, { label: promoCode, value: promoCode }]
        : activePromoCodes
      : [];
  }, [promoCodesList, lineItems.length, initialValues, sendingMemberCode]);

  const isPromoPercentage =
    selectedPromoCodeInfo.discountType === COMMON.PERCENTAGE;
  const isDiscountsApplied = price.some((item) => !!item.discountType);

  const infoText = promoCode
    ? CREATE_ORDER.PROMO_ADDED
    : isDiscountsApplied
    ? CREATE_ORDER.DISCOUNTS_ADDED
    : "";

  const removePromoDiscount = () => {
    const { discountPercentage } = values?.customerInfo || {};
    setFieldValue(
      `${orderItemsPath}.price`,
      price.map((item) => ({
        ...item,
        discountType: toNumber(discountPercentage) ? COMMON.PERCENTAGE : "",
        discount: formatPrice(discountPercentage),
      }))
    );
  };

  useEffect(() => {
    setLoadingPromoCodes(true);
    dispatch(
      fetchPromoCodes({
        params: promoParams({
          memberCode: sendingMemberCode,
          all: false,
          sortBy: "promoCode",
        }),
        resolve: () => {
          setLoadingPromoCodes(false);
        },
        reject: () => {
          setLoadingPromoCodes(false);
        },
      })
    );
  }, [sendingMemberCode]);

  useEffect(() => {
    if (!promoCodeSwitch) return;
    if (isEmpty(lineItems)) setFieldValue(`${orderItemsPath}.promoCode`, "");
  }, [lineItems?.length]);

  useEffect(() => {
    if (!promoCodeSwitch || loadingPromoCodes) return;

    if (!promoCode) {
      setFieldValue(`${orderItemsPath}.selectedPromoCodeInfo`, {});
      return;
    }
    let selectedPromoCode =
      promoCodesList?.find((item) => item.promoCode === promoCode) || {};

    /*
      1. When an order or draft is edited, if the selected promo code is unavailable 
      (expired, deleted, or deactivated), the promo code is retrieved from the order details.

      2. If an order is created with a promo code that is later deleted, expired, or deactivated 
      and then recreated, the older promo code should be displayed when the order is modified. 
      For draft orders, the newly created promo code can be shown.
    */
    if (isEmpty(selectedPromoCode) || isEditOrder) {
      const { promoValue = "", promoCode: initialPromoCode } = get(
        initialValues,
        orderItemsPath,
        []
      );
      const parsedValue = parsePromoValue(promoValue);

      if (parsedValue) {
        const {
          discountType: prevDiscountType,
          discountValue: prevDiscountValue,
        } = parsedValue;

        // Update selectedPromoCode only if it's empty or discount values have changed
        if (
          isEmpty(selectedPromoCode) ||
          (isEditOrder &&
            initialPromoCode === promoCode &&
            (prevDiscountType !== selectedPromoCode?.discountType ||
              prevDiscountValue !== selectedPromoCode?.discountValue))
        ) {
          selectedPromoCode = {
            promoCode,
            ...parsedValue,
            isUnavailable: true,
          };
        }
      }
    }

    const promoValue = parseFloat(
      selectedPromoCode?.discountValue || 0
    ).toFixed(2);

    setFieldValue(`${orderItemsPath}.selectedPromoCodeInfo`, {
      ...selectedPromoCode,
      discountValue: promoValue,
    });
  }, [promoCode, loadingPromoCodes]);

  useEffect(() => {
    if (!(promoCodeSwitch && promoCode && !isEmpty(selectedPromoCodeInfo)))
      return;

    const promoValue = selectedPromoCodeInfo?.discountValue || 0;
    const promoDiscountType = selectedPromoCodeInfo?.discountType || "";

    const productTotal = getOrderSubTotal(order, false);
    let totalDistributedDiscount = 0;

    // Filter out products without a valid price before processing discounts
    const validProducts = price.filter((item) => item?.value > 0);

    price.map((item, idx) => {
      let discount = 0;

      // Only apply discount logic if the item has a valid price
      if (item?.value > 0) {
        const validIdx = validProducts.findIndex(
          (validItem) => validItem === item
        );

        const isRemoved = lineItems[validIdx]?.isRemoved || false;

        if (promoDiscountType === COMMON.PERCENTAGE) {
          discount = promoValue;
        } else if (promoDiscountType === COMMON.DOLLAR) {
          const { quantity = 1 } = lineItems[idx] || {};
          if (validIdx < validProducts.length - 1) {
            // Proportional discount for valid items
            discount = !isRemoved
              ? parseFloat(
                  ((item.value / productTotal) * promoValue * quantity).toFixed(
                    2
                  )
                )
              : 0;
            totalDistributedDiscount += discount; // Track total distributed
          } else {
            // Apply the remaining discount to the last product to ensure adding remaining balance.
            discount = !isRemoved
              ? parseFloat(promoValue - totalDistributedDiscount).toFixed(2)
              : 0;
          }
          // Ensure discount does not exceed the product's total price
          discount = Math.min(Math.max(discount, 0), quantity * item.value);
        }
      }
      setFieldValue(
        `${pricePath}.${idx}.discountType`,
        item.value > 0 ? promoDiscountType : null
      );
      setFieldValue(
        `${pricePath}.${idx}.discount`,
        item.value > 0 ? formatPrice(discount) : "0.00"
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPromoCodeInfo?.discountValue, JSON.stringify(lineItems)]);

  const disablePromo = promoCodeOptions.length === 0 && lineItems.length > 0;

  return (
    <View style={tw(`flex flex-row items-center py-2`)}>
      {!promoCodeSwitch ? (
        <FormField
          name="promoCode"
          placeholder={Localise(messages, "Promo Code")}
          label={Localise(messages, "Promo Code")}
          labelStyle={tw(`font-normal`)}
          path={orderItemsPath}
          containerStyle={{
            width: isDesktop ? 190 : 110,
            paddingHorizontal: 0,
          }}
          errorStyle={tw(`pb-0`)}
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          testID="promo_code"
          accessibilityLabel="promo_code"
        />
      ) : (
        <View style={tw(`flex w-full ${isDesktop ? "flex-row" : "flex-col"}`)}>
          <View
            style={[
              tw(`flex flex-row`),
              {
                width: isDesktop ? (promoCode ? 315 : 190) : "100%",
              },
            ]}
          >
            <FormFieldPicker
              name="promoCode"
              label={Localise(messages, "Promo Code")}
              labelStyle={tw(`font-normal`)}
              placeholder={{
                label: Localise(
                  messages,
                  disablePromo ? "No Promo Codes Available" : "Select Code"
                ),
              }}
              path={orderItemsPath}
              containerStyle={{
                width: isDesktop ? 190 : !promoCode ? "100%" : "62%",
                paddingHorizontal: 0,
                paddingBottom: 0,
              }}
              errorStyle={tw(`pb-0`)}
              data={promoCodeOptions}
              onChange={(val) => {
                if (!val) removePromoDiscount();
              }}
              disabledFieldTouch={true}
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password"
              testID="promo_code"
              accessibilityLabel="promo_code"
              disabled={disablePromo}
              customDisabledStyle
            />
            {!!promoCode && (
              <>
                <FormField
                  name="discountValue"
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  containerStyle={[
                    tw("mt-5 px-0 ml-2"),
                    {
                      width: isDesktop ? 90 : "25%",
                    },
                  ]}
                  errorStyle={tw(`pb-0`)}
                  renderErrorMessage={false}
                  keyboardType="numeric"
                  iconName={isPromoPercentage ? "percent" : "currency-usd"}
                  iconPosition={!isPromoPercentage}
                  iconSize={isPromoPercentage ? 12 : 14}
                  iconType={
                    isPromoPercentage ? "font-awesome" : "material-community"
                  }
                  leftIconContainerStyle={tw(`pr-0`)}
                  placeholder="0.00"
                  path={`${orderItemsPath}.selectedPromoCodeInfo`}
                  editable={false}
                  grayedOutOnDisable={true}
                  testID="promo_discount_value"
                  accessibilityLabel="promo_discount_value"
                />
                <CrossIcon
                  containerStyle={{ marginTop: 28, marginLeft: 4 }}
                  onPress={() => {
                    setFieldValue(`${orderItemsPath}.promoCode`, "");
                    removePromoDiscount();
                  }}
                  testID="remove_promo_code"
                  accessibilityLabel="remove_promo_code"
                />
              </>
            )}
            {disablePromo && (
              <InfoLabel
                toolTipProps={{
                  showToolTip: true,
                  toolTipText: CREATE_ORDER.NO_PROMO_TOOL_TIP,
                }}
                iconProps={{
                  iconStyle: { marginLeft: 5 },
                  iconPosition: "right",
                }}
                containerStyle={{ alignItems: "flex-end", marginBottom: 10 }}
              />
            )}
          </View>
          {!!infoText && !disablePromo && (
            <Text
              style={[
                fonts.style2,
                tw(`flex ${isDesktop ? "ml-2" : ""}`),
                { marginTop: isDesktop ? 26 : 4 },
              ]}
            >
              {Localise(messages, infoText)}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export default PromoCode;
