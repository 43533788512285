import React, { useEffect, useContext } from "react";
import { Text, Button } from "react-native-elements";
import {
  ActivityIndicator,
  View,
  TouchableOpacity,
  Platform,
  Image,
} from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  PrintIframeAction,
  PrintIframeRenderer,
  CustomModal,
  ToasterHandler,
  Tooltip,
} from "components/elements";
import {
  FormFieldPicker,
  Form,
  FormField,
  SubmitButton,
} from "components/elements/forms";

import { useFormikContext } from "formik";
import tw from "tailwind-rn";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import capitalize from "lodash/capitalize";

import moment from "moment";

import useStateIfMounted from "library/utils/useStateIfMounted";
import IMAGES from "static/assets/images";
import { colors, backgroundColors, theme, fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

import UserProfileContext from "library/contexts/userProfile";

import {
  setOrderModifying,
  updateAutoPrintOrders,
  setActiveTab,
  setDconStatus,
  setOnDemandInvoiceFields,
  sendEmailInvoices,
} from "library/sagas/ongoing/current-orders/slice";
import {
  setUserAction,
  setRecalcTaxAndFees,
} from "library/sagas/ongoing/global-data/slice";
import {
  selectOrderLocked,
  selectRecordData,
  selectOnDemandInvoice,
} from "library/sagas/ongoing/current-orders/selector";

import {
  printTrigger,
  getExtendedMenuOptions,
  getZoneDetails,
  hasRemotePrintEnabled,
  getRemotePrinterName,
  showPrintToaster,
} from "./helper";
import { startNavigation, triggerNativeActions } from "../../helper";
import { getSingleOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { getSingleInvoicePrintHTML } from "components/views/drawer/order-details/invoice-print-helper";
import UserProfileStorage from "library/storage/userProfile";
import CancelDSModal from "./cancelDSModal";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";
import { orderDsRequestRerouting } from "library/utils/orderListing";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import {
  generateQRCode,
  setOrderActions,
  setCanEditOrder,
  setShowOrderUnpaidModal,
} from "library/sagas/ongoing/order-details/slice";
import { getOnDemandInvoiceFormValidationSchema } from "./yup";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import { AppSettingsContext } from "library/contexts/appSettings";
import { PrintMenusContainer } from "components/elements/print-menu-container";

const TakeAction = ({
  orderDetails,
  orderActions,
  actionTriggered,
  triggerAction,
  triggerRequest,
  menuAction = "",
  isSmallScreen,
  isMobile,
  sourceMemberCode,
  isCancelPendingApproval,
  cancelApprovalInfo,
  isActionProcessing,
  isPickUpOrder,
  doNotFill,
  hasViewChat,
  driverActions,
  orderRefundStatus,
  setOrderRefundStatus,
  scrollViewRef,
  tabDetailScrollRef,
  selectedproducts,
  customerInfo,
  cardInfo,
  imagesData,
  paymentDetails,
  isCopySaleForDeadOrder,
  setSelectedAction,
  selectedAction,
  setShowReasonForm,
  isCancelDSModal,
  setShowCancelDSModal,
  setShowDconForm,
  internalStatus,
}) => {
  const dispatch = useDispatch();
  const isOrderLocked = useSelector(selectOrderLocked);
  const orderDetailResponse = useSelector(selectRecordData);
  const onDemandInvoiceResponse = useSelector(selectOnDemandInvoice);
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);
  const { resetForm, isValid, values, initialValues, setFieldValue } =
    useFormikContext();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;

  const {
    orderItemId = "",
    erosOrderNumber = "",
    status,
    isPrinted,
    hasFulfillmentError,
    hasSettlementError,
    hasPendingPayLaterPayment = false,
    recipientInfo,
    orderSource,
    deliveryInfo: {
      deliveryDate,
      deliveryMethod,
      deliveryType = "",
      cardMessage = "",
    },
    sendingMember = {},
    receivingMember,
    direction,
    isTrifoldDetailsPrinted,
    isInvoiceDetailsPrinted,
    isDesignerWorkSheetPrinted,
    isReceiptPrinted,
    hasDSFulfillmentError = false,
    giftCardType = "",
    messages: orderMessages = [],
    paymentItemStatus = "",
  } = orderDetails;

  const customerEmail = get(orderDetailResponse, "customerInfo.email", "");

  const {
    customerEmailFields = [customerEmail],
    showOnDemandInvoiceForm = false,
    validateOnDemandInvoiceFields = false,
  } = onDemandInvoiceResponse;

  const customerId = get(orderDetailResponse, "customerInfo.customerId", "");
  const nonOLCMessages = orderMessages.filter((x) => x.messageType !== "OLC");
  const unPrintedMessages = nonOLCMessages.filter((x) => !x.isPrinted);
  const newOrderItemId = orderMessages
    .find((ord) => ord?.comments?.startsWith("New order "))
    ?.comments?.split(": ")[1];

  const { memberCode: sendMemberCode } = sendingMember;
  const { memberCode: recMemberCode } = receivingMember;

  const isDSSubmitted = deliveryType === "Delivery Service";
  const dsRerouteInProgress = orderDsRequestRerouting(
    pick(orderDetails, ["latestDSRequestedStatus"])
  );

  const {
    memberCodes = [],
    userProfile: { firstName: operator } = {},
    userRole,
  } = useContext(UserProfileContext);

  const isSenderOrder =
    direction === "OUTBOUND" &&
    memberCodes.includes(sendMemberCode) &&
    sendMemberCode !== recMemberCode;

  const isLocalOrder = [
    "FLORIST_DELIVERED",
    "STORE_PICKUP",
    "WALK_IN",
    "PHONE_OUT",
  ].includes(deliveryMethod);

  const isWiredOrder = deliveryMethod === "FLORIST_PARTNER";

  const isActiveOrder = ![
    "CANCELLED",
    "REJECTED",
    "FORFEITED",
    "ERROR",
  ].includes(status);

  const [orderActionsData, setOrderActionsData] = useStateIfMounted([]);

  // Hide modify order action button in case order delivery date is older than 90 days
  const hideModifyOrderAction = moment(deliveryDate).isBefore(
    moment().subtract(90, "days")
  );

  // As API is not allowing the order modification on hasFulfillmentError & hasDSFulfillmentError orders then not allowing edit on those
  const EligibleToEditOrder =
    !giftCardType.includes("GIFT_CARD") &&
    !["PARTNER_STORE_PICKUP", "MOL_CUSTOMER_PICKUP"].includes(deliveryMethod) &&
    !hideModifyOrderAction &&
    !isOrderLocked.isLocked &&
    (direction === "INBOUND" ||
      (direction === "OUTBOUND" &&
        [
          "FLORIST_PARTNER",
          "PHONE_OUT",
          "MOL_FLORIST_DELIVERED",
          "FOL_FLORIST_DELIVERED",
        ].includes(deliveryMethod))) &&
    (isActiveOrder ||
      (!isActiveOrder && (hasDSFulfillmentError || hasFulfillmentError)));

  const { qr_code_global_enabled = "" } =
    UserProfileStorage.getShopPreferences(sourceMemberCode);

  const showQrCode = qr_code_global_enabled === "true";

  const showReceiptOption =
    memberCodes.includes(sendMemberCode) &&
    !hasSettlementError &&
    !hasPendingPayLaterPayment;

  const scrollToEndOfPage = () => {
    // Adding setTimeout here in order to scroll to bottom payment section o/w it is scrolling upto 90% only
    setTimeout(() => {
      scrollViewRef &&
        scrollViewRef.current.scrollToEnd({
          animated: true,
        });
      tabDetailScrollRef &&
        tabDetailScrollRef?.current?.scrollToEnd({ animated: true });
    }, 50);
  };

  const hasDesignCenter = hasDesignCenterFeature(permissions, sourceMemberCode);

  useEffect(() => {
    let orderActionsData = orderActions.map((each) => ({
      ...each,
      value: each.key,
    }));

    if (!hasDesignCenter) {
      orderActionsData = orderActionsData.filter(
        (item) => item.key !== "designed" && item.key !== "designed-pickup"
      );
    }

    // Adding edit order as order action based on EligibleToEditOrder
    if (EligibleToEditOrder) {
      dispatch(setCanEditOrder(true));
    }

    if (isMHQNonCoreMember(sourceMemberCode) && Platform.OS === "web") {
      orderActionsData.push({
        key: "email-invoice",
        label: "Email Invoice",
        value: "email-invoice",
      });
    }
    if (orderSource === "MOL") {
      orderActionsData = orderActionsData.filter(
        (item) => item.key !== "reject"
      );
    }

    // Adding edit order as order action based on EligibleToEditOrder
    if (EligibleToEditOrder) {
      orderActionsData.push({
        key: "edit-order",
        label: "Edit Order",
        value: "edit-order",
      });
    }

    setOrderActionsData(orderActionsData);
    dispatch(setOrderActions(orderActionsData));
  }, [orderActions, disableSave]);

  useEffect(() => {
    if (actionTriggered.action === "OrderJourneyCancelDeny") {
      setSelectedAction("cancel-deny");
    }
  }, [actionTriggered]);

  useEffect(() => {
    menuAction.length &&
      setSelectedAction(
        menuAction.substring(0, menuAction.length - orderItemId.length)
      );
  }, [menuAction]);

  useEffect(() => {
    // QR Data Payload for generating the QR code
    const qrData = [
      {
        oid: orderItemId || "", // orderItemId
        sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
        dm: deliveryMethod || "", // order deliveryMethod
      },
    ];
    if (
      [
        "accept-print",
        "print",
        "triCardPrint",
        "invoice",
        "designerWorksheetPrint",
        "receipt",
      ].includes(selectedAction)
    ) {
      printTrigger({
        selectedAction,
        orderDetails,
        memberCodes,
        customerInfo,
        paymentDetails,
        operatorName: operator,
        messages,
        Localise,
        dispatch,
        selectedproducts,
        userRole,
        cardInfo,
        imagesData,
        cardMessage,
        setSelectedAction,
        triggerAction,
        orderDetailResponse,
        ordersList: false,
        deliveryInfo: get(orderDetailResponse, `orderItems.0.deliveryInfo`, {}),
      });
    } else if (selectedAction === "orderMessages") {
      const {
        order_messages_incoming_print_settings:
          inboundPrintPreferencesArray = [],
        order_messages_outgoing_print_settings:
          outboundPrintPreferencesArray = [],
      } = UserProfileStorage.getShopPreferences(sourceMemberCode);

      let qrImageData = "";

      const inboundRemotePrint = hasRemotePrintEnabled(
        inboundPrintPreferencesArray
      );
      const outboundRemotePrint = hasRemotePrintEnabled(
        outboundPrintPreferencesArray
      );
      const inboundRemotePrinterName = inboundRemotePrint
        ? getRemotePrinterName(inboundPrintPreferencesArray)
        : "";
      const outboundRemotePrinterName = outboundRemotePrint
        ? getRemotePrinterName(outboundPrintPreferencesArray)
        : "";

      const updatePrintStatus = (
        remotePrint = false,
        direction = [],
        remotePrinterName = "",
        printPreferences
      ) => {
        if (!remotePrint) {
          if (isElectronApp && !isEmpty(printPreferences)) {
            showPrintToaster({
              remotePrint,
              printerName: printPreferences?.printer,
              artifactName: "Related Messages",
              Localise,
              messages,
            });
          }

          if (!unPrintedMessages.length) {
            setSelectedAction("");
            return;
          }
        }

        const messageList = remotePrint ? nonOLCMessages : unPrintedMessages;

        const autoPrintOrders = messageList.reduce((acc, message) => {
          const { id, direction: msgDirection } = message;
          if (direction.includes(msgDirection)) {
            acc.push({
              orderId:
                orderDetailResponse?.orderId || orderDetails?.orderItemId,
              orderItemId: orderDetails?.orderItemId,
              orderNumber: orderDetails?.erosOrderNumber,
              isPrinted: true,
              deliveryMethod,
              sendingMemberCode: sendMemberCode,
              fillingMemberCode: recMemberCode,
              isAutoPrint: false,
              relatedMessageId: id,
              direction: msgDirection,
              messageType: "RELATED_MESSAGE",
            });
          }
          return acc;
        }, []);

        !isEmpty(autoPrintOrders) &&
          dispatch(
            updateAutoPrintOrders({
              ...(remotePrint && { remotePrintRequest: true }),
              payload: [
                {
                  memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                  autoPrintOrders,
                },
              ],
              resolve: () => {
                remotePrint &&
                  showPrintToaster({
                    remotePrint,
                    printerName:
                      inboundRemotePrint && outboundRemotePrint
                        ? "Printer"
                        : remotePrinterName,
                    artifactName: "Related Messages",
                    Localise,
                    messages,
                  });
                triggerAction({ action: selectedAction });
              },
              reject: () => triggerAction({ action: selectedAction }),
            })
          );

        setSelectedAction("");
      };

      const orderMessagesPrint = (direction = "", printPreferences = {}) => {
        const printType = `order${capitalize(direction)}Messages`;

        PrintIframeAction(
          getSingleOrderPrintHTML({
            orderDetails,
            memberCodes,
            operatorName: operator,
            messages,
            Localise,
            selectedproducts,
            customerInfo,
            qrImageData,
            printType,
          }),
          { printType, preferences: printPreferences },
          () => {
            updatePrintStatus(
              false,
              direction ? [direction] : ["INBOUND", "OUTBOUND"],
              "",
              printPreferences
            );
          },
          printType
        );
      };

      const orderMessagesPrintHelper = () => {
        const inboundPreferences =
          (isElectronApp &&
            (inboundPrintPreferencesArray || []).find(
              (each) => each.macAddress === macAddress
            )) ||
          {};

        const outboundPreferences =
          (isElectronApp &&
            (outboundPrintPreferencesArray || []).find(
              (each) => each.macAddress === macAddress
            )) ||
          {};

        if (inboundRemotePrint && outboundRemotePrint) {
          updatePrintStatus(true, ["INBOUND", "OUTBOUND"]);
        } else if (inboundRemotePrint) {
          updatePrintStatus(true, ["INBOUND"], inboundRemotePrinterName);
          orderMessagesPrint("OUTBOUND", inboundPreferences);
        } else if (outboundRemotePrint) {
          orderMessagesPrint("INBOUND", outboundPreferences);
          updatePrintStatus(true, ["OUTBOUND"], outboundRemotePrinterName);
        } else {
          if (isEmpty(inboundPreferences) && isEmpty(outboundPreferences)) {
            orderMessagesPrint();
          } else {
            orderMessagesPrint("INBOUND", inboundPreferences);
            orderMessagesPrint("OUTBOUND", outboundPreferences);
          }
        }
      };

      !(inboundRemotePrint && outboundRemotePrint) && showQrCode
        ? dispatch(
            generateQRCode({
              params: { qrPayload: qrData },
              type: "SINGLE",
              resolve: (resp) => {
                qrImageData = resp[orderItemId] || "";
                orderMessagesPrintHelper();
              },
              reject: () => {
                orderMessagesPrintHelper();
              },
            })
          )
        : orderMessagesPrintHelper();
    } else if (
      ![
        "cancel",
        "reject",
        "cancel-deny",
        "adjustment",
        "assign-to-designer",
      ].includes(selectedAction)
    ) {
      triggerAction({ action: selectedAction });
      setSelectedAction("");
    } else if (["assign-to-designer"].includes(selectedAction)) {
      dispatch(setActiveTab("order-summary"));
      scrollToEndOfPage();
    }

    if (selectedAction === "reject" && (isDSSubmitted || dsRerouteInProgress)) {
      setShowCancelDSModal(true);
      setShowReasonForm(false);
    }
  }, [selectedAction]);

  // Compare initial values with formik values other than paymentInfo and customer firstPaymentInfo
  const basicValues = cloneDeep(values);
  const newDeliveryFee = get(basicValues, "paymentInfo.newDeliveryFee", "");
  const newRetailDeliveryFee = get(
    basicValues,
    "paymentInfo.newRetailDeliveryFee",
    ""
  );
  const newDiscount = get(basicValues, "paymentInfo.newDiscount", "");
  set(basicValues, "paymentInfo.applyTaxOnDF", false);
  delete basicValues.customerInfo?.firstPaymentInfo;
  set(basicValues, "paymentInfo", {
    ...initialValues.paymentInfo,
    newDeliveryFee,
    newRetailDeliveryFee,
    newDiscount,
  });
  set(basicValues, `orderItems.0.deliveryInfo.shopDayTimings`, {});
  const isFormChanged = !isEqual(initialValues, basicValues);
  const disableSave = !isFormChanged || !isValid;

  const onPrintSelection = (printOption) => {
    if (printOption === "OrderDetail") {
      setSelectedAction("print");
    } else if (printOption === "TriFoldCard") {
      setSelectedAction("triCardPrint");
    } else if (printOption === "Invoice") {
      setSelectedAction("invoice");
    } else if (printOption === "DesignerWorksheet") {
      setSelectedAction("designerWorksheetPrint");
    } else if (printOption === "OrderReceipt") {
      setSelectedAction("receipt");
    } else {
      setSelectedAction("orderMessages");
    }
  };

  const disablePrint =
    (isCancelPendingApproval ||
      (doNotFill && hasViewChat) ||
      isCopySaleForDeadOrder) &&
    !["cancelled", "forfeited", "rejected", "cancelled-pickup"].includes(
      internalStatus
    );

  const sendOnDemandInvoiceEmail = (values, formikBag) => {
    if (formikBag.isSubmitting) return;
    const qrData = [
      {
        oid: orderItemId || "", // orderItemId
        sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
        dm: deliveryMethod || "", // order deliveryMethod
      },
    ];
    let qrImageData = "";
    let htmlContent = "";

    const filteredEmails = values?.recipientEmail.filter(
      (email) => email.trim() !== ""
    );

    // invoice print trigger
    const invoicePrintTrigger = async () => {
      const zoneDetails = await getZoneDetails(orderDetails);
      htmlContent = getSingleInvoicePrintHTML({
        orderDetails,
        memberCodes,
        messages,
        Localise,
        customerInfo,
        paymentDetails,
        userRole,
        zoneDetails,
        qrImageData,
      });

      htmlContent?.length &&
        dispatch(
          sendEmailInvoices({
            payload: {
              requestPayload: {
                isAutoSendEmailInvoice: false, // False for manual send email invoice
                orderId: orderItemId,
                orderNumber: erosOrderNumber,
                recipients: filteredEmails,
                deliveryMethod: deliveryMethod,
                html: htmlContent,
                emailType: "INVOICE",
                ...(customerId && { customerId: customerId }),
              },
              shopCode: sourceMemberCode,
              recordId: orderItemId,
            },
            resolve: () => {
              ToasterHandler(
                "nice",
                Localise(messages, `Your Invoice sent successfully`)
              );
              formikBag.setSubmitting(false);
              dispatch(
                setOnDemandInvoiceFields({
                  customerEmailFields: [customerEmail],
                  showOnDemandInvoiceForm: false,
                })
              );

              setFieldValue("actionItem", "");
              setSelectedAction("email-invoice");
            },
            reject: (error) => {
              ToasterHandler("uh oh", Localise(messages, error));
              formikBag.setSubmitting(false);
            },
          })
        );
    };

    showQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderItemId] || "";
              invoicePrintTrigger();
            },
            reject: () => {
              invoicePrintTrigger();
            },
          })
        )
      : invoicePrintTrigger();
  };

  const handleEditOrder = () => {
    setSelectedAction("");
    setOrderRefundStatus("");

    if (isDSSubmitted || dsRerouteInProgress) {
      setShowCancelDSModal(true);
      dispatch(setActiveTab("delivery"));
      // Edit Order action is not resetting so adding timeout with minimal value.
      setTimeout(() => {
        setFieldValue("actionItem", "");
      }, 50);
    } else {
      dispatch(setEditOrder());
      triggerAction({
        action: "edit-and-lock-order",
      });
    }
  };

  return (
    <>
      {isActionProcessing ? (
        <View
          style={{
            ...tw("flex-row justify-between items-center"),
            width: "auto",
          }}
        >
          <Text style={{ fontWeight: "600", marginRight: 5 }}>
            {Localise(
              messages,
              orderRefundStatus === "Processing"
                ? "Processing Refund"
                : "Processing"
            )}
          </Text>
          <ActivityIndicator color={colors.activityIndicator} />
        </View>
      ) : (
        <View>
          <View style={tw("flex-row items-center justify-between")}>
            <View
              style={{
                ...tw("flex-row items-center flex-wrap justify-end pr-2"),
              }}
            >
              {orderRefundStatus !== "processed" && (
                <>
                  {!!orderActions.length && (
                    <FormFieldPicker
                      placeholder={{ label: "Actions" }}
                      containerStyle={{
                        paddingBottom: 0,
                        width: isMobile ? 80 : 100,
                      }}
                      data={orderActionsData}
                      name="actionItem"
                      togglePickerOnValChange
                      onChange={(val) => {
                        if (hasSettlementError && val === "cancel") {
                          triggerRequest({
                            requestType: "cancel",
                            orderReq: {
                              ignoreCancel: hasFulfillmentError,
                              reason: "Other",
                              comments: "",
                            },
                          });
                        } else if (val === "refund") {
                          if (isLocalOrder && isActiveOrder) {
                            setSelectedAction("");
                            setOrderRefundStatus("");
                            if (isDSSubmitted || dsRerouteInProgress) {
                              setShowCancelDSModal(true);
                              dispatch(setActiveTab("delivery"));
                            } else {
                              dispatch(setEditOrder());
                              triggerAction({
                                action: "edit-and-lock-order",
                              });
                              dispatch(setUserAction("refund"));
                              dispatch(setRecalcTaxAndFees(true));
                            }
                          } else {
                            if (
                              paymentItemStatus === "PAYMENT_TRANSFERRED" &&
                              newOrderItemId
                            ) {
                              ToasterHandler(
                                "uh oh",
                                Localise(
                                  messages,
                                  `Refund can be issued from the new order: ${newOrderItemId}`
                                )
                              );
                            } else {
                              setOrderRefundStatus("initiated");
                              dispatch(setActiveTab("payment"));
                              scrollToEndOfPage();
                            }
                          }
                          setShowReasonForm(false);
                        } else if (val === "save-order-changes") {
                          isFormChanged && isValid && setSelectedAction(val);
                        } else if (val === "nevermind") {
                          dispatch(setOrderModifying(false));
                          isLocalOrder &&
                            triggerAction({
                              action: "unlock-order",
                            });
                          resetForm();
                        } else if (val === "review-payment") {
                          scrollToEndOfPage();
                        } else if (val === "assign-to-designer") {
                          dispatch(setActiveTab("order-summary"));
                          scrollToEndOfPage();
                        } else if (
                          hasPendingPayLaterPayment &&
                          [
                            "delivery-confirmation",
                            "delivery-exception",
                          ].includes(val)
                        ) {
                          dispatch(setShowOrderUnpaidModal(true));
                        } else if (val === "email-invoice") {
                          dispatch(
                            setOnDemandInvoiceFields({
                              showOnDemandInvoiceForm: true,
                            })
                          );
                        } else if (val === "edit-order") {
                          handleEditOrder();
                        } else {
                          setSelectedAction(val);
                          setShowReasonForm(true);
                          if (
                            !isPickUpOrder &&
                            [
                              "delivery-confirmation",
                              "delivery-exception",
                            ].includes(val)
                          ) {
                            dispatch(setDconStatus(val));
                            setShowDconForm(false);
                            setTimeout(() => {
                              setShowDconForm(true);
                            }, 0);
                          }
                        }
                      }}
                    />
                  )}
                  <CancelDSModal
                    isOrderModify={true}
                    isCancelDSModal={isCancelDSModal}
                    setShowCancelDSModal={setShowCancelDSModal}
                    setShowReasonForm={setShowReasonForm}
                    setSelectedAction={setSelectedAction}
                    triggerAction={triggerAction}
                    cancelApprovalInfo={cancelApprovalInfo}
                  />

                  {driverActions.map((action) => {
                    if (
                      ["callRecipient", "textRecipient"].includes(action.key)
                    ) {
                      const { phone = "" } = recipientInfo;
                      if (phone === "") return null;
                    }
                    return (
                      <View
                        key={action.key}
                        style={{
                          ...tw("flex-row flex-wrap"),
                          ...theme.Button.secondaryButtonStyle,
                          ...(isSmallScreen && { marginBottom: 7 }),
                          ...tw("mr-1 mt-1"),
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                        }}
                      >
                        <Text
                          style={{
                            ...theme.Button.secondaryTitleStyle,
                            color: backgroundColors.primary2,
                            fontSize: 13,
                          }}
                          testID={action.key}
                          accessibilityLabel={action.key}
                          onPress={() => {
                            if (action.key === "navigation") {
                              startNavigation(
                                recipientInfo,
                                messages,
                                Localise
                              );
                            } else if (
                              [
                                "callRecipient",
                                "textRecipient",
                                "callShop",
                              ].includes(action.key)
                            ) {
                              triggerNativeActions(recipientInfo, action.key);
                            }
                          }}
                        >
                          {Localise(messages, action.label)}
                        </Text>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
            {EligibleToEditOrder && (
              <View style={tw("pr-2")}>
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={handleEditOrder}
                  testID="edit_order"
                  accessibilityLabel="edit_order"
                >
                  <Tooltip
                    text={Localise(messages, "Edit Order")}
                    renderForWebOnly={true}
                  >
                    <Image
                      style={{
                        width: 22,
                        height: 25,
                      }}
                      source={IMAGES["edit-rounded-square"]}
                    />
                  </Tooltip>
                </TouchableOpacity>
              </View>
            )}
            <View>
              {((status !== "NEW" && status !== "VIEWED") || isPickUpOrder) && (
                <>
                  <PrintIframeRenderer testID={orderItemId} />
                  <PrintIframeRenderer
                    testID={`orderInboundMessages_${orderItemId}`}
                    id="orderInboundMessages"
                  />
                  <PrintIframeRenderer
                    testID={`orderOutboundMessages_${orderItemId}`}
                    id="orderOutboundMessages"
                  />
                  <PrintIframeRenderer
                    testID={`orderMessages_${orderItemId}`}
                    id="orderMessages"
                  />
                  <PrintMenusContainer
                    isSmallScreen={isSmallScreen}
                    submitAction={setSelectedAction}
                    getExtendedMenuOptions={getExtendedMenuOptions({
                      isPrinted,
                      isDesignerWorkSheetPrinted,
                      isTrifoldDetailsPrinted,
                      isInvoiceDetailsPrinted,
                      isReceiptPrinted,
                      isSideCarOpen: false,
                      sourceMemberCode,
                      isWiredOrder,
                      nonOLCMessages,
                      unPrintedMessages,
                      showReceiptOption,
                    })}
                    onPrintSelection={onPrintSelection}
                    isPrinted={isPrinted}
                    action="print"
                    currentPage={"orders"}
                    printOptionsContainer={tw(
                      `flex-row justify-end items-center`
                    )}
                    disablePrint={disablePrint}
                  />
                </>
              )}
            </View>
          </View>
        </View>
      )}

      {showOnDemandInvoiceForm && (
        <CustomModal
          modalVisible={showOnDemandInvoiceForm}
          modalContent={{
            content: (
              <View
                style={{
                  maxWidth: isSmallScreen ? "100%" : 600,
                  maxHeight: isSmallScreen ? "100%" : 400,
                  margin: "auto",
                  backgroundColor: backgroundColors.secondary,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <View
                  style={[
                    tw("p-3 flex flex-row items-center justify-between"),
                    { backgroundColor: backgroundColors.primary },
                  ]}
                >
                  <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
                    {Localise(messages, "Email Invoice")}
                  </Text>
                </View>

                <Form
                  initialValues={{ recipientEmail: customerEmailFields }}
                  onSubmit={(values, formikBag) => {
                    sendOnDemandInvoiceEmail(values, formikBag);
                  }}
                  validationSchema={getOnDemandInvoiceFormValidationSchema(
                    Localise,
                    messages
                  )}
                  validateOnChange={validateOnDemandInvoiceFields}
                  validateOnBlur={validateOnDemandInvoiceFields}
                  render={(props) => {
                    const { values: onDemandFieldValues, submitCount } = props;

                    //eslint-disable-next-line
                    useEffect(() => {
                      if (submitCount > 0) {
                        dispatch(
                          setOnDemandInvoiceFields({
                            ...onDemandInvoiceResponse,
                            setValidationOnChange: true,
                          })
                        );
                      }
                    }, [submitCount]);

                    return (
                      <View>
                        <View
                          style={[
                            tw(`flex flex-row items-center justify-between`),
                          ]}
                        >
                          <Text
                            style={{
                              ...tw("my-4 ml-2"),
                              maxWidth: 360,
                              textAlign: "justify",
                            }}
                          >
                            {"Customer Email"}
                          </Text>

                          {customerEmailFields?.length < 5 && (
                            <TouchableOpacity
                              style={{ paddingHorizontal: 5 }}
                              onPress={() => {
                                dispatch(
                                  setOnDemandInvoiceFields({
                                    ...onDemandInvoiceResponse,
                                    customerEmailFields: [
                                      ...onDemandFieldValues?.recipientEmail,
                                      "",
                                    ],
                                  })
                                );
                              }}
                              testID="add-customer-email"
                              accessibilityLabel="add-customer-email"
                            >
                              <Tooltip
                                text={Localise(messages, "Add Customer Email")}
                              >
                                <Image
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  resizeMode="contain"
                                  source={IMAGES[`create-order`]}
                                />
                              </Tooltip>
                            </TouchableOpacity>
                          )}
                        </View>

                        {customerEmailFields?.map((field, index) => {
                          return (
                            <FormField
                              key={index}
                              autoCapitalize="none"
                              autoCorrect={false}
                              keyboardType="email-address"
                              name={`recipientEmail[${index}]`}
                              placeholder={"Enter Customer Email"}
                              textContentType="emailAddress"
                            />
                          );
                        })}

                        <View
                          style={tw(
                            "flex flex-row justify-center items-center mx-16"
                          )}
                        >
                          <Button
                            testID={"close_ondemand_popup"}
                            accessibilityLabel="close_ondemand_popup"
                            title={Localise(messages, "Cancel")}
                            titleStyle={theme.Button.secondaryTitleStyle}
                            buttonStyle={theme.Button.secondaryButtonStyle}
                            onPress={() => {
                              dispatch(
                                setOnDemandInvoiceFields({
                                  customerEmailFields: [customerEmail],
                                  showOnDemandInvoiceForm: false,
                                })
                              );
                              setFieldValue("actionItem", "");
                            }}
                          />
                          <SubmitButton
                            containerStyle={{ marginLeft: 24 }}
                            title={"Send"}
                            isSubmitOnEnter={true}
                            testID={"submit_email_content"}
                            accessibilityLabel={"submit_email_content"}
                          />
                        </View>
                      </View>
                    );
                  }}
                />
              </View>
            ),
          }}
          contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
          modalStyle={{ padding: 0 }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    selectedproducts: state.mhq.ongoing.global.selectedproducts,
  };
};

export default connect(mapStateToProps, null)(TakeAction);
