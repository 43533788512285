import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "react-native-elements";
import { View, TouchableOpacity, Image } from "react-native";

import tw from "tailwind-rn";
import moment from "moment";

import { CountDownTimer, PopupMenu, Tooltip } from "components/elements";

import { IMAGES, DIMENSIONS } from "static/assets/images";
import { colors, backgroundColors, datePriceChangeColors } from "styles/theme";

import lowerCase from "lodash/lowerCase";
import toLower from "lodash/toLower";

import {
  setCancelFormOnRefund,
  setRefundFormOnCancel,
} from "library/sagas/ongoing/order-details/slice";
import { selectCurrentPage } from "library/sagas/ongoing/global-data/selector";
import { getPickupTimeLeftInMins } from "library/utils/datetime";
import { replaceDeliverPhrase, isDSorder } from "library/utils/orderListing";
import I18NContext from "library/contexts/i18N";

import styles from "./styles";
import { PrintMenusContainer } from "components/elements/print-menu-container";

const ListItem = React.memo(
  ({
    item,
    itemKey,
    columnsList,
    subColumnsList = [],
    setRecordId,
    setDeliveryMethod,
    setSourceMemberCode,
    isSelectedRecord,
    setTimeLeftInMins,
    targetTime,
    showCountDownTimer,
    countDownPauseTime,
    menuOptions,
    submitAction,
    isSmallScreen,
    pauseCountDownTimer = false,
    isMobile,
    pickupTimeLeftInMins,
    setPickupTimeLeftInMins,
    recordId,
    listingType,
    index,
    readOnly,
    removeTile,
    removeTileById,
    getExtendedMenuOptions,
  }) => {
    const { messages, Localise } = React.useContext(I18NContext);
    const dispatch = useDispatch();

    const {
      displayOrderStatus,
      statusColor,
      imageName,
      isPickupOrder,
      storePickupDateTime,
      status = "",
      hasSettlementError = false,
      messageNumber = "",
      isPrinted,
      customerOrderId = "",
      toolTipText = "",
      notes = "",
      isFollowUp = false,
    } = item;

    const assignedPickupOrder =
      isPickupOrder && !["NEW", "VIEWED", "PRINTED"].includes(status);

    const isCompletedOrder = ["CANCELLED", "DELIVERED"].includes(status);

    const hasDateChangeNotification = [
      "Approve Date",
      "Date Approved",
      "Date Denied",
    ].includes(displayOrderStatus);
    const hasPriceChangeNotification = [
      "Approve Price",
      "Price Approved",
      "Price Denied",
    ].includes(displayOrderStatus);

    const { imageWidth = 20, imageHeight = 20 } = DIMENSIONS[imageName] || {};

    const deliveryServiceIcon = ({
      deliveryDate,
      status,
      dsEligible = false,
      orderCategory,
      latestDSRequestedStatus,
    }) => {
      const imageName = `delivery-service-${lowerCase(
        item.displayOrderStatus
      )}`;
      const hideDeliveryServiceIcon = [
        "REJECTED",
        "COMPLETED",
        "DELIVERED",
        "OUT_FOR_DELIVERY",
        "DS_REQUESTED",
        "DS_REQUEST_PENDING",
      ].includes(status);

      return (
        dsEligible &&
        !hideDeliveryServiceIcon &&
        !isDSorder(latestDSRequestedStatus, status) && // hide if order already DSDelivered/DSRequestInProgress
        orderCategory !== "outgoingOrders" && (
          <View
            style={{ position: "relative", top: 2 }}
            testID="DSEligibleIcon"
          >
            <Image
              style={{
                width: 16,
                height: 16,
                marginLeft: 4,
              }}
              resizeMode="cover"
              source={IMAGES[imageName] || IMAGES["delivery-service"]}
            />
          </View>
        )
      );
    };

    const Component = readOnly ? View : TouchableOpacity;

    const onPrintSelection = (printOption) => {
      if (printOption === "OrderDetail") {
        submitAction("printOrder");
      } else if (printOption === "TriFoldCard") {
        submitAction("triCardPrint");
      } else if (printOption === "Invoice") {
        submitAction("invoice");
      } else if (printOption === "DesignerWorksheet") {
        submitAction("designerWorksheetPrint");
      } else if (printOption === "OrderReceipt") {
        submitAction("receipt");
      }
    };

    const currentPage = useSelector(selectCurrentPage);

    const tooltipsConfig = [
      {
        condition: notes !== "",
        text: Localise(messages, notes),
        imageStyle: { width: 18, height: 18 },
        imageSource: IMAGES["message"],
      },
      {
        condition: isFollowUp,
        text: Localise(messages, "Order flagged for follow up"),
        imageStyle: { width: 18, height: 23, marginTop: isSmallScreen ? 0 : 1 },
        imageSource: IMAGES["followup-flag-on"],
      },
    ];

    return (
      <Component
        key={recordId}
        activeOpacity={0.6}
        onPress={() => {
          if (!readOnly) {
            dispatch(setRefundFormOnCancel(""));
            dispatch(setCancelFormOnRefund(""));
            setRecordId(item[itemKey]);
            setDeliveryMethod && setDeliveryMethod(item["deliveryMethod"]);
            setSourceMemberCode &&
              setSourceMemberCode(item["sourceMemberCode"]);
          }
        }}
        style={{
          minWidth: 300,
          flex: 1,
        }}
        testID={`${messageNumber}_${displayOrderStatus}`}
        accessibilityLabel={`${recordId}_${displayOrderStatus}`}
      >
        <View
          style={[
            styles.recordContainer,
            isSelectedRecord && styles.activeRecordContainer,
            !isMobile && { flex: 1 },
          ]}
          fsClass="fs-unmask"
        >
          <View
            style={[
              styles.record,
              {
                borderColor: statusColor || "transparent",
                alignItems: [
                  "orderItemId",
                  "primaryMemberCodeWithEmailId",
                ].includes(itemKey)
                  ? "center"
                  : "baseline",
              },
            ]}
            fsClass="fs-unmask"
          >
            {displayOrderStatus && (
              <View
                style={[
                  tw("justify-between items-center"),
                  styles.recordItemView,
                  styles.status,
                ]}
              >
                <View
                  style={tw(
                    `flex flex-row items-center w-full flex-wrap ${
                      isSmallScreen ? "justify-between" : "justify-start"
                    }`
                  )}
                >
                  <View style={styles.statusText}>
                    <Text
                      numberOfLines={2}
                      style={{
                        ...styles.recordItem,
                        textAlign: "left",
                        fontSize: 13,
                        fontWeight: "600",
                        width: isMobile ? "auto" : 85,
                        color: [
                          "#e5175e",
                          "#b50d81",
                          "#23a587",
                          "#037c77",
                          "#6c0057",
                          "#4aa779",
                        ].includes(statusColor)
                          ? ["Designed", "In Design"].includes(
                              displayOrderStatus
                            )
                            ? colors.primary
                            : statusColor
                          : colors.primary,
                      }}
                      testID="orderStatusText"
                    >
                      {Localise(
                        messages,
                        displayOrderStatus.replace("Cancelled", "Canceled")
                      )}
                    </Text>
                    {((isPickupOrder &&
                      !hasSettlementError &&
                      (pickupTimeLeftInMins > 30 ||
                        getPickupTimeLeftInMins(storePickupDateTime) > 30)) ||
                      assignedPickupOrder) &&
                      !isCompletedOrder && (
                        <View style={tw("mt-1")}>
                          <Text
                            numberOfLines={2}
                            style={{
                              ...styles.recordItem,
                              textAlign: "left",
                              fontSize: 12,
                              fontWeight: "600",
                              width: isMobile ? "auto" : 75,
                              color: [
                                "#e5175e",
                                "#b50d81",
                                "#23a587",
                                "#037c77",
                                "#6c0057",
                              ].includes(statusColor)
                                ? statusColor
                                : colors.primary,
                            }}
                          >
                            {moment(storePickupDateTime).format("h:mm A")}
                          </Text>
                        </View>
                      )}
                    {showCountDownTimer && !isPickupOrder && (
                      <CountDownTimer
                        targetTime={targetTime}
                        setTimeLeftInMins={setTimeLeftInMins}
                        pauseCountDownTimer={pauseCountDownTimer}
                        countDownPauseTime={countDownPauseTime}
                        textStyle={{
                          fontSize: 9,
                          textAlign: "left",
                          fontWeight: "600",
                          color: [
                            "#e5175e",
                            "#b50d81",
                            "#23a587",
                            "#037c77",
                            "#6c0057",
                          ].includes(statusColor)
                            ? statusColor
                            : colors.primary,
                        }}
                      />
                    )}
                    {isPickupOrder &&
                      !hasSettlementError &&
                      !assignedPickupOrder &&
                      (pickupTimeLeftInMins < 31 ||
                        getPickupTimeLeftInMins(storePickupDateTime) < 31) &&
                      (pickupTimeLeftInMins > 0 ||
                      getPickupTimeLeftInMins(storePickupDateTime) > 0 ? (
                        <CountDownTimer
                          targetTime={moment(storePickupDateTime)}
                          pauseCountDownTimer={false}
                          setTimeLeftInMins={setPickupTimeLeftInMins}
                          isPickUpOrder={isPickupOrder}
                          overrideColor={false}
                          textStyle={{
                            fontSize: 9,
                            textAlign: "left",
                            fontWeight: "600",
                            color: "#e5175e",
                          }}
                        />
                      ) : (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: "left",
                            fontWeight: "600",
                            color: "#e5175e",
                          }}
                        >
                          0:00:00
                        </Text>
                      ))}
                  </View>
                  <View style={styles.statusIcon} testID={`${imageName}`}>
                    <Tooltip
                      text={Localise(messages, toolTipText)}
                      renderForWebOnly={true}
                    >
                      <Image
                        style={{
                          width: imageWidth,
                          height: imageHeight,
                        }}
                        containerStyle={{
                          marginHorizontal: isSmallScreen ? 5 : 10,
                          marginVertical: 5,
                        }}
                        source={IMAGES[imageName]}
                      />
                    </Tooltip>
                  </View>
                </View>
              </View>
            )}
            {itemKey === "orderItemId" && (
              <View
                style={[
                  tw("flex flex-col justify-between items-center"),
                  styles.recordItemView,
                  styles.notesFlag,
                ]}
              >
                {tooltipsConfig.map(
                  (config, index) =>
                    config.condition && (
                      <Tooltip
                        key={index}
                        text={config.text}
                        renderForWebOnly={true}
                      >
                        <Image
                          style={config.imageStyle}
                          containerStyle={{
                            marginHorizontal: isSmallScreen ? 5 : 10,
                            marginVertical: 5,
                          }}
                          source={config.imageSource}
                        />
                      </Tooltip>
                    )
                )}
              </View>
            )}
            {columnsList.map((column) => {
              return (
                <React.Fragment key={column}>
                  {itemKey === "primaryMemberCodeWithEmailId" && (
                    <View
                      style={[
                        styles.recordItemView,
                        styles[column],
                        {
                          width: !isMobile ? 25 : item.isAccountLocked ? 25 : 0,
                        },
                      ]}
                    >
                      {column === "name" && item.isAccountLocked && (
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                          }}
                          source={IMAGES["lock"]}
                        />
                      )}
                    </View>
                  )}
                  <View style={[styles.recordItemView, styles[column]]}>
                    {/* Loop through subColumnsList[column] array directly */}
                    <Text
                      numberOfLines={1}
                      style={{
                        ...styles.recordItem,
                        textAlign:
                          isMobile && column === "displayPrice"
                            ? "right"
                            : "left",
                        ...(isSelectedRecord && {
                          color: backgroundColors.primary,
                        }),
                        ...(((hasDateChangeNotification &&
                          column === "displayDeliveryDate") ||
                          (hasPriceChangeNotification &&
                            column === "displayPrice") ||
                          item[`${subColumnsList[column]}`] === "Pending") && {
                          color:
                            datePriceChangeColors[
                              item[`${subColumnsList[column]}`]
                            ],
                        }),
                        fontWeight: isSelectedRecord ? "600" : "500",
                        ...(column === "deliveryInstructions" && {
                          textTransform: "none",
                        }),
                      }}
                      title={item[column]}
                      fsClass={
                        toLower(column) === "phone" ? "fs-exclude" : "fs-unmask"
                      }
                      testID={`${messageNumber}_${column}`}
                      accessibilityLabel={`${messageNumber}_${column}`}
                    >
                      {Localise(
                        messages,
                        column === "deliveryInstructions"
                          ? replaceDeliverPhrase(item[column])
                          : item[column]
                      )}
                      {isSmallScreen &&
                        column === "recipientCity" &&
                        deliveryServiceIcon(item)}
                    </Text>
                    {Array.isArray(subColumnsList[column]) ? (
                      <>
                        {subColumnsList[column].map((subItem, index) => (
                          <Text
                            key={`${messageNumber}_${subItem}_${index}`}
                            numberOfLines={1}
                            style={{
                              ...styles.subRecordItem,
                              ...(isSelectedRecord && {
                                color: backgroundColors.primary,
                              }),
                              ...(((hasDateChangeNotification &&
                                column === "displayDeliveryDate") ||
                                (hasPriceChangeNotification &&
                                  column === "displayPrice") ||
                                subItem === "Pending") && {
                                color: datePriceChangeColors[subItem],
                              }),
                              fontWeight: isSelectedRecord ? "600" : "500",
                              ...(column === "displayDeliveryDate" && {
                                textTransform: "none",
                              }),
                            }}
                            testID={`${messageNumber}_${subItem}_${index}`}
                            accessibilityLabel={`${messageNumber}_${subItem}_${index}`}
                          >
                            {item[`${subItem}`]}
                          </Text>
                        ))}
                        {!isSmallScreen &&
                          column === "recipientCity" &&
                          deliveryServiceIcon(item)}
                      </>
                    ) : (
                      <Text
                        numberOfLines={2}
                        style={{
                          ...styles.subRecordItem,
                          ...(isSelectedRecord && {
                            color: backgroundColors.primary,
                          }),
                          ...(((hasDateChangeNotification &&
                            column === "displayDeliveryDate") ||
                            (hasPriceChangeNotification &&
                              column === "displayPrice") ||
                            item[`${subColumnsList[column]}`] ===
                              "Pending") && {
                            color:
                              datePriceChangeColors[
                                item[`${subColumnsList[column]}`]
                              ],
                          }),
                          fontWeight: isSelectedRecord ? "600" : "500",
                          ...(column === "displayDeliveryDate" && {
                            textTransform: "none",
                          }),
                        }}
                        testID={`${messageNumber}_${subColumnsList[column]}`}
                        accessibilityLabel={`${messageNumber}_${subColumnsList[column]}`}
                      >
                        {column === "displayDeliveryDate"
                          ? replaceDeliverPhrase(
                              item[`${subColumnsList[column]}`]
                            )
                          : item[`${subColumnsList[column]}`]}
                        {!isSmallScreen &&
                          column === "recipientCity" &&
                          deliveryServiceIcon(item)}
                      </Text>
                    )}
                    {customerOrderId.length > 0 &&
                      column === "customerName" && (
                        <Text
                          style={{
                            ...styles.subRecordItem,
                            ...(isSelectedRecord && {
                              color: backgroundColors.primary,
                            }),
                            fontWeight: isSelectedRecord ? "600" : "500",
                          }}
                        >
                          {customerOrderId}
                        </Text>
                      )}
                  </View>
                </React.Fragment>
              );
            })}
          </View>
          {!["staff", "ftd-user-accounts"].includes(listingType) &&
            !readOnly && (
              <PrintMenusContainer
                printMenuContainer={styles.printMenuContainer}
                printContainer={styles.printContainer}
                printText={styles.printText}
                isSmallScreen={isSmallScreen}
                submitAction={submitAction}
                getExtendedMenuOptions={getExtendedMenuOptions}
                onPrintSelection={onPrintSelection}
                currentPage={currentPage}
                isPrinted={isPrinted}
                printOptionsContainer={styles.printOptionsContainer}
                action="printOrder"
                showPrintOptions={
                  (status !== "NEW" && status !== "VIEWED") || isPickupOrder
                }
                imageProps={{ imageWidth: 22, imageHeight: 22 }}
              />
            )}
          {!readOnly && menuOptions && menuOptions.length > 0 && (
            <PopupMenu
              containerStyle={styles.menuContainer}
              optionsContainerStyle={{
                width: 150,
                padding: 5,
              }}
              menuOptions={menuOptions}
              onSelect={submitAction}
              testID="list_item_menu"
              accessibilityLabel="list_item_menu"
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                resizeMode="contain"
                source={IMAGES[`three-dots`]}
              />
            </PopupMenu>
          )}
          {readOnly && removeTile && (
            <TouchableOpacity
              onPress={() => {
                dispatch(removeTileById(item[itemKey]));
              }}
              testID="close"
              style={{ ...tw("flex justify-center items-center"), width: "7%" }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          )}
        </View>
      </Component>
    );
  }
);

export default ListItem;
