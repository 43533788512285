import { qrPlaceHolder } from "components/views/drawer/order-details/helper.js";

export const keyMap = {
  CASH: "Cash",
  CHECK: "Check",
  INVOICE: "Invoice",
  PAID_ELSEWHERE: "Paid Elsewhere",
  CREDIT_CARD: "CC",
  SAVED_CARD: "Saved Payment",
  CREDIT_BALANCE: "Credit Balance",
  GIFT_CARD: "Gift Card",
  PAY_LATER: "Pay Later",
};

export const scanActionsMap = {
  Accepted: "accepted",
  "Assign to Designer": "assign",
  "In Design": "design",
  Designed: "designed",
  Delivery: "delivery",
  Completed: "completed",
  "Ready for Pickup": "delivery",
  "Picked Up": "completed",
  "Show Recipe": "showrecipe",
  "View Order": "vieworder",
  "Take Payment": "unpaid",
  Reprint: "showreprint",
  "Optimize Route": "optimize",
};

export const bulkScanActionLabel = {
  accept: "Accepted",
  "assign-to-designer": "Assign to Designer",
  design: "Design",
  designed: "Designed",
  "out-for-delivery": "Delivery / Ready for Pickup",
  "delivery-confirmation": "Completed / Picked up",
};

export const desiredOrderActions = {
  accept: "accepted",
  "assign-to-designer": "assign",
  design: "design",
  designed: "designed",
  "out-for-delivery": "delivery",
  "delivery-confirmation": "completed",
};

export const qrScanActions = (payload, qrImagesData) => {
  const getActionsList = (payload) => {
    const actionsList = payload.map((each) => {
      const qrImage = qrImagesData[each.id];
      return `<div class="action" data-testid="${
        each.label
      }"><div data-testid="action_label">${
        each.label
      }</div><div style="min-height: 75px;" data-testid="action_qr">${qrPlaceHolder(
        qrImage,
        {
          width: 75,
          height: 75,
          position: "top",
        }
      )}</div></div>`;
    });
    return actionsList.join("");
  };

  return `<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <title>Mercury HQ</title>
      <style>
        @page {
          size: letter;
          margin: 20px;
        }
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
        }
        .container {
          max-width: 8.5in; /* Letter size width */
          margin: 5px auto;
          padding: 10px;
        }
        .grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
        }
        .action {
          border: 1px solid #ccc;
          font-size: 20px;
          padding: 10px 20px;
          min-height: 90px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .pageTitle {
          font-size: 22px;
          font-weight: 700;
          padding-bottom: 10px;
        }
        @media print {
          #header, #footer {
            display: none;
          }
        }
      </style>
    </head>
    <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
      <div class="container">
        <div class="pageTitle" data-testid="pageTitle">MercuryHQ Order Actions</div>
        <div class="grid" data-testid="grid">
          ${getActionsList(payload)}
        </div>
      </div>
    </body>
  </html>
  `;
};
