/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect, useContext } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "react-native-elements";

import {
  setLoading,
  setPageActions,
  fetchPrintDetails,
  triggerMeetBallAction,
} from "library/sagas/views/home/drawer/orders-new/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { selectActions } from "library/sagas/views/home/drawer/orders-new/selector";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import UserProfileContext from "library/contexts/userProfile";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import I18NContext from "library/contexts/i18N";
import {
  getOrderInternalStatus,
  orderDsRequestRerouting,
} from "library/utils/orderListing";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import {
  PrinterSettingsKeys,
  nonStandardEligiblePrinterSettings,
} from "components/views/drawer/shop-settings/orders/printer-settings/config";
import { getShopSettings } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";
import { getActionObj } from "components/views/drawer/order-details/helper";
import {
  printActions,
  invoicePrintAction,
  designerWorksheetAction,
  orderMessagesPrintAction,
} from "components/views/drawer/order-details/order-head/take-action/helper";
import { getOrderStatus } from "components/views/drawer/order-details/order-head/order-breadcrumb/helper";
import { isFloristDelivered } from "components/views/drawer/order-details/delivery-info/helper.js";
import { ToasterHandler } from "components/elements";

import { useNavigation, CommonActions } from "@react-navigation/native";
import IMAGES from "static/assets/images";
import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { printTrigger } from "../../order-details/order-head/take-action/helper";
import {
  needsActionMappings,
  oldPrintActionKeys,
  newPrintActionKeys,
  columnIDs,
  meetBallActionKeys,
} from "../config";
import "../styles.css";

export const CustomHeaderComponent = (props) => {
  const isFilterInActive =
    isEmpty(props?.column?.colDef?.appliedFilter ?? {}) &&
    isEmpty(props?.column?.colDef?.appliedSort ?? "") &&
    isEmpty(props?.column?.colDef?.appliedSearch ?? "");
  const filterIconRef = useRef(null);

  const openFilter = () => {
    filterIconRef.current && props.showColumnMenu(filterIconRef.current);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>{props.displayName}</span>
      <div
        ref={filterIconRef}
        onClick={openFilter}
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: 8,
          cursor: "pointer",
          ...(!isFilterInActive
            ? {
                backgroundColor: "#DFE5EA",
                padding: 5,
                borderRadius: 5,
                shadowColor: "#000000",
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowRadius: 5,
                shadowOpacity: 1.0,
              }
            : {}),
        }}
      >
        <Image
          style={{ width: 12, height: 12 }}
          resizeMode={"contain"}
          source={IMAGES["column_filter"]}
        />
        <span
          style={{
            position: "absolute",
            right: 4,
            top: 13,
            width: 8,
            height: 8,
            backgroundColor: isFilterInActive ? "transparent" : "#1F4256",
            borderRadius: "50%",
            marginRight: 4,
          }}
        ></span>
      </div>
    </div>
  );
};

export function CustomFilterComponent(props) {
  const dispatch = useDispatch();
  const { gridState = [] } = useSelector(selectActions);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onDateChange = (dates, filterVal) => {
    const [start, end] = dates;
    const startDateFormatted = moment(start).format("MM-DD-YYYY");
    const endDateFormatted = moment(end).format("MM-DD-YYYY");

    setStartDate(start);
    setEndDate(end);

    start &&
      end &&
      updateFilterState(
        `${filterVal}::${startDateFormatted}::${endDateFormatted}`
      );
  };

  const { filterOptions = [], colDef: { colId = "" } = {} } = props;
  const searchValue =
    gridState?.find((e) => e?.colId === colId)?.appliedSearch.split("::")[1] ||
    "";

  const updateFilterState = (filter = "") => {
    dispatch(
      setPageActions({
        type: "saveColumnFilter",
        value: filter,
        callback: (reload = false) => {
          reload && props.filterChangedCallback();
        },
      })
    );
  };

  const getFilterStatus = (colId, rowId, label) => {
    const filterState =
      gridState?.find((e) => e?.colId === colId)?.appliedFilter?.[rowId] || [];
    return filterState.includes(label);
  };

  useEffect(() => {
    if (gridState.length && colId === "delivery_date") {
      const { startDate = "", endDate = "" } =
        gridState?.find((e) => e?.colId === "delivery_date")?.appliedFilter ||
        {};

      if (startDate.length && endDate.length) {
        const start = new Date(moment(startDate).format("MM/DD/YYYY"));
        const end = new Date(moment(endDate).format("MM/DD/YYYY"));

        setStartDate(start);
        setEndDate(end);
      }
    }
  }, []);

  return (
    <View style={{ padding: 10 }}>
      <Input
        containerStyle={{ paddingHorizontal: 0 }}
        inputContainerStyle={{
          height: 30,
          marginVertical: 5,
        }}
        errorStyle={{ paddingBottom: 0 }}
        onChangeText={(val) => {
          dispatch(
            setPageActions({
              type: "saveColumnSearch",
              value: `${colId}::${val}`,
            })
          );
        }}
        value={searchValue}
        placeholder={"Search.."}
        leftIcon={
          <Image
            style={{ width: 12, height: 12 }}
            resizeMode={"contain"}
            source={IMAGES["search"]}
          />
        }
      />
      {filterOptions.map((e, i) => {
        const refinedOptions = e.filters.filter((e) =>
          e.label.toLowerCase().includes(searchValue.toLowerCase())
        );

        if (!refinedOptions.length) return null;

        return (
          <View style={{ paddingVertical: 5 }} key={i}>
            <Text style={{ fontSize: 14, fontWeight: 700, marginBottom: 5 }}>
              {e.heading}
            </Text>
            {refinedOptions.map(({ label, name }, j) => {
              const checked = getFilterStatus(colId, e.rowId, label);

              return (
                <React.Fragment key={j}>
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      updateFilterState(`${colId}::${e.rowId}::${label}`);
                    }}
                  >
                    <div
                      className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
                        checked ? "ag-checked" : ""
                      }`}
                      role="presentation"
                    >
                      <input
                        className="ag-input-field-input ag-checkbox-input"
                        type="checkbox"
                      />
                    </div>
                    <View style={{ marginRight: 5 }} />
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        marginVertical: 5,
                      }}
                    >
                      {name}
                    </Text>
                  </TouchableOpacity>
                  {label === "CustomDatePicker" && checked && (
                    <View style={{ paddingTop: 10 }}>
                      <DatePicker
                        selected={startDate}
                        onChange={(dates) =>
                          onDateChange(dates, `${colId}::${e.rowId}::${label}`)
                        }
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                    </View>
                  )}
                </React.Fragment>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

const NeedsActionToolTip = ({ position, actions }) => {
  return ReactDOM.createPortal(
    <div
      className="custom-tooltip"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      {actions.map((e) => {
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 10,
                marginRight: 5,
                paddingHorizontal: 10,
                width: 150,
                height: 26,
                backgroundColor: "white",
                borderWidth: 1,
                borderColor: needsActionMappings[e].color,
                borderRadius: 8,
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode={"contain"}
                source={IMAGES[needsActionMappings[e].image]}
              />
              <View style={{ marginRight: 5 }} />
              <Text
                style={{
                  flex: 1,
                  textAlign: "center",
                  color: needsActionMappings[e].color,
                }}
              >
                {needsActionMappings[e].label}
              </Text>
            </View>
          </View>
        );
      })}
    </div>,
    document.body
  );
};

export const StatusCellRenderer = (props) => {
  const needsActionRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const {
    status: { needsAction = [], order_status = "" } = {},
    group = false,
  } = props?.data ?? {};
  const editorValues = props?.colDef?.cellEditorParams?.values ?? [];
  const renderValue = editorValues.find(
    (e) => e?.value?.toLowerCase() === order_status?.toLowerCase()
  );
  const {
    label = "",
    value = "",
    backgroundColor = "",
    color = "",
  } = renderValue ?? {};

  const handleTooltipToggle = () => {
    const rect = needsActionRef.current.getBoundingClientRect();
    setTooltipPosition({
      top: rect.bottom + 10,
      left: rect.x - 75,
    });
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const gridXasisElement = document.querySelector(".ag-center-cols-viewport");
    const gridYaxisElement = document.querySelector(".ag-body-viewport");

    const handleScroll = () => {
      showTooltip && setShowTooltip(false);
    };

    gridXasisElement?.addEventListener("scroll", handleScroll);
    gridYaxisElement?.addEventListener("scroll", handleScroll);

    return () => {
      gridXasisElement?.removeEventListener("scroll", handleScroll);
      gridYaxisElement?.removeEventListener("scroll", handleScroll);
    };
  }, [showTooltip]);

  useEffect(() => {
    const handleClickOutside = () => {
      setShowTooltip(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (group) return null;

  return (
    <div className="custom-cell">
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 10,
          paddingHorizontal: 10,
          width: 150,
          height: 26,
          backgroundColor: backgroundColor || "#AADEC640",
          borderWidth: 1,
          borderColor: "#31AA92",
          borderRadius: 8,
        }}
      >
        <Image
          style={{ width: 20, height: 20 }}
          resizeMode={"contain"}
          source={IMAGES[label || "accepted"]}
        />
        <View style={{ marginRight: 5 }} />
        <Text
          style={{ flex: 1, textAlign: "center", color: color || "#31AA92" }}
        >
          {value || props.value}
        </Text>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 10,
            marginRight: 5,
            paddingHorizontal: 10,
            width: 150,
            height: 26,
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: needsActionMappings[needsAction[0]].color,
            borderRadius: 8,
          }}
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode={"contain"}
            source={IMAGES[needsActionMappings[needsAction[0]].image]}
          />
          <View style={{ marginRight: 5 }} />
          <Text
            style={{
              flex: 1,
              textAlign: "center",
              color: needsActionMappings[needsAction[0]].color,
            }}
          >
            {needsActionMappings[needsAction[0]].label}
          </Text>
        </View>
        <TouchableOpacity ref={needsActionRef} onPress={handleTooltipToggle}>
          <Text
            style={{
              flex: 1,
              textAlign: "center",
              color: needsActionMappings[needsAction[0]].color,
            }}
          >
            +{needsAction.length}
          </Text>
        </TouchableOpacity>
      </View>
      {showTooltip && (
        <NeedsActionToolTip position={tooltipPosition} actions={needsAction} />
      )}
    </div>
  );
};

export const RecipientCellRenderer = (props) => {
  const { group = false } = props?.data ?? {};

  if (group) return null;

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text>{props.value.name}</Text>
      <View style={{ marginBottom: 1 }} />
      <Text>{props.value.address}</Text>
      <View style={{ marginBottom: 1 }} />
      <Text>{props.value.status}</Text>
      <View style={{ marginBottom: 1 }} />
      <Text>{props.value.phone}</Text>
    </View>
  );
};

export const CustomerCellRenderer = (props) => {
  const { group = false } = props?.data ?? {};

  if (group) return null;

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text>{props.value.name}</Text>
      <View style={{ marginBottom: 1 }} />
      <Text>{props.value.phone}</Text>
    </View>
  );
};

export const DateCellRenderer = (props) => {
  const { group = false } = props?.data ?? {};

  if (group) return null;

  const { status: { needsAction = [] } = {} } = props.data;
  const color = needsAction.includes("date_request_approved")
    ? needsActionMappings["date_request_approved"].color
    : needsAction.includes("approve_request_denied")
    ? needsActionMappings["approve_request_denied"].color
    : "black";

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{moment(props.value).format("MM/DD/YYYY")}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{"Approved"}</Text>
    </View>
  );
};

export const PriceCellRenderer = (props) => {
  const { group = false } = props?.data ?? {};

  if (group) return null;

  const { status: { needsAction = [] } = {} } = props.data;
  const color = needsAction.includes("price_request_approved")
    ? needsActionMappings["price_request_approved"].color
    : needsAction.includes("approve_request_denied")
    ? needsActionMappings["approve_request_denied"].color
    : "black";

  return (
    <View>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{props.value.amount}</Text>
      <View style={{ marginBottom: 5 }} />
      <Text style={{ color }}>{props.value.status}</Text>
    </View>
  );
};

export const ProductCellRenderer = (props) => {
  const { group = false, value = {} } = props?.data ?? {};

  if (group) return null;

  const { product_first_choice_description = "" } = value;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingVertical: 5,
      }}
    >
      <Image
        style={{ width: 60, height: 60, borderRadius: 10 }}
        resizeMode={"contain"}
        source={IMAGES["product_flower"]}
      />
      <View style={{ marginLeft: 5 }} />
      <View>
        <Text>{product_first_choice_description}</Text>
        <View style={{ marginBottom: 5 }} />
        <Text
          style={{
            borderColor: "lightGray",
            borderWidth: 1,
            borderRadius: 10,
            padding: 2,
            width: 50,
            textAlign: "center",
          }}
        >
          {"Floral"}
        </Text>
        <View style={{ marginBottom: 5 }} />
        <Text>{"5 Items"}</Text>
      </View>
    </View>
  );
};

const MeetballActions = ({
  position,
  actions,
  meetBallsPopupRef,
  triggerAction,
}) => {
  return ReactDOM.createPortal(
    <div
      ref={meetBallsPopupRef}
      className="custom-tooltip"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        visibility: position.visibility,
      }}
    >
      <View>
        {actions.map((e, i) => {
          return (
            <TouchableOpacity
              key={i}
              onPress={() => triggerAction(e.key)}
              style={{ padding: 5 }}
            >
              <Text>{e.label}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </div>,
    document.body
  );
};

const PrintActions = ({
  position,
  printActionsPopupRef,
  activePrintActions,
  statusObject,
  triggerPrint,
}) => {
  return ReactDOM.createPortal(
    <div
      ref={printActionsPopupRef}
      className="custom-tooltip"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        visibility: position.visibility,
      }}
    >
      <View style={{ alignItems: "flex-start" }}>
        {activePrintActions.map(({ key, label }, i) => {
          return (
            <TouchableOpacity
              key={i}
              onPress={() => triggerPrint(oldPrintActionKeys[key])}
              style={{ flexDirection: "row", alignItems: "center", padding: 5 }}
            >
              <Text style={{ width: 100 }}>{label}</Text>
              {statusObject[newPrintActionKeys[key]] && (
                <Image
                  style={{ width: 15, height: 15 }}
                  resizeMode={"contain"}
                  source={IMAGES["confirm"]}
                />
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    </div>,
    document.body
  );
};

export const ActionsCellRenderer = (props = {}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);
  const { userRole } = useContext(UserProfileContext);
  const { userProfile: { firstName: operator } = {} } =
    useContext(UserProfileContext);
  const meetballsRef = useRef(null);
  const printActionsRef = useRef(null);
  const meetBallsPopupRef = useRef(null);
  const printActionsPopupRef = useRef(null);

  const [showMeetBallActions, setShowMeetBallActions] = useState(false);
  const [showPrintActions, setShowPrintActions] = useState(false);
  const [meetBallActionsPosition, setMeetBallActionsPosition] = useState({
    top: 0,
    left: 0,
    visibility: "hidden",
  });
  const [printActionsPosition, setPrintActionsPosition] = useState({
    top: 0,
    left: 0,
    visibility: "hidden",
  });

  const {
    data: {
      correlation_id: recordId = "",
      member_code: sourceMemberCode = "",
      fulfillment: deliveryMethod = "",
      delivery_date: deliveryDate = "",
      status: statusObject = {},
      messages: orderMessages = [],
      dsEligible = false,
      autoSendToDS = false,
      latestDSRequestedStatus = "",
      numOfUnreadChatMessages = 0,
      numOfUnreadStructuredResponses = 0,
      numOfReadChatMessages = 0,
      direction = "",
      orderSource = "",
      giftCardType = "",
      hasDSFulfillmentError = false,
      hasSettlementError = false,
      designer = {},
      group = false,
    } = {},
  } = props;

  const isDSSubmitEligible =
    dsEligible &&
    autoSendToDS &&
    !["SUCCESS", "REROUTING"].includes(latestDSRequestedStatus);

  // Print Actions
  const memberCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.ORDERS in permissions[memberCode])
      memberCodes.push(memberCode);
  });
  const isMHQNonCoreUser = isMHQNonCoreMember(sourceMemberCode);
  const nonOLCMessages = orderMessages.filter((x) => x.messageType !== "OLC");
  const isWiredOrder = deliveryMethod === "FLORIST_PARTNER";
  const showOrderMessagePrintActions = isWiredOrder && nonOLCMessages.length;
  let activePrintActions = [];

  if (isMHQNonCoreUser) {
    activePrintActions = [
      ...printActions,
      ...invoicePrintAction,
      ...designerWorksheetAction,
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  } else {
    activePrintActions = [
      ...printActions,
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  }

  // Meetball Actions
  const isPickupOrder = [
    "STORE_PICKUP",
    "MOL_CUSTOMER_PICKUP",
    "PARTNER_STORE_PICKUP",
  ].includes(deliveryMethod);

  let internalSubStatus = "";
  if (numOfUnreadChatMessages > 0 || numOfUnreadStructuredResponses > 0)
    internalSubStatus = "view-chats";
  else if (numOfReadChatMessages > 0) internalSubStatus = "review-chats";

  const subActions = get(ListOfSubActions, internalSubStatus, []);
  const showAdjustmentaction =
    moment().isBefore(moment(deliveryDate).add(90, "days")) &&
    moment(deliveryDate).month() !== moment().month();
  const adjustmentAction =
    showAdjustmentaction &&
    direction === "OUTBOUND" &&
    deliveryMethod !== "PHONE_OUT" &&
    !["CANCELLED", "REJECTED", "FORFEITED", "ERROR"].includes(
      statusObject.order_status
    )
      ? [{ key: "adjustment", label: "Adjustment" }]
      : [];
  const internalStatus = getOrderStatus({
    status: statusObject.order_status,
    isPickUpOrder: isPickupOrder,
  });
  const isLocalOrder = isFloristDelivered(deliveryMethod);
  const rejectAction =
    [
      "incoming-pickup",
      "accepted-pickup",
      "design-pickup",
      "designed-pickup",
      "delivery-pickup",
    ].includes(internalStatus) &&
    !isLocalOrder &&
    orderSource !== "MOL"
      ? [{ key: "reject", label: "Reject" }]
      : [];
  const isGiftCard = ["DIGITAL_GIFT_CARDS", "PHYSICAL_GIFT_CARDS"].includes(
    giftCardType
  );
  const cancelAction =
    ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(
      deliveryMethod
    ) &&
    statusObject.order_status !== "CANCELLED" &&
    isLocalOrder &&
    !isGiftCard
      ? [{ key: "cancel", label: "Cancel" }]
      : [];
  const dsRerouteInProgress = orderDsRequestRerouting({
    latestDSRequestedStatus,
  });
  const dsErrorAction =
    direction === "INBOUND" &&
    (hasDSFulfillmentError || dsRerouteInProgress) &&
    statusObject.order_status !== "DELIVERED"
      ? [{ key: "delivery-confirmation", label: "Confirm Delivery" }]
      : [];
  const hasDesignCenter = hasDesignCenterFeature(permissions, sourceMemberCode);
  const hasDesigner = !!designer && designer?.id;
  const assignToDesigner =
    direction === "INBOUND" &&
    hasDesignCenter &&
    ["accepted", "in-design", "accepted-pickup", "design-pickup"].includes(
      internalStatus
    ) &&
    !hasDesigner &&
    !hasSettlementError
      ? [{ key: "assign-to-designer", label: "Assign to Designer" }]
      : [];
  const settlementAction =
    hasSettlementError ||
    ["CANCELLED", "REJECTED", "FORFEITED"].includes(statusObject.order_status)
      ? [{ key: "copy-order", label: "Copy Order" }]
      : [];

  const coreActionsList = {};
  if (sourceMemberCode?.length && !hasDesignCenter) {
    // Removing "designed" objects from the each status array
    Object.values(ListOfActions).forEach((value, index) => {
      const updatedArray = value.filter(
        (item) => item.key !== "designed" && item.key !== "designed-pickup"
      );
      coreActionsList[Object.keys(ListOfActions)[index]] = updatedArray;
    });
  }
  const finalActionsList = hasDesignCenter ? ListOfActions : coreActionsList;
  const finalActions = get(
    finalActionsList,
    isPickupOrder
      ? getOrderInternalStatus(
          { status: statusObject.order_status },
          isPickupOrder
        )
      : direction === "INBOUND" || deliveryMethod === "PHONE_OUT"
      ? hasDSFulfillmentError || dsRerouteInProgress
        ? "dsfullfillmenterror"
        : statusObject.order_status !== "FORWARDED"
        ? hasSettlementError
          ? []
          : internalStatus === "in-design"
          ? "design"
          : internalStatus
        : []
      : internalStatus !== "rejected" && internalStatus !== "cancelled"
      ? "outgoing"
      : "",
    []
  );
  const menuOptions = finalActions.concat(
    subActions,
    adjustmentAction,
    rejectAction,
    cancelAction,
    dsErrorAction,
    assignToDesigner,
    settlementAction
  );
  const meetballActions =
    (statusObject.order_status !== "NEW" &&
      statusObject.order_status !== "VIEWED") ||
    isPickupOrder
      ? [
          ...menuOptions,
          {
            key: "printOrder",
            label: statusObject.details_printed
              ? "Reprint Order"
              : "Print Order",
          },
        ]
      : menuOptions;

  const triggerAction = (action) => {
    setShowMeetBallActions(false);
    dispatch(setLoading(true));

    if (action === "copy-order") {
      // Timeout to avoid the screen stuck while redirecting to create order
      setTimeout(() => {
        dispatch(setCurrentPage("create-order"));
        dispatch(setLoading(false));
        navigation.dispatch(
          CommonActions.navigate({
            name: "create-order",
            params: {
              id: recordId,
              action: "copy",
              smc: sourceMemberCode,
              dm: deliveryMethod,
            },
          })
        );
      }, 2000);
    } else if (
      [
        "accept-print",
        "printOrder",
        "triCardPrint",
        "invoice",
        "designerWorksheetPrint",
      ].includes(action)
    ) {
      dispatch(setLoading(false));
      triggerPrint(action === "printOrder" ? "print" : action);
    } else {
      const reqObj = getActionObj(deliveryDate, action, "");
      const isMarkRead = action === "markAsRead";
      const requestMethod = isMarkRead
        ? "order-message-actions"
        : "order-actions";
      const params = {
        recordId,
        deliveryMethod,
        sourceMemberCode,
        ...(requestMethod === "order-message-actions"
          ? {
              markAsRead: isMarkRead,
              isStructured: true,
              isUnstructured: true,
            }
          : {
              action,
              ...reqObj,
              operator,
            }),
      };

      dispatch(
        triggerMeetBallAction({
          requestMethod,
          params,
          callback: (error) => {
            if (error) {
              ToasterHandler(
                "uh oh",
                Localise("Something went wrong, Please try again later!")
              );
            } else {
              dispatch(setLoading(false));
              if (Object.keys(meetBallActionKeys).includes(params.action)) {
                props.node.setData({
                  ...props.data,
                  status: {
                    ...props.data.status,
                    order_status: meetBallActionKeys[params.action],
                  },
                });
                props.api.ensureColumnVisible(columnIDs.status);
                props.api.flashCells({
                  rowNodes: [props.node],
                  columns: [columnIDs.status],
                });
              }
            }
          },
        })
      );
    }
  };

  const triggerPrint = (action) => {
    setShowPrintActions(false);
    dispatch(setLoading(true));

    dispatch(
      fetchPrintDetails({
        recordId,
        deliveryMethod,
        sourceMemberCode,
        callback: ({ res, productsInfo }) => {
          const {
            deliveryInfo: { cardMessage = "" },
          } = res.orderItems[0];

          if (
            [
              "accept-print",
              "print",
              "triCardPrint",
              "invoice",
              "designerWorksheetPrint",
            ].includes(action)
          ) {
            // getting the latest printer-settings to support remote print
            dispatch(
              getShopSettings({
                params: {
                  memberCode: sourceMemberCode,
                  ids: isMHQNonCoreUser
                    ? PrinterSettingsKeys
                    : nonStandardEligiblePrinterSettings,
                  updateStorage: true,
                },
                resolve: () => {
                  dispatch(setLoading(false));
                  printTrigger({
                    selectedAction: action,
                    orderDetails: res.orderItems[0],
                    memberCodes,
                    operatorName: operator,
                    messages,
                    Localise,
                    customerInfo: res?.customerInfo,
                    paymentDetails: res?.paymentDetails,
                    dispatch,
                    selectedproducts: productsInfo,
                    cardInfo: res?.cardInfo,
                    imagesData: {},
                    cardMessage,
                    triggerAction,
                    orderDetailResponse: res,
                    ordersList: true,
                    deliveryInfo: get(res?.orderItems[0], `deliveryInfo`, {}),
                    recordId,
                    isDSSubmitEligible,
                    userRole,
                  });
                },
              })
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    if (showMeetBallActions) {
      const rect = meetballsRef.current.getBoundingClientRect();
      const tooltipWidth = meetBallsPopupRef.current.offsetWidth;

      setMeetBallActionsPosition({
        top: rect.bottom + 10,
        left: rect.left - (tooltipWidth + 28) / 2,
        visibility: "visible",
      });
    } else if (showPrintActions) {
      const rect = printActionsRef.current.getBoundingClientRect();
      const tooltipWidth = printActionsPopupRef.current.offsetWidth;

      setPrintActionsPosition({
        top: rect.bottom + 5,
        left: rect.left - (tooltipWidth - 18) / 2,
        visibility: "visible",
      });
    }

    const gridXasisElement = document.querySelector(".ag-center-cols-viewport");
    const gridYaxisElement = document.querySelector(".ag-body-viewport");

    const handleScroll = () => {
      showMeetBallActions && setShowMeetBallActions(false);
      showPrintActions && setShowPrintActions(false);
    };

    gridXasisElement?.addEventListener("scroll", handleScroll);
    gridYaxisElement?.addEventListener("scroll", handleScroll);

    return () => {
      gridXasisElement?.removeEventListener("scroll", handleScroll);
      gridYaxisElement?.removeEventListener("scroll", handleScroll);
    };
  }, [showMeetBallActions, showPrintActions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      meetBallsPopupRef.current &&
        !meetBallsPopupRef.current.contains(event.target) &&
        setShowMeetBallActions(false);
      printActionsPopupRef.current &&
        !printActionsPopupRef.current.contains(event.target) &&
        setShowPrintActions(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (group) return null;

  return (
    <div className="custom-cell">
      <View
        style={{
          flexDirection: "row",
          marginVertical: 5,
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => setShowMeetBallActions(!showMeetBallActions)}
        >
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode={"contain"}
            source={IMAGES["meatballs_new"]}
          />
        </TouchableOpacity>
        <View style={{ marginRight: 5 }} />
        <TouchableOpacity
          ref={meetballsRef}
          onPress={() => triggerPrint("print")}
        >
          <Image
            style={{ width: 30, height: 30 }}
            resizeMode={"contain"}
            source={IMAGES[props?.value ? "printAgain" : "print"]}
          />
        </TouchableOpacity>
        <View style={{ marginRight: 5 }} />
        <TouchableOpacity
          ref={printActionsRef}
          onPress={() => setShowPrintActions(!showPrintActions)}
        >
          <Image
            style={{ width: 15, height: 15 }}
            resizeMode={"contain"}
            source={IMAGES["vector_new"]}
          />
        </TouchableOpacity>
      </View>
      {showMeetBallActions && (
        <MeetballActions
          position={meetBallActionsPosition}
          actions={meetballActions}
          meetBallsPopupRef={meetBallsPopupRef}
          triggerAction={triggerAction}
        />
      )}
      {showPrintActions && (
        <PrintActions
          position={printActionsPosition}
          printActionsPopupRef={printActionsPopupRef}
          activePrintActions={activePrintActions}
          statusObject={statusObject}
          triggerPrint={triggerPrint}
        />
      )}
    </div>
  );
};

export const DesignerCellRenderer = (props) => {
  const { designer = {} } = props?.data ?? {};

  return <Text>{designer.name}</Text>;
};
