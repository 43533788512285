/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, ActivityIndicator, Platform } from "react-native";
import { Button, Text } from "react-native-elements";
import tw from "tailwind-rn";
import { theme, colors, shapes, fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import { useNavigation, CommonActions } from "@react-navigation/native";
import UserProfileStorage from "library/storage/userProfile";
import { ConfirmModal } from "components/wrappers";
import { ToasterHandler } from "components/elements";
import { PaymentSection } from "library/utils/payment-options";
import RedeemGiftCards from "../../create-order/redeem-gift-cards-section";
import { selectGiftCardsData } from "library/sagas/views/home/drawer/create-order/selector";
import { getGrandTotalPrice } from "library/utils/createOrder";
import { Entitlements } from "library/utils/entitlements";

import isArray from "lodash/isArray";
import get from "lodash/get";
import moment from "moment";
import {
  getFeesInfo,
  getLineItemAmounts,
  getTaxAmounts,
  voidPayments,
} from "library/utils/createOrder";
import { setPaymentDetails } from "library/sagas/views/home/drawer/create-order/slice";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
//import HouseAccountPayment from "./house-account-payment";
import { formatPrice } from "library/utils/formatter";
import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";
import { getIsEventModified } from "../helper";

const ProposalAndPayment = (props) => {
  const { messages, Localise } = useContext(I18NContext);
  const { permissions = {} } = useContext(AppSettingsContext);

  const customerDetails = useSelector(selectCustomerDetails);
  const giftCardsResponse = useSelector(selectGiftCardsData);

  const [innerFormLoading, setInnerFormLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useStateIfMounted([]);

  const paymentDetailsRef = useRef([]);
  const houseAccountFormRef = useRef(null);
  const dispatch = useDispatch();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const {
    values,
    resetForm,
    dirty,
    isDesktop,
    currentTabIndex,
    setCurrentTabIndex,
    memberCodes,
    setFieldValue,
    handleSubmit,
    loading,
    initialValues,
    completedTabIndexs,
    setValidationOnChange,
  } = props;

  const selectedShopPermissions = get(permissions, values?.memberCode, {});

  const floristBusinessName =
    UserProfileStorage.getShopName(values?.memberCode) || "";
  const {
    firstName = "",
    lastName = "",
    businessName = "",
  } = values.customerInfo;
  const {
    actualCustomerInfo,
    paymentDetails,
    customerInfo,
    showPayment,
    isPaymentRequired,
    orderItems,
    orderAmounts,
    orderIds = [],
    merchantReferenceId = "",
    ...other
  } = values;

  const isEventModified = getIsEventModified(initialValues, values);

  const shopPreferences = UserProfileStorage.getShopPreferences(
    values?.memberCode
  );

  const {
    house_account_invoice,
    cash,
    check,
    credit_card,
    paid_elsewhere,
    pay_later,
    payment_terminal = "false",
    terminal_settings = [],
    gift_cards,
  } = shopPreferences;

  const { eventId, memberCode, locations } = values;

  const isStorePickup = !locations.some(
    (location) => location.deliveryType !== "STORE_PICKUP"
  );

  const {
    paymentInfo: firstPaymentInfo = {},
    houseAccountInfo: { isActive } = {},
  } = customerDetails;
  const { paymentMethodType: firstPaymentMethodType } = get(
    paymentDetails,
    "paymentMethod.0"
  );

  const amountToPay = formatPrice(
    isArray(orderAmounts)
      ? orderAmounts?.find((x) => x.name === "amountChargedToCustomer")?.value
      : 0
  );

  const { grandTotal = 0 } = getGrandTotalPrice(amountToPay, giftCardsResponse); // orders total with gc

  const isSplitPaymentEnabled = Environment.get("ENABLE_SPLIT_PAYMENT", false);

  const enableSplitPayment =
    isSplitPaymentEnabled &&
    selectedShopPermissions[Entitlements.CREATE_EVENT]?.includes(
      Entitlements.CREATE_EVENT_PAGE.SPLIT_PAYMENT
    );

  useEffect(() => {
    setFieldValue(
      "showPayment",
      eventId &&
        status !== "cancelled" &&
        !isEventModified &&
        orderIds.length === 0
    );
  }, [isEventModified, setFieldValue, eventId, status]);

  useEffect(() => {
    setFieldValue(
      "isPaymentRequired",
      !!(
        showPayment &&
        (firstPaymentMethodType || ["approved"].includes(status))
      )
    );
  }, [showPayment, firstPaymentMethodType]);

  useEffect(() => {
    let eligiblePaymentOptions = [];

    if (showPayment) {
      cash === "true" &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Cash"),
          value: "CASH",
        });

      check === "true" &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Check"),
          value: "CHECK",
        });

      credit_card === "true" &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Credit Card"),
          value: "CREDIT_CARD",
        });

      paid_elsewhere === "true" &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Paid Elsewhere"),
          value: "PAID_ELSEWHERE",
        });

      credit_card === "true" &&
        firstPaymentInfo?.creditCardId &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Saved Payment"),
          value: "SAVED_CARD",
        });

      house_account_invoice === "true" &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Invoice"),
          value: "INVOICE",
        });

      pay_later === "true" &&
        isStorePickup &&
        eligiblePaymentOptions.push({
          label: Localise(messages, "Pay Later"),
          value: "PAY_LATER",
        });

      if (isElectronApp) {
        const paymentTerminalEnabledForMembercode = payment_terminal === "true";

        const enablePaymentTerminal = Environment.get(
          "ENABLE_PAYMENT_TERMINAL",
          ""
        ).split(",");

        const hasAccessToPaymentTerminal =
          enablePaymentTerminal.includes("ALL") ||
          enablePaymentTerminal.includes(memberCode);

        if (paymentTerminalEnabledForMembercode && hasAccessToPaymentTerminal) {
          const updatePaymentOptions = async () => {
            const { currentPOSSettings = {} } = await getCurrentPOSSettings(
              terminal_settings
            );

            if (currentPOSSettings?.serial_number) {
              eligiblePaymentOptions.unshift({
                label: Localise(messages, "Payment Terminal"),
                value: "PAYMENT_TERMINAL",
              });

              setPaymentOptions(eligiblePaymentOptions);
              setFieldValue(
                "paymentDetails.paymentMethod.0.paymentMethodType",
                "PAYMENT_TERMINAL"
              );
            }
          };

          updatePaymentOptions();
        }
      } else {
        setPaymentOptions(eligiblePaymentOptions);
      }
    } else {
      // To reset payment options when show payment is false
      setPaymentOptions(eligiblePaymentOptions);
    }
  }, [showPayment]);

  useEffect(() => {
    if (eventId && !isEventModified) {
      let orderItemsTaxAmount = 0,
        orderItemsSubTotal = 0,
        orderItemsFeeTotal = 0;

      const orderItems = locations.map((location) => {
        let orderItemTotal = 0;
        const lineItems = location.products.map((product) => {
          const { productId, productName, quantity, amount } = product;
          orderItemTotal += parseFloat(amount * quantity);
          return {
            productFirstChoiceCode: productId,
            productFirstChoiceDescription: productName,
            productSecondChoiceCode: "NONE",
            productSecondChoiceDescription: "NONE",
            productFirstChoiceRefNumberId: productId,
            itemPrice: amount,
            accessories: [],
            quantity,
            type: "Product",
            img: "",
            lineItemAmounts: getLineItemAmounts(amount, quantity, 0),
          };
        });

        const {
          address: { addressLine1, city, country, county, zip, state },
          deliveryDate,
          deliveryType,
          taxInfo = {},
          deliveryInstructions,
          deliveryFee,
          retailDeliveryFee = 0,
          addressVerificationInfo,
        } = location;

        const { taxAmount = 0, taxRate } = taxInfo;
        const productTaxAmount = parseFloat(
          (taxRate * orderItemTotal).toFixed(2)
        );

        let formattedRetailDeliveryFee = parseFloat(retailDeliveryFee);
        let formattedRelayFee = 0;
        let formattedDeliveryFee = parseFloat(deliveryFee);
        let orderItemTotalFee =
          formattedRetailDeliveryFee + formattedRelayFee + formattedDeliveryFee;

        orderItemsSubTotal += parseFloat(orderItemTotal);
        orderItemsFeeTotal +=
          parseFloat(deliveryFee) + parseFloat(retailDeliveryFee);
        orderItemsTaxAmount += parseFloat(taxAmount);

        const { taxAmounts, totalDeliveryFeeTax, totalRelayFeeTax } =
          getTaxAmounts(
            taxInfo,
            orderItemTotal,
            formattedDeliveryFee,
            formattedRelayFee,
            deliveryType,
            shopPreferences
          );

        return {
          lineItems,
          price: [
            {
              value: parseFloat(orderItemTotal + formattedDeliveryFee).toFixed(
                2
              ),
              name: "orderTotal",
            },
          ],
          recipientInfo: {
            firstName: firstName || businessName,
            lastName: lastName || " ",
            email: "",
            giftCardId: "",
            addressLine1,
            city,
            state,
            zip,
            country,
            locationType: "Residence",
            locationName: "",
            phone: "",
            deliveryMethod: deliveryType,
            hasRecipientInfo: true,
            county,
          },
          addressVerificationInfo,
          deliveryInfo: {
            cardMessage: values.type,
            deliveryDate,
            pickUpDateTime: moment(
              locations[0]?.locationTimes[0]?.startTime
            ).utc(),
            deliveryMethod: deliveryType,
            pickUpBy: businessName || `${firstName} ${lastName}`,
            deliveryInstructions,
            occasion: ["FUNERAL"].includes(values.type) ? values.type : "OTHER",
            shopDayTimings: {},
            timeZone: "America/Anchorage",
          },
          fees: getFeesInfo(
            formattedRetailDeliveryFee,
            formattedRelayFee,
            formattedDeliveryFee,
            orderItemTotalFee
          ),
          taxAmounts: taxAmounts,
          totalTax: {
            amount: taxAmount,
            rate: taxRate,
            productTaxAmount: productTaxAmount,
            feeTaxAmount: parseFloat((taxAmount - productTaxAmount).toFixed(2)),
            deliveryFeeTaxAmount: totalDeliveryFeeTax,
            relayFeeTaxAmount: totalRelayFeeTax,
          },
          orderItemAmounts: [
            {
              name: "orderTotal",
              value: parseFloat(
                orderItemTotal +
                  taxAmount +
                  parseFloat(deliveryFee) +
                  parseFloat(retailDeliveryFee)
              ).toFixed(2),
            },
            { name: "discountAmount", value: "0.00" },
            { name: "savings", value: "0.00" },
            {
              name: "amountChargedToCustomer",
              value: parseFloat(
                orderItemTotal +
                  taxAmount +
                  parseFloat(deliveryFee) +
                  parseFloat(retailDeliveryFee)
              ).toFixed(2),
            },
            { name: "taxAmount", value: parseFloat(taxAmount).toFixed(2) },
            {
              name: "subtotal",
              value: parseFloat(orderItemTotal).toFixed(2),
            },
            { name: "feeTotal", value: parseFloat(deliveryFee).toFixed(2) },
          ],
          sendingMember: {
            memberCode,
          },
          receivingMember: { memberCode },
        };
      });
      const orderAmounts = [
        {
          name: "orderTotal",
          value: parseFloat(
            orderItemsSubTotal + orderItemsTaxAmount + orderItemsFeeTotal
          ).toFixed(2),
        },
        { name: "discountAmount", value: "0.00" },
        { name: "savings", value: "0.00" },
        {
          name: "amountChargedToCustomer",
          value: parseFloat(
            orderItemsSubTotal + orderItemsTaxAmount + orderItemsFeeTotal
          ).toFixed(2),
        },
        {
          name: "taxAmount",
          value: parseFloat(orderItemsTaxAmount).toFixed(2),
        },
        {
          name: "subtotal",
          value: parseFloat(orderItemsSubTotal).toFixed(2),
        },
        {
          name: "feeTotal",
          value: parseFloat(orderItemsFeeTotal).toFixed(2),
        },
      ];
      setFieldValue("orderItems", orderItems, []);
      setFieldValue("orderAmounts", orderAmounts, []);
    }
  }, [isEventModified, setFieldValue, memberCode, eventId, locations]);

  //Updating formik so that payment options component uses the same payment info
  useEffect(() => {
    firstPaymentInfo.creditCardId &&
      setFieldValue("customerInfo.firstPaymentInfo", firstPaymentInfo);
  }, [firstPaymentInfo.creditCardId]);

  const navigation = useNavigation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const status = values?.status?.toLowerCase();

  const labelStyle = useRef({
    fontWeight: "normal",
  });

  return useMemo(() => {
    const disable =
      innerFormLoading ||
      loading ||
      !dirty ||
      ["completed", "cancelled"].includes(status?.toLowerCase());

    const isGiftCardsEnabled = Environment.get("GIFT_CARDS_ENABLED", false);
    const isRedeemGiftCardEnabled = isGiftCardsEnabled && gift_cards === "true";

    return (
      <React.Fragment>
        {showPayment && (
          <View
            style={[shapes.sectionBorder, { width: "100%", marginTop: 10 }]}
          >
            {isRedeemGiftCardEnabled && (
              <RedeemGiftCards values={values} amountToPay={amountToPay} />
            )}
            <View
              style={[
                tw("py-2"),
                {
                  paddingHorizontal: 5,
                  ...(showPayment && { marginBottom: 10 }),
                },
              ]}
            >
              <View style={tw("flex flex-row items-center")}>
                <View
                  style={[
                    tw("flex flex-row"),
                    { width: isDesktop ? "75%" : "70%" },
                  ]}
                >
                  <Text
                    style={[
                      fonts.sectionHeading,
                      tw("pr-4"),
                      { color: colors.primary },
                    ]}
                  >
                    {Localise(messages, "Grand Total")}
                  </Text>
                </View>
                <View
                  style={[
                    tw("flex flex-row items-center justify-end"),
                    { width: isDesktop ? "25%" : "30%" },
                  ]}
                >
                  <Text
                    style={[fonts.sectionHeading, { color: colors.primary }]}
                    testID="grandTotal_title"
                  >
                    {`$${formatPrice(grandTotal)}`}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ marginLeft: 5 }}>
              {paymentOptions.length > 0 && (
                <PaymentSection
                  paymentOptions={paymentOptions}
                  {...{
                    paymentDetailsRef,
                    grandTotal,
                    enableSplitPayment,
                    house_account_invoice,
                  }}
                  labelStyle={labelStyle.current}
                  isPayLaterEnabled={isStorePickup}
                  setValidationOnChange={setValidationOnChange}
                  houseAccountFormRef={houseAccountFormRef}
                  onHouseAccountUpdateSuccess={() => {
                    setInnerFormLoading(false);
                    handleSubmit();
                  }}
                  onHouseAccountUpdateReject={() => {
                    setInnerFormLoading(false);
                  }}
                  setInnerFormLoading={setInnerFormLoading}
                  showInvoiceOnInactive={true}
                />
              )}
            </View>
          </View>
        )}
        <View
          style={{
            marginTop: 10,
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
          }}
        ></View>
        {!orderIds.length && (
          <View style={[tw("flex flex-row items-center justify-end")]}>
            <Button
              titleStyle={{ ...theme.Button.secondaryTitleStyle, fontSize: 12 }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
              }}
              containerStyle={{
                width: isDesktop ? 72 : 80,
                height: 40,
                marginRight: 4,
              }}
              onPress={() => {
                setShowConfirmModal(true);
              }}
              title={Localise(messages, "Cancel")}
              testID={"Cancel"}
              accessibilityLabel={"Cancel"}
              disabled={disable}
            />
            <Button
              // titleStyle={theme.Button.secondaryTitleStyle}
              titleStyle={{ fontSize: 12 }}
              containerStyle={{
                minWidth: isDesktop ? 72 : 80,
                height: 40,
                marginLeft: 4,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                backgroundColor: colors.button,
              }}
              onPress={() => {
                if (houseAccountFormRef.current) {
                  houseAccountFormRef.current.handleSubmit();
                } else handleSubmit();
              }}
              title={Localise(messages, "Submit")}
              testID={"submit"}
              accessibilityLabel={"submit"}
              disabled={disable || completedTabIndexs.length < 3}
            />
            {(innerFormLoading || loading) && (
              <ActivityIndicator
                style={{ marginLeft: 5, marginTop: -5 }}
                color={colors.activityIndicator}
              />
            )}
          </View>
        )}
        <ConfirmModal
          modalVisible={showConfirmModal}
          handlePrimary={() => {
            const cancelAction = () => {
              resetForm();
              navigation.dispatch(
                CommonActions.navigate({
                  name: "events",
                })
              );
              setShowConfirmModal(false);
            };
            const paymentMethods = get(
              values,
              "paymentDetails.paymentMethod",
              []
            );
            const hasProcessedPayments = paymentMethods.filter(
              (eachPayment) => {
                return (
                  !!["CREDIT_CARD", "SAVED_CARD"].includes(
                    eachPayment.paymentMethodType
                  ) && !!eachPayment.paymentProcessed
                );
              }
            );
            if (isSplitPaymentEnabled && hasProcessedPayments.length > 0) {
              voidPayments({
                values,
                resolve: () => {
                  dispatch(setPaymentDetails({ status: "", values: {} }));
                  cancelAction();
                  ToasterHandler(
                    "nice",
                    Localise(
                      messages,
                      `Refund initiated for successful transaction(s)`
                    )
                  );
                },
                reject: () => {
                  ToasterHandler(
                    "uh oh",
                    Localise(
                      messages,
                      "Something went wrong. Please try again!"
                    )
                  );
                },
              });
            } else {
              cancelAction();
            }
          }}
          contentStyle={{ maxWidth: 340 }}
          handleSecondary={() => {
            setShowConfirmModal(false);
          }}
          data={{
            modal: {
              primary: "Yes",
              secondary: "No",
              content: Localise(
                messages,
                "Do you want to cancel your changes?"
              ),
            },
          }}
        />
      </React.Fragment>
    );
  }, [
    dirty,
    isDesktop,
    values,
    currentTabIndex,
    Localise,
    messages,
    house_account_invoice,
    isActive,
    floristBusinessName,
    handleSubmit,
    innerFormLoading,
    setInnerFormLoading,
    loading,
    navigation,
    resetForm,
    setCurrentTabIndex,
    showConfirmModal,
    status,
    memberCodes,
    showPayment,
    firstPaymentInfo,
    orderAmounts,
    giftCardsResponse,
  ]);
};

export default ProposalAndPayment;
