import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import toLower from "lodash/toLower";
import { Platform } from "react-native";
import qw from "query-string";
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";
import { defaultDeliveryProvider } from "library/utils/deliveryService";
import UserAuthStorage from "library/storage/userAuth";
import uniq from "lodash/uniq";

const isProxyUserRequest = () => {
  const proxyUser = UserProfileStorage.getProxyUser();
  if (proxyUser) return proxyUser;

  if (Platform.OS === "web" && window) {
    const { shop, group } = qw.parse(window.location.search);
    if (shop || group) {
      const proxyUser = {
        roles: ["ADMIN"],
        ...(shop && { memberCodes: [shop] }),
        ...(group && { shopGroups: [group] }),
      };
      UserProfileStorage.setProxyUser(proxyUser);
      return proxyUser;
    }
  }
  return;
};

const setupProxyUserProfile = () => {
  const userProfile = UserProfileStorage.getUser();
  if (!userProfile.authGroups) {
    return Promise.resolve();
  }

  const { shopGroups } = UserProfileStorage.getProxyUser() || {};
  const promiseObj = () =>
    shopGroups
      ? request("get-shop-group", {
          shopGroupName: encodeURIComponent(shopGroups[0]),
          partnerGroupName: "-",
          memberCodes: "",
          memberCode: userProfile.memberCode || "FTD",
        })
          .then((res = []) => res[0])
          .then(({ memberCodes = [] } = {}) => {
            UserProfileStorage.setProxyUser({
              roles: ["ADMIN"],
              memberCodes,
              shopGroups,
            });
          })
      : Promise.resolve();

  return promiseObj()
    .then(() => setupUserFullProfile())
    .catch((err) => console.log("Cannot set up user proxy", err));
};

const setupUserFullProfile = () => {
  const userProfile = UserProfileStorage.getUser();
  if (!userProfile.authGroups) {
    return Promise.resolve();
  }

  const proxyUserAuthGroup = UserProfileStorage.getProxyUser();
  if (proxyUserAuthGroup && proxyUserAuthGroup.memberCodes) {
    const adminAuthGroup = userProfile.authGroups.find((e) =>
      e.roles.includes("ADMIN")
    );

    if (adminAuthGroup) {
      userProfile.authGroups = [{ ...adminAuthGroup, ...proxyUserAuthGroup }];
      userProfile.firstName = `FTD ${userProfile.firstName}`;
    }
  }

  let promises = [];
  userProfile.authGroups
    .filter(
      (e) => e.shopGroups.length && !e.shopGroups.includes("FTD_ALL_SHOPS")
    )
    .map((authGroup) => {
      // authGroup.shopGroups.map((shopGroup) => {
      //   promises.push(
      //     request("get-shop-group", {
      //       shopGroupName: encodeURIComponent(shopGroup),
      //       memberCode: userProfile.memberCode,
      //       partnerGroupName: userProfile.partnerGroup,
      //       memberCodes: "",
      //     })
      //       .then((res = []) => res[0])
      //       .then(({ memberCodes = [] } = {}) => {
      //         authGroup.memberCodes = memberCodes;
      //       })
      //       .catch(() => console.log("get shopgroup failed"))
      //   );
      // });
      //authGroup.shopGroups = [];

      UserProfileStorage.setAllShopGroups(authGroup.shopGroups);
      UserProfileStorage.setSelectedShopGroup(authGroup.shopGroups[0]);

      promises.push(
        request("get-shop-group", {
          shopGroupName: encodeURIComponent(authGroup.shopGroups[0]),
          memberCode: userProfile.memberCode,
          partnerGroupName: userProfile.partnerGroup,
          memberCodes: "",
        })
          .then((res = []) => res[0])
          .then(({ memberCodes = [] } = {}) => {
            UserProfileStorage.setAccountMemberCodes(memberCodes);
          })
          .catch(() => console.log("get shopgroup failed"))
      );
    });

  //Adding groupMemberCodes to fetch funerals of shop group
  if (userProfile.authGroups[0].memberCodes.length === 1) {
    promises.push(
      request("get-shop-group", {
        shopGroupName: "",
        memberCode: userProfile.memberCode,
        partnerGroupName: userProfile.partnerGroup,
        memberCodes: userProfile.memberCode,
      })
        .then((res = []) => res[0])
        .then(({ memberCodes = [] } = {}) => {
          UserProfileStorage.setGroupMemberCodes(memberCodes);
        })
        .catch(() => console.log("get shopgroup failed"))
    );
  }

  return Promise.all(promises).then(() => {
    UserProfileStorage.setUser(userProfile);
    const memberDetails = UserProfileStorage.getAllShopNames();
    if (isEmpty(memberDetails) || proxyUserAuthGroup) {
      const userRole = UserProfileStorage.getRole();
      const isProxyUser = isProxyUserRequest();
      let memberCodes = [];
      if (userRole === "ADMIN" && !isProxyUser) {
        memberCodes = UserProfileStorage.getAccountMemberCodes();
      } else {
        memberCodes = get(userProfile, "authGroups.0.memberCodes");
      }

      if (memberCodes.length) {
        const deliveryModuleAccess = Environment.get(
          "DELIVERY_MODULE_ACCESS",
          ""
        ).split(",");

        getAllMemberDetails(memberCodes).then((allMemberDetails) => {
          allMemberDetails.forEach((memberDetail) => {
            memberDetail.memberCode !== "FTD" &&
              getMemberDetails(memberDetail)
                .then(
                  ({
                    shopName,
                    preferences,
                    floristTimeZone,
                    openHours,
                    memberCode,
                    floristTimeZoneData,
                    shopAddress,
                    phone,
                    email,
                    websites,
                    memberEntitlementName,
                    floristTechnology,
                    currencyCode,
                  }) => {
                    UserProfileStorage.setMemberEntitlement(
                      memberCode,
                      memberEntitlementName
                    );
                    UserProfileStorage.setShopName(memberCode, shopName);
                    UserProfileStorage.setShopPreferences(memberCode, {
                      ...preferences,
                      delivery_module_access:
                        deliveryModuleAccess.includes("ALL") ||
                        deliveryModuleAccess.includes(memberCode)
                          ? true
                          : false,
                    });
                    UserProfileStorage.setShopTimeZone(
                      memberCode,
                      floristTimeZone,
                      floristTimeZoneData
                    );
                    UserProfileStorage.setShopHours(memberCode, openHours);
                    UserProfileStorage.setShopLocation(memberCode, shopAddress);
                    UserProfileStorage.setShopPhoneNumber(memberCode, phone);
                    UserProfileStorage.setShopEmail(memberCode, email);
                    UserProfileStorage.setShopWebsites(memberCode, websites);
                    UserProfileStorage.setFloristTechnology(
                      memberCode,
                      floristTechnology
                    );
                    UserProfileStorage.setShopCurrency(
                      memberCode,
                      currencyCode
                    );
                  }
                )
                .catch((err) => console.log(err));
          });
        });
      }
    }

    return userProfile;
  });
};

const getUserProfile = () => {
  return request("get-user-profile")
    .then((profile) => {
      UserProfileStorage.setUser(profile);

      return isProxyUserRequest()
        ? setupProxyUserProfile()
        : setupUserFullProfile();
    })
    .catch((err) => {
      console.log("Invalid Profile response", JSON.stringify(err));
    });
};

const getMemberDetails = (memberDetail) => {
  const {
    businessName = "",
    timeZone,
    memberCode = "",
    openHours,
    preferences = [],
    reason = "",
    tzData = "", //timeZone fullform
    address = {},
    latitude = "",
    longitude = "",
    phone = "",
    email = "",
    websites = [],
    memberEntitlements: {
      memberEntitlementName = "",
      message: entitlementMessage = "",
    } = {}, // updated this line in order to resolve issue i.e. when we not received memberEntitlements obj in API response
    technology = "",
    currencyCode = "USD",
  } = memberDetail;

  let entitlement = memberEntitlementName;

  if (entitlementMessage.includes("expired")) entitlement = "MHQ CORE";

  const deliveryService = preferences.find(
    (e) => e.name === "delivery_service"
  );
  const bulkPrint = preferences.find((e) => e.name === "bulk_print_orders");
  const shopifyStoreName = get(
    preferences.find((e) => e.name === "shopify_store_name"),
    "value.0",
    `${toLower(memberCode)}-mol`
  );
  const catalogPriceMinimum = preferences.find(
    (e) => e.name === "catalog_price_minimum"
  );
  const languages = preferences.find((e) => e.name === "languages");
  const allowNewProducts = preferences.find(
    (e) => e.name === "allow_new_products"
  );
  const deliveryFee = preferences.find((e) => e.name === "delivery_fee");
  const taxOnDeliveryFee = preferences.find(
    (e) => e.name === "tax_delivery_fee"
  );

  const retailDeliveryFee = preferences.find(
    (e) => e.name === "retail_delivery_fee"
  );
  const taxRetailDeliveryFee = preferences.find(
    (e) => e.name === "tax_retail_delivery_fee"
  );
  const relayFee = preferences.find((e) => e.name === "relay_fee");

  const isCreditCardEnabled = preferences.find((e) => e.name === "credit_card");
  const isCashEnabled = preferences.find((e) => e.name === "cash");
  const isCheckEnabled = preferences.find((e) => e.name === "check");
  const isPaidElseWhereEnabled = preferences.find(
    (e) => e.name === "paid_elsewhere"
  );
  const isGiftCardPaymentEnabled = preferences.find(
    (e) => e.name === "gift_cards"
  );
  const isPayLaterEnabled = preferences.find((e) => e.name === "pay_later");
  const isHouseAccountInvoiceEnabled = preferences.find(
    (e) => e.name === "house_account_invoice"
  );
  const isHouseAccountLateFeeChargeEnabled = preferences.find(
    (e) => e.name === "house_account_latefeeCharge_enabled"
  );
  const isHouseAccountLateFeeAmountEnabled = preferences.find(
    (e) => e.name === "house_account_latefeeAmount"
  );
  const isHouseAccountStatementStoreAddressEnabled = preferences.find(
    (e) => e.name === "house_account_statement_store_address_enabled"
  );
  const isBLNOrdersEnabled = preferences.find(
    (e) => e.name === "bloomlink_enabled"
  );
  const isTELOrdersEnabled = preferences.find(
    (e) => e.name === "teleflora_enabled"
  );
  const telMemberCode = preferences.find(
    (e) => e.name === "teleflora_memberCode"
  );
  const telUserName = preferences.find((e) => e.name === "teleflora_userName");
  const telPassword = preferences.find((e) => e.name === "teleflora_password");
  const autoAcceptOrders = preferences.find(
    (e) => e.name === "auto_accept_orders"
  );
  const autoPrintAcceptedOrders = preferences.find(
    (e) => e.name === "auto_print_accept_orders"
  );

  const qrCodeGlobalEnabled = preferences.find(
    (e) => e.name === "qr_code_global_enabled"
  );
  const qrCodeInvoiceEnabled = preferences.find(
    (e) => e.name === "qr_code_invoice_enabled"
  );
  const qrCodeWorksheetEnabled = preferences.find(
    (e) => e.name === "qr_code_worksheet_enabled"
  );
  const qrCodeOrderDetailsEnabled = preferences.find(
    (e) => e.name === "qr_code_order_details_enabled"
  );
  const qrCodeTriFoldEnabled = preferences.find(
    (e) => e.name === "qr_code_trifold_enabled"
  );
  const deliveryProvider = preferences.find(
    (e) => e.name === "delivery_provider"
  );
  const localOrderTaxSettings = preferences.find(
    (e) => e.name === "local_order_tax_settings"
  );
  const wireOrderTaxSettings = preferences.find(
    (e) => e.name === "wired_order_tax_settings"
  );
  const taxOnDfSettings = preferences.find(
    (e) => e.name === "tax_on_local_delivery_fee"
  );
  const taxOnOutsideDfSettings = preferences.find(
    (e) => e.name === "tax_on_outside_local_delivery_fee"
  );
  const taxOnServiceFeeSettings = preferences.find(
    (e) => e.name === "tax_on_service_fee"
  );
  const taxOnRelayFeeSettings = preferences.find(
    (e) => e.name === "tax_on_relay_fee"
  );
  const emailHaInvoicesNotificationSettings = preferences.find(
    (e) => e.name === "email_ha_invoices_notification_settings"
  );
  const floristContactInformationSettings = preferences.find(
    (e) => e.name === "florist_contact_information_settings"
  );
  const invoicePrintCardSection3Settings = preferences.find(
    (e) => e.name === "invoice_print_card_section3_settings"
  );

  const cardSettings = preferences.find((e) => e.name === "card_settings");

  const additionalSympathyCard = preferences.find(
    (e) => e.name === "additional_sympathy_card"
  );

  const additionalSympathyInvoice = preferences.find(
    (e) => e.name === "additional_sympathy_invoice"
  );

  const invoicePrintSettings = preferences.find(
    (e) => e.name === "legal_size_invoice_print_settings"
  );

  const trifoldPrintSettings = preferences.find(
    (e) => e.name === "trifold_card_print_settings"
  );

  const orderDetailsPrintSettings = preferences.find(
    (e) => e.name === "order_details_print_settings"
  );

  const orderDetailsOutgoingPrintSettings = preferences.find(
    (e) => e.name === "order_details_outgoing_print_settings"
  );

  const worksheetPrintSettings = preferences.find(
    (e) => e.name === "designer_worksheet_print_settings"
  );

  const pickupManifestPrintSettings = preferences.find(
    (e) => e.name === "pickup_manifest_print_settings"
  );

  const orderMessagesIncomingPrintSettings = preferences.find(
    (e) => e.name === "order_messages_incoming_print_settings"
  );
  const orderMessagesOutgoingPrintSettings = preferences.find(
    (e) => e.name === "order_messages_outgoing_print_settings"
  );

  const attemptedDeliveryFlaggedFollowup = preferences.find(
    (e) => e.name === "attempted_delivery_flagged_followup"
  );

  let terminalSettings = [];
  let isPaymentTerminalEnabled = false;

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  if (isElectronApp) {
    const blnOrderPrefs = document.getElementById("blnOrderPrefs").value;

    const blnOrderPrefsObj = JSON.parse(blnOrderPrefs) || {
      bloomlink_enabled: "false",
    };

    if (
      blnOrderPrefsObj?.bloomlink_enabled === "false" &&
      get(isBLNOrdersEnabled, "value.0", "") === "true"
    ) {
      document.getElementById("blnOrderPrefs").value = JSON.stringify({
        bloomlink_enabled: "true",
        bln_enabled_member_code: memberDetail.memberCode,
        accessToken: UserAuthStorage.getAccessToken(),
        request_context: getRequestContext(),
      });
    }

    terminalSettings = preferences.find((e) => e.name === "terminal_settings");
    terminalSettings = get(terminalSettings, "value.0", "[]");

    isPaymentTerminalEnabled = preferences.find(
      (e) => e.name === "payment_terminal"
    );
  }

  const memberData = {
    shopName: businessName || reason,
    preferences: {
      delivery_service: get(deliveryService, "value.0", ""),
      bulk_print_orders: get(bulkPrint, "value.0", ""),
      attempted_delivery_flagged_followup: get(
        attemptedDeliveryFlaggedFollowup,
        "value.0",
        ""
      ),
      catalog_price_minimum: get(catalogPriceMinimum, "value.0", ""),
      languages: get(languages, "value.0") ? get(languages, "value") : ["en"],
      allow_new_products: get(allowNewProducts, "value.0", "true"),
      shopify_store_url: `https://${shopifyStoreName}.myshopify.com`,
      delivery_fee: get(deliveryFee, "value.0", "0.00"),
      tax_delivery_fee: get(taxOnDeliveryFee, "value.0", "false"),
      local_order_tax_settings: get(
        localOrderTaxSettings,
        "value.0",
        "point_of_origin"
      ),
      wired_order_tax_settings: get(
        wireOrderTaxSettings,
        "value.0",
        "point_of_origin"
      ),
      tax_on_local_delivery_fee: get(taxOnDfSettings, "value.0", ""),
      tax_on_outside_local_delivery_fee: get(
        taxOnOutsideDfSettings,
        "value.0",
        ""
      ),
      tax_on_service_fee: get(taxOnServiceFeeSettings, "value.0", ""),
      tax_on_relay_fee: get(taxOnRelayFeeSettings, "value.0", ""),
      retail_delivery_fee: get(retailDeliveryFee, "value.0", "0.00"),
      tax_retail_delivery_fee: get(taxRetailDeliveryFee, "value.0", "false"),
      relay_fee: get(relayFee, "value.0", "0.00"),
      credit_card: get(isCreditCardEnabled, "value.0", ""),
      cash: get(isCashEnabled, "value.0", ""),
      check: get(isCheckEnabled, "value.0", ""),
      paid_elsewhere: get(isPaidElseWhereEnabled, "value.0", ""),
      gift_cards: get(isGiftCardPaymentEnabled, "value.0", ""),
      pay_later: get(isPayLaterEnabled, "value.0", ""),
      email_ha_invoices_notification_settings: get(
        emailHaInvoicesNotificationSettings,
        "value.0",
        ""
      ),
      house_account_invoice: get(isHouseAccountInvoiceEnabled, "value.0", ""),
      house_account_statement_store_address_enabled: get(
        isHouseAccountStatementStoreAddressEnabled,
        "value.0",
        "true"
      ),
      house_account_latefeeCharge_enabled: get(
        isHouseAccountLateFeeChargeEnabled,
        "value.0",
        ""
      ),
      house_account_latefeeAmount: get(
        isHouseAccountLateFeeAmountEnabled,
        "value.0",
        ""
      ),
      bloomlink_enabled: get(isBLNOrdersEnabled, "value.0", ""),
      teleflora_enabled: get(isTELOrdersEnabled, "value.0", ""),
      teleflora_memberCode: get(telMemberCode, "value.0", ""),
      teleflora_userName: get(telUserName, "value.0", ""),
      teleflora_password: get(telPassword, "value.0", ""),
      auto_accept_orders: get(autoAcceptOrders, "value.0", ""),
      auto_print_accept_orders: get(autoPrintAcceptedOrders, "value.0", ""),
      qr_code_global_enabled: get(qrCodeGlobalEnabled, "value.0", ""),
      florist_contact_information_settings: get(
        floristContactInformationSettings,
        "value.0",
        ""
      ),
      invoice_print_card_section3_settings: get(
        invoicePrintCardSection3Settings,
        "value.0",
        ""
      ),
      qr_code_invoice_enabled: get(qrCodeInvoiceEnabled, "value.0", ""),
      qr_code_worksheet_enabled: get(qrCodeWorksheetEnabled, "value.0", ""),
      qr_code_order_details_enabled: get(
        qrCodeOrderDetailsEnabled,
        "value.0",
        ""
      ),
      qr_code_trifold_enabled: get(qrCodeTriFoldEnabled, "value.0", ""),
      legal_size_invoice_print_settings: JSON.parse(
        get(invoicePrintSettings, "value.0") || "[]"
      ),
      trifold_card_print_settings: JSON.parse(
        get(trifoldPrintSettings, "value.0") || "[]"
      ),
      order_details_print_settings: JSON.parse(
        get(orderDetailsPrintSettings, "value.0") || "[]"
      ),
      order_details_outgoing_print_settings: JSON.parse(
        get(orderDetailsOutgoingPrintSettings, "value.0") || "[]"
      ),
      order_messages_incoming_print_settings: JSON.parse(
        get(orderMessagesIncomingPrintSettings, "value.0") || "[]"
      ),
      order_messages_outgoing_print_settings: JSON.parse(
        get(orderMessagesOutgoingPrintSettings, "value.0") || "[]"
      ),
      pickup_manifest_print_settings: JSON.parse(
        get(pickupManifestPrintSettings, "value.0") || "[]"
      ),
      designer_worksheet_print_settings: JSON.parse(
        get(worksheetPrintSettings, "value.0") || "[]"
      ),
      ...(isElectronApp && {
        terminal_settings:
          terminalSettings != "" ? JSON.parse(terminalSettings) : [],
        payment_terminal: get(isPaymentTerminalEnabled, "value.0", "false"),
      }),
      delivery_provider:
        get(deliveryProvider, "value.0", defaultDeliveryProvider) ||
        defaultDeliveryProvider,
      card_settings: get(cardSettings, "value.0", "{}"),
      additional_sympathy_card: get(additionalSympathyCard, "value.0", ""),
      additional_sympathy_invoice: get(
        additionalSympathyInvoice,
        "value.0",
        ""
      ),
    },
    floristTimeZone: timeZone,
    memberCode: memberCode,
    openHours: openHours,
    floristTimeZoneData: tzData,
    shopAddress: {
      ...address,
      ...{ latitude: latitude, longitude: longitude },
    },
    phone: phone,
    email: email,
    websites: websites,
    memberEntitlementName: entitlement,
    floristTechnology: technology,
    currencyCode,
  };
  return Promise.resolve(memberData);
};

const getAllMemberDetails = (memberCodes) => {
  return request("get-shop-details", { memberCodes })
    .then((res = []) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject("Invalid Member response");
    });
};

const getRequestContext = () => {
  const isFloristAdmin = UserProfileStorage.getRole() === "ADMIN";

  const isProxyUser = isProxyUserRequest();

  const {
    name: authGroupName,
    memberCodes: profileMemberCodes = [],
    shopGroups,
    roles,
  } = UserProfileStorage.getAuthGroup();

  let defaultMemberCodes = [];
  if (isFloristAdmin && !isProxyUser) {
    defaultMemberCodes = UserProfileStorage.getAccountMemberCodes() || [];
  } else {
    defaultMemberCodes = profileMemberCodes;
  }

  return {
    authGroupName,
    memberCodes: uniq(defaultMemberCodes),
    shopGroups,
    roles,
  };
};

export default {
  getUserProfile,
  isProxyUserRequest,
  setupProxyUserProfile,
  getRequestContext,
};
