import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  View,
  Text,
  Platform,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { fonts, colors } from "styles/theme";
import { selectState } from "library/sagas/session/selector";
import moment from "moment";
import { Accordion, Tooltip } from "components/elements";
import IMAGES from "static/assets/images";
import I18NContext from "library/contexts/i18N";

const WindowHeight = 400;
const maxCount = 10;

const currentTimeZone = moment.tz(moment.tz.guess()).format("z");

const getLastUpdateDate = (latestDate, isPrint) => {
  if (isPrint) {
    return `<div style="margin-top: 20px">
        <div style="font-size: 12; font-weight: 600; font-family: Arial; color: ${colors.primary}; margin-left: 15px; align-items: center;">
          ${latestDate}
        </div>
      </div>`;
  }
  return (
    <View style={{ marginTop: 20 }}>
      <Text
        style={{
          ...fonts.heading5,
          marginLeft: 15,
          alignItems: "center",
        }}
      >
        {latestDate}
      </Text>
    </View>
  );
};

const getAllActivitiesInfo = (
  events,
  memberId,
  Localise,
  messages,
  isPrint = false
) => {
  return events.map((activity, index) => {
    const { userNote, type, amount, timeStamp, user } = activity;
    const parsedAmount = parseFloat(amount).toFixed(2);

    const currDate = moment(timeStamp).format("MMM D, YYYY");
    const eventTime = moment(timeStamp).format("hh:mm A");

    const prevDate = moment(events[index - 1]?.timeStamp).format("MMM D, YYYY");

    const getOrderActivityText = () => {
      const {
        payment = "",
        tenderedAmount = "",
        changeDueAmount = "",
        customerName = "",
        isHAReceivePayment = false,
      } = JSON.parse(userNote);

      const customer = customerName?.trim() || "customer";

      return `${
        isHAReceivePayment ? Localise(messages, "Receive Payment :") : ``
      } $${
        amount > 0 ? parsedAmount : `(${parseFloat(amount * -1).toFixed(2)})`
      } ${payment} ${Localise(
        messages,
        "payment received from"
      )} ${customer} ${Localise(messages, "for")} ${memberId} ${Localise(
        messages,
        "by"
      )} ${user} ${Localise(messages, "at")} ${eventTime} ${currentTimeZone}. ${
        type === "Cash"
          ? `Tendered $${tenderedAmount}, change due $${changeDueAmount}`
          : ``
      }`;
    };

    const getActivityText = () => {
      let activityText = `$${parseFloat(amount).toFixed(2)} ${type.replace(
        "-",
        " "
      )} ${Localise(messages, "order completed by")} ${user} ${Localise(
        messages,
        "at"
      )} ${eventTime} ${currentTimeZone} ${
        userNote && userNote !== "$$BALANCE_CHECK$$"
          ? `\nNotes: ${userNote}`
          : ``
      }`;

      if (userNote === "$$BALANCE_CHECK$$") {
        activityText = Localise(messages, "Balance Check: ") + activityText;
      }
      return activityText;
    };

    if (isPrint) {
      return `<div>
          ${
            currDate !== prevDate || index === 0
              ? getLastUpdateDate(
                  moment(timeStamp)?.format("MMM D, YYYY"),
                  isPrint
                )
              : ``
          }
          <div
            key=${index}
            style="border-width: 0px; border-style: solid; border-left-width:1px; border-left-color: ${
              colors.light
            }; margin-left: 5px"
          >
            <div style="position: relative; display: flex; flex-direction: row; align-items: center; padding-top: 10px">
              <div style="border-radius: 50px; border-style: solid; border-width: 1px; border-color: ${
                colors.dark
              }; width: 10px; height: 10px; position: absolute; left: -6px; top: ${
        Platform.OS === "web" ? "calc(50%)" : 5
      }; background-color: ${colors.secondary}; margin-bottom: 15px;"></div>
              <div style="font-size: 12px; font-family: fontFamily.regular; color: ${
                colors.primary
              }; margin-left: 15px;">
                ${
                  ["Cash", "Non-Cash", "Check"].includes(type)
                    ? getOrderActivityText()
                    : ["Paid-In", "Paid-Out", "No-Sale"].includes(type)
                    ? getActivityText()
                    : userNote
                }
              </div>
            </div>
          </div>
        </div>`;

      //console.log("string --", html.outerHTML);
      //return JSON.stringify(html);
    }

    return (
      <React.Fragment key={index}>
        {(currDate !== prevDate || index === 0) &&
          getLastUpdateDate(moment(timeStamp)?.format("MMM D, YYYY"), isPrint)}
        <View
          key={index}
          style={{
            borderWidth: 0,
            borderStyle: "solid",
            borderLeftWidth: 1,
            borderLeftColor: colors.light,
            marginLeft: 5,
          }}
        >
          <View
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                borderRadius: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: colors.dark,
                width: 10,
                height: 10,
                position: "absolute",
                left: -5,
                top: Platform.OS === "web" ? "calc(50%)" : 5,
                backgroundColor: colors.secondary,
                marginBottom: 15,
              }}
            ></Text>
            <Text
              style={{
                ...fonts.default,
                lineHeight: 20,
                marginLeft: 15,
              }}
            >
              {["Cash", "Non-Cash", "Check"].includes(type)
                ? getOrderActivityText()
                : ["Paid-In", "Paid-Out", "No-Sale"].includes(type)
                ? getActivityText()
                : userNote}
            </Text>
          </View>
        </View>
      </React.Fragment>
    );
  });
};

export const printActivitiesReceipt = (
  events,
  memberId,
  Localise,
  messages
) => {
  const activityHtml = `<!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <style>
              * {
                font-size: 12px;
                font-family: Helvetica, sans-serif;
              }
              .content {
                font-size: 16px;  
                margin: 10px;           
              }
              .r-1awozwy{
                align-items: center
              } 
              .r-6koalj{
                display: flex
              }
              .r-18u37iz{
                flex-direction: row
              }
              .r-u8s1d {
                position: absolute;
              }
              .r-14lw9ot {
                background-color: rgb(255, 255, 255);
              } 
            </style>
          </head>
          <body>
            <div class="content">
            ${getAllActivitiesInfo(
              events,
              memberId,
              Localise,
              messages,
              true
            ).join("")}
            </div>
          </body>
        </html>
     `;

  console.log("activity html--", activityHtml);
  const { ipcRenderer } = window.require("electron");
  ipcRenderer.send("printOrderInfo", 1, [activityHtml]);
};

const Activity = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const sessionInfo = useSelector(selectState) || {};
  const [activities, setActivities] = useState([]);
  const { events, memberId } = sessionInfo;

  useEffect(() => {
    if (sessionInfo.sessionId) {
      setActivities(
        events.length > maxCount ? events.slice(0, maxCount) : events
      );
    } else setActivities([]);
  }, [sessionInfo.events.length]);

  const handleScroll = (event) => {
    const currentOffset = event?.nativeEvent?.contentOffset?.y;
    const contentHeight = event?.nativeEvent?.contentSize?.height;

    if (contentHeight === currentOffset + WindowHeight) {
      const end =
        events.length > activities.length + maxCount
          ? activities.length + maxCount
          : events.length;

      const updatedActvities =
        events.length > activities.length ? events.slice(0, end) : activities;

      setActivities(updatedActvities);
    }
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        opacity: 1,
      }}
      pointerEvents={"auto"}
    >
      <Accordion
        title={"Activity"}
        labelStyle={{
          backgroundColor: "white",
          marginTop: 0,
          paddingHorizontal: 10,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          height: "auto",
        }}
        closeStyle={{ borderBottomWidth: 0 }}
        iconStyle={{ fontSize: 12, color: colors.primary }}
        titleStyle={{
          color: colors.primary,
          fontWeight: 500,
        }}
        defaultOpen={true}
        iconType={"chevron"}
        contentStyle={{
          backgroundColor: "white",
          borderWidth: 0,
          paddingVertical: 10,
          paddingHorizontal: 10,
        }}
        handleOnPress={() => {}}
        testID={"activity"}
      >
        {activities.length ? (
          <React.Fragment>
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                printActivitiesReceipt(events, memberId, Localise, messages);
              }}
              testID="print"
              accessibilityLabel="print"
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Tooltip
                text={Localise(messages, "Print session activity")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  resizeMode="cover"
                  source={IMAGES["print"]}
                />
              </Tooltip>
            </TouchableOpacity>
            <ScrollView
              nativeID="activityInfo"
              style={{ maxHeight: WindowHeight }}
              onScroll={handleScroll}
            >
              {getAllActivitiesInfo(events, memberId, Localise, messages)}
            </ScrollView>
          </React.Fragment>
        ) : (
          <View style={{ padding: 20, paddingLeft: 0 }}>
            <Text>{Localise(messages, "No Activities yet!!")}</Text>
          </View>
        )}
      </Accordion>
    </View>
  );
};

export default Activity;
